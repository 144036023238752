import type { ChangeOrdersQuery, ChangeOrderState } from 'types/graphql'

import type { CellSuccessProps, CellFailureProps } from '@redwoodjs/web'

import { routes, useParams, Link } from '@redwoodjs/router'
import { prettyDateTime } from 'src/lib/formatters'


import { ChevronRightIcon } from '@heroicons/react/20/solid'

export const QUERY = gql`
  query ChangeOrdersQuery {
    changeOrders {
      _id
      state
      name
      number
      description
      created
      appliedAt
    }
  }
`

import { LoadingSpinnerWithDelay as LoadingSpinner } from 'src/components/Loading'
export const Loading = () => <LoadingSpinner className='flex p-10 items-center justify-center'/>

import GenericFailure from '../Failure/Failure'
export const Failure = GenericFailure

type PageProps = {
  filter: ChangeOrderState
}
export const Success = ({
  changeOrders,
  filter
}: CellSuccessProps<ChangeOrdersQuery> & PageProps) => {
  const orgId = useParams().orgId!
  const filtered = changeOrders
    .filter(co => co.state === filter)
    .sort((a, b) => {
      if (filter === 'Complete') {
        return (new Date(b.appliedAt!)!).getTime() - (new Date(a.appliedAt!)).getTime()!
      }
      return (new Date(b.created)!).getTime() - (new Date(a.created)).getTime()!
    })

  return filtered.length ? <ul className='divide-y divide-gray-100 cursor-pointer'>
    {filtered.map((co) => {
      const updateMessage = () => {
        const created = new Date(co.created)
        if (co.appliedAt) {
          const appliedAt = prettyDateTime(new Date(co.appliedAt))
          return <>Applied <time dateTime={co.created}>{appliedAt}</time></>
        }
        return <>Opened <time dateTime={co.created}>{prettyDateTime(created)}</time></>
      }
      return <li key={co.number}>
          <Link
            className='p-4 hover:bg-gray-100 relative flex justify-between gap-x-40 px-4 py-5 sm:px-6'

            to={routes.changeOrder({ orderNumber: co.number, orgId }) }>
            <div className="flex min-w-0 gap-x-4">
            <div className="min-w-0 flex-auto">
              <p className="text-sm font-semibold leading-6 text-gray-900">
                <span className="absolute inset-x-0 -top-px bottom-0" />
                {co.name}
              </p>
              <p className="mt-1 flex text-xs leading-5 text-gray-500">
                {co.description}
              </p>
            </div>
          </div>
          <div className="flex shrink-0 items-center gap-x-4">
            <div className="hidden sm:flex sm:flex-col sm:items-end">
              <p className="text-sm leading-6 text-gray-900">{co.state}</p>
              <p className="mt-1 text-xs leading-5 text-gray-500">
                {updateMessage()}
              </p>
            </div>
            <ChevronRightIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
          </div>
        </Link>
      </li>
  })}
  </ul> : <div className='p-4'>
      There are currently no change orders in {filter.toLowerCase()}.
  </div>
}
