
import {
  PartVersionSelectQuery,
  PartCompareQueryVariables
} from 'types/graphql'

import type { CellSuccessProps, CellFailureProps } from '@redwoodjs/web'

import * as ListBox from 'src/components/ListBox'
import classNames from 'classnames'

const MAIN_BRANCH = -1 as const;

export const QUERY = gql`
query PartVersionSelectQuery ($partNumber: String!) {
  partProto (partNumber: $partNumber) {
    instances {
      version
      inChangeOrderNumber
      branch
    }
  }
}
`

type CellProps = {
  defaultVersion: string
  name: string
  variant?: 'plain' | 'fullBleed'
  orderNumber?: number
  size?: 'sm' | 'default'
  hideRing?: boolean
  className?: string
  wrapperClassName?: string
}

export const Loading = ({ ...props }: CellProps) => {
  return <PartVersionSelect {...props} />
}

type SuccessProps = CellSuccessProps<PartVersionSelectQuery, PartCompareQueryVariables> & CellProps
export const Success = ({ ...props }: SuccessProps) => {
  return <PartVersionSelect {...props} />
}

import { FailureErrorBar } from '../Failure/Failure'
export const Failure = (props: CellProps & CellFailureProps) => {
  return <FailureErrorBar userErrorMessage='There was an error loading part versions' {...props}>
    <PartVersionSelect {...props} />
  </FailureErrorBar>
}

type PartVersionSelectProps = CellProps & Partial<CellSuccessProps<PartVersionSelectQuery, PartCompareQueryVariables>>

export const PartVersionSelect = ({ partProto, defaultVersion, name, variant, orderNumber, size, hideRing, className, wrapperClassName }: PartVersionSelectProps) => {
  variant = variant || 'plain'
  size = size || 'default'

  const instances = partProto?.instances.filter(i => {
    if (i.branch === MAIN_BRANCH) return true
    if (i.inChangeOrderNumber === null) return true
    if (i.inChangeOrderNumber === orderNumber) return true
    return false
  }) || [{ version: defaultVersion }]

  const buttonCn = classNames(
    size === 'sm' && 'py-0 leading-4 -ml-1 pl-1 pr-6 min-w-14 [&>*:nth-child(2)]:pr-0 text-xs',
    className
  )

  const optionClasses = classNames(
    'py-3',
    size === 'sm' && '!py-2',
  )

  return <ListBox.HookedListBox variant={variant} name={name} defaultValue={defaultVersion}>
    <ListBox.UncontrolledButton variant={variant} hideRing={hideRing} className={buttonCn} size={size === 'sm' ? 'sm' : undefined} wrapperClassName={wrapperClassName} displayFunction={v => v.value} />
    <ListBox.Options align='left'>
      {instances.map(({ version }) => (
        <ListBox.Option key={version} className={optionClasses} value={version} display={version} />
      ))}
    </ListBox.Options>
  </ListBox.HookedListBox>
}
