import type {
  OrgSettingsQuery,
  CreateUserGroupMutation,
  CreateUserGroupMutationVariables,
  DeleteUserGroupMutation,
  DeleteUserGroupMutationVariables,
  AddUserGroupMemberMutation,
  AddUserGroupMemberMutationVariables,
  RemoveUserGroupMemberMutation,
  RemoveUserGroupMemberMutationVariables,
  SetGlobalWorkflowMutation,
  SetGlobalWorkflowMutationVariables,
  Workflow,
  WorkflowRule,
} from 'types/graphql'

import { useLifeCycleStages, resolveLifeCycle } from "src/lib/lifecycle"

import { CellSuccessProps, CellFailureProps, useMutation } from '@redwoodjs/web'
import { useState } from 'react';
import { Form, Submit, TextField, FormError } from 'src/components/Form';
import * as ListBox from 'src/components/ListBox'
import Button, { FakeButton } from 'src/components/Button'
import { ConditionalModal } from 'src/components/Modal'
import { LoadingSpinnerWithDelay as LoadingSpinner } from 'src/components/Loading'
import {
  UserIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import {
  EllipsisHorizontalIcon,
  ChevronDownIcon,
  ChevronRightIcon,

} from '@heroicons/react/20/solid';

import { UserSelect } from 'src/components/UserSelectCell';

import { reportMutationError } from 'src/lib/reportError';

export const QUERY = gql`
  query OrgSettingsQuery {
    me {
      id
      isSuperUser
    }
    changeOrders(states: [Draft, Review]) {
      reviewerGroups {
        groupId
      }
    }
    currentOrg {
      id
      name
      me {
        role
      }
      members {
        role
        user {
          id
          email
          name
        }
      }
      userGroups {
        id
        name
        members { userId }
      }
      globalWorkflow {
        groupIds
        rules {
          condition {
            type
            stage
          }
          effect {
            type
            groupId
          }
        }
      }
      manualExportMapper
    }
  }
`

export const Loading = () => <div className='flex justify-center p-2'><LoadingSpinner/></div>

export const Empty = () => <div>Empty</div>

import GenericFailure from '../Failure/Failure'
export const Failure = GenericFailure

type SuccessProps = CellSuccessProps<OrgSettingsQuery>
type GlobalMe = SuccessProps['me']
type OrgProps = SuccessProps['currentOrg']
type OpenOrdersProps = SuccessProps['changeOrders']
const hasEscalatedPrivileges = (me: OrgProps['me'], globalMe: GlobalMe) => globalMe.isSuperUser || me?.role === 'Owner' || me?.role === 'Admin'
export const Success = ({queryResult, updating, ...props}: SuccessProps) => {
  return (
    <div className='pb-8 flex flex-col gap-6 mb-12'>
      <div className='bold text-2xl'>
        Teams and Workflow
      </div>
      <UserGroupsPanel {...props.currentOrg} openOrders={props.changeOrders} globalMe={props.me} />
      <GlobalWorkflowPanel {...props.currentOrg} globalMe={props.me}/>
    </div>
  )
}

export const ADD_USER_GROUP_MEMBER_MUTATION = gql`
mutation AddUserGroupMemberMutation ($input: UserGroupMememberInput!) {
  addUserGroupMember(input: $input) { userId }
}
`

export const REMOVE_USER_GROUP_MEMBER_MUTATION = gql`
mutation RemoveUserGroupMemberMutation ($input: UserGroupMememberInput!) {
  removeUserGroupMember(input: $input) { id }
}
`

export const DELETE_USER_GROUP_MUTATION = gql`
mutation DeleteUserGroupMutation ($input: DeleteUserGroupInput!) {
  deleteUserGroup(input: $input)
}
`

const joinUserGroups = ({userGroups, members}: Pick<OrgProps, 'userGroups' | 'members'>) =>
  userGroups.map(g => ({
    ...g,
    members: g.members.map(gm => ({
      ...gm,
      ...members.find(m => m.user.id === gm.userId.toString())!

    }))
  }))

type UserGroupsPanelProps = Pick<OrgProps, 'members' | 'userGroups' | 'me'> & {
  openOrders: OpenOrdersProps
  globalMe: GlobalMe
}
const UserGroupsPanel = (props: UserGroupsPanelProps) => {
  const [showCreateGroupModal, setShowCreateGroupModal] = useState(false)

  const [addGroupMemberMutation, { loading: loadingAddGroupMember, error: addGroupMemeberError }] = useMutation<AddUserGroupMemberMutation, AddUserGroupMemberMutationVariables>(ADD_USER_GROUP_MEMBER_MUTATION)
  const [removeGroupMemberMutation, { loading: loadingRemoveGroupMember, error: removeGroupMemberError }] = useMutation<RemoveUserGroupMemberMutation, RemoveUserGroupMemberMutationVariables>(REMOVE_USER_GROUP_MEMBER_MUTATION)

  const editGroupMember = async (action: 'add' | 'remove', groupId: number, user: Parameters<Parameters<typeof UserSelect>[0]['onSelect']>[0]) => {
    const variables: AddUserGroupMemberMutationVariables & RemoveUserGroupMemberMutationVariables = {
      input: {
        userId: parseInt(user.id),
        groupId
      }
    }

    const mutation = action === 'add' ? addGroupMemberMutation : removeGroupMemberMutation

    const { errors } = await mutation({
      variables,
      refetchQueries: [QUERY],
      awaitRefetchQueries: true
    })
    if (errors) {
      reportMutationError({
        errors,
        variables,
        message: `Failed to ${action === 'add' ? 'add' : 'remove'} group member`
      })
    }
  }

  const fullGroups = joinUserGroups(props)

  return (
    <div className='border border-gray-200 rounded-md min-h-24 p-4 flex flex-col gap-4'>
      <div className='flex gap-1 items-center'>
        <span className='font-semibold'>
          Teams
        </span>
        {hasEscalatedPrivileges(props.me, props.globalMe) &&
          <Button variant='primary' className='ml-auto' onClick={() => setShowCreateGroupModal(true)}>Create Team</Button>
        }
        {showCreateGroupModal && <ConditionalModal onClose={() => setShowCreateGroupModal(false)}>
          <CreateGroupForm onComplete={() => setShowCreateGroupModal(false)}/>
        </ConditionalModal>}
      </div>
      <div>
        <FormError error={addGroupMemeberError || removeGroupMemberError} wrapperClassName="text-white bg-red-500 w-full my-2 p-2 rounded" />
        <div className='flex flex-col gap-2'>
          {fullGroups.map(g =>
            <TeamPanel key={g.id} me={props.me} globalMe={props.globalMe} openOrders={props.openOrders}
              group={g}
              allMembers={props.members}
              loading={loadingAddGroupMember || loadingRemoveGroupMember}
              removeMember={(gid, user) => editGroupMember('remove', gid, user)}
              addMember={(gid, user) => editGroupMember('add', gid, user)}
            />
          )}
          {fullGroups.length === 0 &&
            <div className='text-center italic text-gray-600 bg-gray-100 p-2 rounded-md'>
              There are no teams
            </div>
          }
        </div>
      </div>
    </div>
  )
}

type Member = OrgProps['members'][number]
type TeamPanelProps = {
  allMembers: Member[],
  me: OrgProps['me']
  loading?: boolean,
  globalMe: GlobalMe,
  openOrders: OpenOrdersProps,
  group: ReturnType<typeof joinUserGroups>[number]
  removeMember: (groupId: number, user: Member['user']) => void
  addMember: (groupId: number, user: Member['user']) => void
}
const TeamPanel = (props: TeamPanelProps) => {
  const { me, globalMe, group, openOrders, allMembers, removeMember, addMember, loading: passedLoading } = props

  const [deleteGroupMutation, { error: deleteGroupError, loading: deleteGroupLoading }] = useMutation<DeleteUserGroupMutation, DeleteUserGroupMutationVariables>(DELETE_USER_GROUP_MUTATION)

  const loading = passedLoading || deleteGroupLoading;

  const deleteGroup = async () => {
    const variables: DeleteUserGroupMutationVariables = {
      input: {
        id: parseInt(group.id),
      }
    }
    const { errors } = await deleteGroupMutation({
      variables,
      refetchQueries: [QUERY],
      awaitRefetchQueries: true
    })
    if (errors) {
      reportMutationError({
        errors,
        variables,
        message: 'Failed to delete user group'
      })
    }
  }


  const [showDeleteGroup, setShowDeleteGroup] = useState(false)
  const [open, setOpen] = useState(false)

  const escalated = hasEscalatedPrivileges(me, globalMe)

  const handleAddMember: typeof addMember = (groupId, user) => {
    setOpen(true)
    addMember(groupId, user)
  }

  const handleDelete = async () => {
    await deleteGroup()
    setShowDeleteGroup(false)
  }

  const inOpenOrders = openOrders.filter(order =>
    order.reviewerGroups.find(g => g.groupId.toString() === group.id)
  ).length > 0

  return (
    <div className='border rounded' data-testid={`team-${group.name}-panel`}>
      <div className='flex gap-1 items-center p-2 pr-4'>
        <button onClick={() => setOpen(!open)} className='flex gap-1 p-2 pr-4 min-w-48'>
          {open ?
            <ChevronDownIcon className='h-6 w-4'/> :
            <ChevronRightIcon className='h-6 w-4'/>
          }
          {group.name}
        </button>
        {escalated && <>
          <UserSelect
            users={allMembers.map(m => m.user)}
            displayValue={`Add User`}
            className='ml-auto relative'
            omit={group.members.map(m => m.userId.toString())}
            onSelect={user => handleAddMember(parseInt(group.id), user)}/>
          <ListBox.ListBox value='' onChange={() => setShowDeleteGroup(true)}>
            {({ open }) => (<>
              <div className="relative">
                <ListBox.HeadlessButton>
                  <FakeButton className='h-9' data-testid='team-more'>
                    <EllipsisHorizontalIcon className='w-4 h-4'/>
                  </FakeButton>
                </ListBox.HeadlessButton>
                <ListBox.Options open={open}>
                  <ListBox.Option value='_' display={`Delete Team`}/>
                </ListBox.Options>
              </div>
            </>)}
          </ListBox.ListBox>
          {showDeleteGroup && <ConditionalModal onClose={() => setShowDeleteGroup(false)}>
            <div className='flex flex-col gap-4'>
              <div className='font-medium'>
                Delete Team
              </div>
              {inOpenOrders &&
                <div className='bg-yellow-200 rounded p-2'>
                  This team is reviewing a currently active change order. It will be removed from the order if you delete this team.
                </div>
              }
              <div>
                Are you sure you want to delete <span className='font-bold'>{group.name}</span>?
              </div>
              <div className='flex'>
                <Button onClick={() => setShowDeleteGroup(false)}>Cancel</Button>
                <Button onClick={handleDelete} disabled={loading} variant='primary' className='ml-auto'>Delete</Button>
              </div>
              <FormError error={deleteGroupError} wrapperClassName="text-white bg-red-500 w-full my-2 p-2 rounded" />
            </div>
          </ConditionalModal>}
        </>}
      </div>
      {open &&
        <div className='mb-4'>
          {group.members.map(m =>
            <div className='ml-5 flex gap-1 items-center py-2 p-0 mr-4 border-t last:-mb-4'>
              <div className='text-sm font-light flex gap-1 items-center'>
                <UserIcon className='w-3 h-3'/>
                {m.user.name}
              </div>
              {escalated &&
                <Button className='ml-auto' disabled={loading} onClick={() => removeMember(parseInt(group.id), m.user)}>
                  Remove
                </Button>
              }
            </div>
          )}
          {group.members.length === 0 &&
            <div className='text-center italic text-gray-600 bg-gray-100 p-2 mx-4 rounded-md'>
              No users in team
            </div>
          }
        </div>
      }
    </div>
  )
}

export const CREATE_USER_GROUP_MUTATION = gql`
mutation CreateUserGroupMutation ($input: CreateUserGroupInput!) {
  createUserGroup(input: $input) { id }
}
`

const CreateGroupForm = ({onComplete}: { onComplete: () => void }) => {
  const [createUserGroup, { loading, error }] = useMutation<CreateUserGroupMutation, CreateUserGroupMutationVariables>(CREATE_USER_GROUP_MUTATION)
  type CreateGroupForm = {
    groupName: string
  }
  const submitCreateUserGroup = async (formData: CreateGroupForm) => {
    const variables: CreateUserGroupMutationVariables = {
      input: {
        name: formData.groupName
      }
    }

    const { errors } = await createUserGroup({
      variables,
      refetchQueries: [QUERY],
      awaitRefetchQueries: true
    })
    if (errors) {
      reportMutationError({
        errors,
        variables,
        message: 'Failed to create user group'
      })
    }
    else {
      onComplete()
    }
  }
  return (
    <div className='flex flex-col gap-4'>
      <div>
        <div className="font-medium mb-2">
          Create a new team
        </div>
        <div className='font-light text-sm'>
          A team is a group of users who can be added as required reviewers to change orders.
        </div>
      </div>
      <Form<CreateGroupForm> onSubmit={submitCreateUserGroup} className='flex flex-col gap-6'>
        <TextField name='groupName' placeholder='Group Name' validation={{
          required: true,
          min: 1
        }}/>
        <div className='flex'>
          <Button onClick={onComplete}>Cancel</Button>
          <Submit variant='primary' disabled={loading} className='ml-auto'>Create</Submit>
        </div>
        <FormError error={error} wrapperClassName="text-white bg-red-500 w-full my-2 p-2 rounded" />
      </Form>
    </div>
  )
}

export const SET_GLOBAL_WORKFLOW_MUTATION = gql`
mutation SetGlobalWorkflowMutation ($input: SetGlobalWorkflowInput!) {
  setGlobalWorkflow(input: $input) { id }
}
`

type GlobalWorkflowPanelProps = Pick<OrgProps, 'globalWorkflow' | 'userGroups' | 'me'> & {
  globalMe: GlobalMe
}
const GlobalWorkflowPanel = ({globalWorkflow, userGroups, me, globalMe}: GlobalWorkflowPanelProps) => {
  const stages = useLifeCycleStages()

  const escalated = hasEscalatedPrivileges(me, globalMe)
  const [setGlobalWorkflowMutation, { loading, error }] = useMutation<SetGlobalWorkflowMutation, SetGlobalWorkflowMutationVariables>(SET_GLOBAL_WORKFLOW_MUTATION)
  const setGlobalWorkflow = async (groupIds: number[] | null) => {
    const variables: SetGlobalWorkflowMutationVariables = {
      input: {
        workflow: groupIds ? {
          groupIds,
          rules: globalWorkflow?.rules?.filter(rule => groupIds.includes(rule.effect.groupId!))
        } : null
      }
    }
    const { errors } = await setGlobalWorkflowMutation({
      variables,
      refetchQueries: [QUERY],
      awaitRefetchQueries: true
    })
    if (errors) {
      reportMutationError({
        errors,
        variables,
        message: 'Failed to update workflow'
      })
    }
  }
  const addWorkflowGroup = async (groupId: number) => {
    await setGlobalWorkflow(globalWorkflow?.groupIds.concat(groupId) || [groupId])
  }
  const setWorkflowCondition = async (groupId: number, lifeCycleStage: string ) => {
    const rule: WorkflowRule | undefined = lifeCycleStage === '__always__' ? undefined : {
      condition: {
        type: 'Lifecycle',
        stage: lifeCycleStage
      },
      effect: {
        type: 'AddGroup',
        groupId
      }
    }

    const newRules = [
      ...(globalWorkflow!.rules?.filter(rule => rule.effect.groupId !== groupId) ?? []),
      ...rule ? [rule] : []
    ]

    const workflow: Workflow = {
      groupIds: globalWorkflow!.groupIds,
      rules: newRules.length ? newRules : undefined
    }

    const variables: SetGlobalWorkflowMutationVariables = {
      input: { workflow }
    }
    const { errors } = await setGlobalWorkflowMutation({
      variables,
      refetchQueries: [QUERY],
      awaitRefetchQueries: true
    })
    if (errors) {
      reportMutationError({
        errors,
        variables,
        message: 'Failed to update workflow'
      })
    }
  }

  const getCondition = (groupId: number) => {
    const selectedStage = globalWorkflow?.rules?.find(r => r.effect?.groupId === groupId)?.condition?.stage
    if (selectedStage) {
      const resolved = resolveLifeCycle(stages, selectedStage)
      return resolved.isDeprecated ? undefined : resolved.name
    }
    return undefined
  }

  const removeWorkflowGroup = async (groupId: number) => {
    let newGroups = globalWorkflow?.groupIds.filter(gid => gid !== groupId) || null
    if (!newGroups?.length) {
      newGroups = null;
    }
    await setGlobalWorkflow(newGroups)
  }
  const unusedGroups = userGroups.filter(g =>
    !globalWorkflow?.groupIds.includes(parseInt(g.id))
  )
  return (
    <div className='border border-gray-200 rounded-md min-h-24 p-4 flex flex-col gap-4'>
      <div className='flex flex-col gap-1'>
        <div className='font-semibold flex items-center gap-1'>
          Workflow
        </div>
        <span className='font-light'>These teams need to approve every change order.</span>
      </div>
      {escalated &&
        <ListBox.ListBox onChange={gid => addWorkflowGroup(parseInt(gid))} disabled={loading} value=''>
          <div className={'relative ml-auto'}>
            <ListBox.Button className='min-w-fit pr-8' variant='primary' displayValue={'Add Team'} />
            <ListBox.Options>
              {unusedGroups.length === 0 &&
                <div className='py-1 px-4'>No Teams</div>
              }
              {unusedGroups.map(g =>
                <ListBox.Option key={g.id} className='py-3' value={g.id} display={g.name} />
              )}
            </ListBox.Options>
          </div>
        </ListBox.ListBox>
      }
      <div className='flex flex-col gap-2'>
        {globalWorkflow?.groupIds.map(gid =>
          <div key={gid} className='flex gap-1 items-center border rounded py-2 px-3'>
            {userGroups.find(g => g.id === gid.toString())?.name}
            {escalated &&
              <div className='ml-auto flex gap-2'>
                {stages &&
                  <ListBox.ListBox disabled={loading} onChange={condition => setWorkflowCondition(gid, condition)} value=''>
                    <div className={'relative ml-auto'}>
                      <ListBox.Button className='min-w-fit pr-8' displayValue={`Condition: ${getCondition(gid) ?? 'Always'}`} />
                      <ListBox.Options className='text-sm'>
                        <ListBox.Option key={'__always__'} className='py-3' value={'__always__'} display={'Always'} />
                        {stages.map(s =>
                          <ListBox.Option key={s.key} className='py-3' value={s.key} display={s.name} />
                        )}
                      </ListBox.Options>
                    </div>
                  </ListBox.ListBox>
                }
                <button type='button' disabled={loading} onClick={() => removeWorkflowGroup(gid)}>
                  <TrashIcon className='w-4 text-gray-500'/>
                </button>
              </div>
            }
          </div>
        )}
        {(!globalWorkflow || globalWorkflow.groupIds.length === 0) &&
          <div className='text-center italic text-gray-600 bg-gray-100 p-2 rounded-md'>
            No teams added
          </div>
        }
      </div>
      <FormError error={error} wrapperClassName="text-white bg-red-500 w-full my-2 p-2 rounded" />
    </div>
  )
}
