import { Part, Dependency, PartCategory } from 'types/graphql'

import { Quantity } from '../units'
import { AppContext } from 'src/lib/appContext'
import { CreoLogo, Logo, SolidworksLogo, AltiumLogo } from 'src/components/CadLogo'

import * as carbonQuestConfig from './carbonQuest/config'
import demoConfig from './demo/config'
import nobleConfig from './noble/config'
import charbroilConfig from './charbroil/config'
import * as ozloConfig from './ozlo/config'
import patternConfig from './pattern/config'
import * as copperConfig from './copper/config'

type MappedPart = Pick<Part,
    'partNumber' |
    'cadRev' |
    'summary' |
    'name'>
  & Pick<Dependency, 'quantity' | 'referenceDesignator' | 'units'> & {
    hierarchy: string
    categoryId: string
    isOffTheShelf: string
  }

// export type MapTransform = {
//   type: 'leadingUnderscoreHierarchy'
// }
export type MapExecutionConfig = {
  // Map a BOM column directly to MappedPart part field
  type: 'simple'
  value: string
} | {
  // Do not change the value already in the PLM
  type: 'ignore'
  value?: never
} | {
  // Same as simple but with static type checks
  // on the quantity enum
  type: 'quantity'
  value: Quantity
} | {
  type: 'generateByKey',
  schemaKey: string,
  partNumberKey: string,
  categoryId: string,
  generatorOptions?: Record<string, unknown>
} | {
  type: 'alwaysGenerate',
  categoryId: string,
} | {
  type: 'categoryIdByPartNumber',
  schemaKey: string,
  partNumber: string,
} | {
  type: 'split',
  splitOn: string
  input: string
  // recieves a parts array
  value: string
} | {
  type: 'regex',
  input: string
  regex: string
  value: string
} | {
  type: 'empty'
  value?: never
}

export type Condition = {
  type: 'eq' | 'neq',
  column: string,
  value: string
} | {
  type: 'empty',
  column: string
} | {
  type: 'notEmpty',
  column: string
} | {
  type: 'contains',
  column: string,
  value: string
} | {
  type: 'startsWith',
  column: string,
  value: string
} | {
  type: 'regex'
  column: string
  value: string
}

export type ConditionsAndExection = {
  default?: false
  conditions: Condition[]
  onMatch: MapExecutionConfig
} | {
  default: true
  onMatch: MapExecutionConfig
}

export type PartNumberGeneration = {
  conditions: Condition[]
  onMatch: {
    type: 'generateByKey',
    schemaKey: string,
    partNumberKey: string,
    generatorOptions: Record<string, unknown>
  }
}

export type MapperSpec = MapExecutionConfig | ConditionsAndExection[]

type SourceMapper = {
  // will be mapped to a distributor ID
  distributorName?: MapperSpec
  distributorSku?: MapperSpec
  url?: MapperSpec
}

type PartMap = {
  [Key in keyof MappedPart]: MapperSpec
} & {
  metadata?: Record<string, MapperSpec>
  sources?: SourceMapper[]
};

export type RowsMergeConfig = {
  type: 'MergeRows',
  config: {
    mergeOn: string,
    output: Record<string, string>
  }
}

/*
 * Takes rows that match a criteria and normalizes
 * copies the first row over to the other rows.
 */
export type RowsNormalizeConfig = {
  type: 'NormalizeRows',
  config: {
    normalizeOn: string,
    normalizeColumns: string[]
  }
}

export type RowsExplodeConfig = {
  type: 'SubassemblyExplode',
  config: {
    explodeIdentifier: string
    subassemblyKeyColumn: string,
  }[]
}

export type RowsRemoveConfig = {
  type: 'FilterRemove',
  config: {
    conditions: Condition[]
  }
}

export type RowsKeepConfig = {
  type: 'FilterKeep',
  config: {
    conditions: Condition[]
  }
}

// Translates a leveled hierarchy in order into
// a regular hierarchy
export type RowsOrderedLevelConfig = {
  type: 'OrderedLevelHierarchy',
  config: {
    input: string
  }
}

export type RowsToBomConfigs = (
  RowsExplodeConfig |
  RowsMergeConfig |
  RowsRemoveConfig |
  RowsKeepConfig |
  RowsNormalizeConfig |
  RowsOrderedLevelConfig)[]

type Validator = {
  type: 'duplicate',
  message: string,
  check: string
}

// not sure if this is ever used?
export type ImportOutputFields = {
  metadata: string[]
}

export type FileType = 'xlsx' | 'csv' | 'txt' | 'json' | 'bom'
export type ParserId = 'creo' | 'xlsx' | 'csv'
export type Parser = {
  fileType: FileType
  parserId: 'creo' | 'xlsx' | 'csv'
}

export type MapperConfig = {
  orgIds: string[]
  name: string
  superUserOnly?: boolean
  Logo: Logo
  importOutputFields: ImportOutputFields
  // no longer used
  rootCategoryId: string
  rootIsTopLevel?: boolean
  fileToRows?: {
    parsers?: Parser[]
    xlsx?: {
      enable?: boolean
      firstRow?: number
    }
    csv?: {
      enable: boolean
    }
  }
  rowsToBom?: RowsToBomConfigs,
  standardizeBom: {
    transforms?: {
      hierarchy?: {
        type: 'numericSequenceHierarchy'
        column: string
      }
    }
    columns: PartMap
  },
  generatePartNumbers?: PartNumberGeneration[]
}

export const mapperConfigs: MapperConfig[] = [
  {
    name: 'Solidworks',
    orgIds: ['sparx-demo'],
    rootCategoryId: 'top-level-product',
    Logo: SolidworksLogo,
        importOutputFields: {
      metadata: []
    },
    standardizeBom: {
      columns: {
        summary: {
          type: 'ignore'
        },
        metadata: {},
        hierarchy: {
          value: '{{row.level}}',
          type: 'simple'
        },
        partNumber: {
          value: '{{row.part_number}}',
          type: 'simple'
        },
        cadRev: {
          value: '{{row.revision}}',
          type: 'simple'
        },
        categoryId: {
          value: '{{row.type}}',
          type: 'simple'
        },
        name: {
          value: '{{row.description}}',
          type: 'simple'
        },
        quantity: {
          value: '{{row.qty}}',
          type: 'simple',
        },
        units: {
          value: 'each',
          type: 'quantity'
        },
        isOffTheShelf: {
          value: '{{row.off_the_shelf}}',
          type: 'simple'
        },
      }
    }
  },
  {
    name: 'Creo',
    orgIds: ['demo-1'],
    rootCategoryId: 'top-level-product',
    Logo: CreoLogo,
    importOutputFields: {
      metadata: []
    },
    standardizeBom: {
      columns: {
        summary: {
          type: 'ignore'
        },
        metadata: {},
        hierarchy: {
          value: '{{row.level}}',
          type: 'simple'
        },
        partNumber: {
          value: '{{row.part_number}}',
          type: 'simple'
        },
        cadRev: {
          value: '{{row.revision}}',
          type: 'simple'
        },
        categoryId: {
          value: 'mechanical-part',
          type: 'simple'
        },
        name: {
          value: '{{row.description}}',
          type: 'simple'
        },
        quantity: {
          value: '{{row.qty}}',
          type: 'simple',
        },
        units: {
          value: 'each',
          type: 'quantity'
        },
        isOffTheShelf: {
          value: '{{row.off_the_shelf}}',
          type: 'simple'
        },
      }
    }
  },
  {
    name: 'Altium',
    orgIds: ['demo-1'],
    rootCategoryId: 'top-level-product',
    Logo: AltiumLogo,
    importOutputFields: {
      metadata: []
    },
    standardizeBom: {
      columns: {
        summary: {
          type: 'ignore'
        },
        metadata: {},
        // when building the altium mapper, remove this
        // field and add a "flat" option to build it
        // automatically
        hierarchy: {
          value: '{{row.level}}',
          type: 'simple'
        },
        partNumber: {
          value: '{{row.part_number}}',
          type: 'simple'
        },
        categoryId: {
          value: 'electrical-component',
          type: 'simple'
        },
        name: {
          value: '{{row.description}}',
          type: 'simple'
        },
        quantity: {
          value: '{{row.qty}}',
          type: 'simple',
        },
        units: {
          value: 'each',
          type: 'quantity'
        },
        isOffTheShelf: {
          value: '{{row.off_the_shelf}}',
          type: 'simple'
        },
      }
    }
  },
  nobleConfig,
  carbonQuestConfig.plantMapper,
  carbonQuestConfig.partsImport,
  demoConfig,
  ozloConfig.electricalConfig,
  ozloConfig.mechanicalConfig,
  ozloConfig.allPartsConfig,
  charbroilConfig,
  patternConfig,
  copperConfig.allParts,
  copperConfig.bom
] as const

export const getMapper = (orgId: string, name: string) => {
  if (orgId.startsWith('changeOrg')) return demoConfig

  return mapperConfigs.find(m => {
    return m.orgIds.includes(orgId) && m.name === name
  })
}

export const getMapperOptions = (orgId: string, { me }: AppContext) => {
  if (orgId.startsWith('changeOrg')) return [demoConfig]
  return mapperConfigs.filter(m => {
    if (m.superUserOnly && !me.isSuperUser) return false
    return m.orgIds.includes(orgId)
  })
}
