import type { MapperConfig } from '../mapperConfigs'

import { DocumentIcon } from '@heroicons/react/24/outline'

export const electricalConfig: MapperConfig = {
  name: 'PCBA',
  orgIds: ['ozlo', 'ozlo-sandbox'],
  rootCategoryId: '203',
  rootIsTopLevel: true,
  Logo: DocumentIcon,
  importOutputFields: {
    metadata: [
      'manufacturer',
      'manufacturer-part-number'
    ]
  },
  standardizeBom: {
    columns: {
      summary: {
        type: 'ignore'
      },
      metadata: {
        manufacturer: {
          type: 'simple',
          value: '${row.mfg_name || row.mfr_name}',
        },
        'manufacturer-part-number': {
          type: 'simple',
          value: '{{row.mfg_pn}}',
        }
      },
      hierarchy: {
        type: 'simple',
        value: '1.{{rowIndex}}',
      },
      partNumber: {
        type: 'generateByKey',
        schemaKey: 'smart',
        partNumberKey: '{{row.mfg_pn}}',
        categoryId: '300',
      },
      categoryId: {
        type: 'simple',
        value: '300',
      },
      isOffTheShelf: {
        value: 'true',
        type: 'simple'
      },
      name: {
        value: '{{row.description}}',
        type: 'simple'
      },
      quantity: {
        value: '{{row.quantity}}',
        type: 'simple',
      },
      units: {
        value: 'each',
        type: 'quantity'
      },
      referenceDesignator: {
        type: 'simple',
        value: '{{row.designator}}'
      }
    }
  }
}

export const mechanicalConfig: MapperConfig = {
  name: 'Mechanical Assembly',
  rootCategoryId: '900',
  rootIsTopLevel: true,
  orgIds: ['ozlo', 'ozlo-sandbox'],
  Logo: DocumentIcon,
  importOutputFields: {
    metadata: [
      'cm-pn',
      'vendor',
      'vendor-part-number'
    ]
  },
  rowsToBom: [{
    type: 'FilterKeep',
    config: {
      conditions: [{
        type: 'regex',
        column: 'ozlo_pn',
        value: '^\\d{7}-\\d{2}$',
      }]
    }
  }],
  standardizeBom: {
    columns: {
      summary: {
        type: 'ignore'
      },
      metadata: {
        'cm-pn': {
          type: 'simple',
          value: '{{row.cm_pn}}',
        },
        'vendor': {
          type: 'simple',
          value: '{{row.vendor}}',
        },
        'vendor-part-number': {
          type: 'simple',
          value: '{{row.vendor_pn}}',
        }
      },
      hierarchy: {
        type: 'simple',
        value: '1.{{rowIndex}}',
      },
      partNumber: {
        type: 'simple',
        value: '{{row.ozlo_pn}}'
      },
      cadRev: {
        type: 'simple',
        value: '{{row.revision}}'
      },
      categoryId: {
        type: 'regex',
        input: '{{row.ozlo_pn}}',
        regex: '^(\\d{3})',
        value: '{{match.0}}',
      },
      isOffTheShelf: {
        value: '{{row.off_the_shelf}}',
        type: 'simple'
      },
      name: {
        value: '{{row.description}}',
        type: 'simple'
      },
      quantity: {
        value: '${row.qty}',
        type: 'simple',
      },
      units: {
        value: '${row.unit.toLowerCase()}',
        type: 'simple'
      }
    }
  }
}

// Part Number	Variant	Description	Category	Comments	Vendor	Vendor Part Number
export const allPartsConfig: MapperConfig = {
  name: 'All Parts',
  superUserOnly: true,
  rootCategoryId: '900',
  orgIds: ['ozlo', 'ozlo-sandbox'],
  rootIsTopLevel: true,
  Logo: DocumentIcon,
  importOutputFields: {
    metadata: [
      'vendor',
      'vendor-part-number'
    ]
  },
  rowsToBom: [{
    type: 'FilterRemove',
    config: {
      conditions: [{
        type: 'empty',
        column: 'part_number',
      }]
    }
  }],
  standardizeBom: {
    columns: {
      summary: {
        type: 'ignore'
      },
      metadata: {
        'vendor': {
          type: 'simple',
          value: '{{row.vendor}}',
        },
        'vendor-part-number': {
          type: 'simple',
          value: '{{row.vendor_pn}}',
        }
      },
      hierarchy: {
        type: 'simple',
        value: '1.{{rowIndex}}',
      },
      partNumber: {
        type: 'simple',
        value: '${row.part_number}${row.variant}'
      },
      categoryId: {
        type: 'regex',
        input: '{{row.part_number}}',
        regex: '^(\\d{3})',
        value: '{{match.0}}',
      },
      isOffTheShelf: {
        type: 'simple',
        value: 'false'
      },
      name: {
        value: '{{row.description}}',
        type: 'simple'
      },
      quantity: {
        value: '1',
        type: 'simple',
      },
      units: {
        value: 'each',
        type: 'quantity'
      }
    }
  }
}
