import type {
  FindChangeOrderQuery,
  FindChangeOrderQueryVariables,
  SetChangeOrderStateMutation,
  SetChangeOrderStateMutationVariables,
  ChangeOrderState,
  AddChangeOrderCommentMutation,
  AddChangeOrderCommentMutationVariables,
} from 'types/graphql'

import { QUERY as CO_QUERY, ADD_COMMENT_MUTATION } from './ChangeOrderCell'

import { ReactNode, useContext, useState } from 'react'
import { prettyDateTime } from 'src/lib/formatters';

import { useForm } from '@redwoodjs/forms'
import { FormError } from 'src/components/Form'
import type { ChangeOrderLogPayload } from 'api/src/services/changeOrders/changeOrders'
import { reportMutationError } from 'src/lib/reportError';
import {
  CheckIcon,
  UserPlusIcon,
  UserMinusIcon,
  PencilIcon,
  PlusIcon,
  StopIcon,
  SparklesIcon,
  ChevronUpDownIcon,
} from '@heroicons/react/20/solid';
import {
  BookOpenIcon,
  DocumentPlusIcon,
  ChatBubbleLeftIcon,
  DocumentArrowUpIcon,
  FlagIcon,
  TrashIcon,
  ForwardIcon,
} from '@heroicons/react/24/outline'
import { type CellSuccessProps, useMutation } from '@redwoodjs/web'

import { getGroupReviewStatus, WorkflowContext } from './Reviewers'
import Button from 'src/components/Button'
import { Form, TextArea, Submit } from 'src/components/Form';
import humanizeString from 'humanize-string';
import AppContext from 'src/lib/appContext';
import englishJoin from 'src/lib/englishJoin'

export const SET_CHANGE_ORDER_STATE_MUTATION = gql`
mutation SetChangeOrderStateMutation ($orderNumber: Int!, $state: ChangeOrderState!) {
  setChangeOrderState(number: $orderNumber, input: { state: $state }) {
    state
  }
}
`

type SuccessProp = CellSuccessProps<FindChangeOrderQuery, FindChangeOrderQueryVariables>
type COProp = SuccessProp['changeOrder']

export default ({ changeOrder, hasEscalatedPrivileges }: { changeOrder: COProp, hasEscalatedPrivileges: boolean }) => {
  const appContext = useContext(AppContext)
  const workflowData = useContext(WorkflowContext)

  const [setChangeOrderState, { loading, error: stateChangeError }] = useMutation<SetChangeOrderStateMutation, SetChangeOrderStateMutationVariables>(SET_CHANGE_ORDER_STATE_MUTATION)
  const updateState = async (state: ChangeOrderState) => {
    const variables = {
      orderNumber: changeOrder.number,
      state
    }
    const { errors } = await setChangeOrderState({
      variables,
      refetchQueries: [CO_QUERY],
      awaitRefetchQueries: true
    })
    if (errors) {
      reportMutationError({
        errors,
        variables,
        message: `Failed to change order state to ${state}`
      })
    }
  }

  type CommentForm = {
    comment: string
  }
  const formMethods = useForm<CommentForm>()
  const [comment] = formMethods.watch(['comment'])
  const [addComment] = useMutation<
    AddChangeOrderCommentMutation,
    AddChangeOrderCommentMutationVariables
  >(ADD_COMMENT_MUTATION)


  const handleCommentSubmit = async (data: CommentForm) => {
    const { comment } = data
    const variables = { message: comment, orderNumber: changeOrder.number }
    const { errors } = await addComment({
      variables,
      refetchQueries: [CO_QUERY],
      awaitRefetchQueries: true
    })
    formMethods.reset()
    if (errors) {
      reportMutationError({
        errors,
        variables,
        message: `Failed to comment on change order`
      })
    }
  }

  type ChangeIconProps = {
    color?: string
    icon?: ReactNode
  }
  const changeIcon = ({ icon, color }: ChangeIconProps) => {
    color = color || 'bg-white'
    const iconClass = `
      rounded-xl border border-gray-200 ${color}
      w-7 h-7 flex items-center justify-center
      absolute top-4 left-1/2 -translate-x-1/2
    `
    return <div className='flex relative min-w-10 min-h-16'>
      <div className='flex-1 border-r border-gray-200'></div>
      <div className='flex-1'></div>
      <div className={iconClass}>{icon}</div>
    </div>
  }

  const highlight = (children: ReactNode) => {
    return <span className='font-medium text-gray-900'>
      {children}
    </span>
  }
  const reviewers = changeOrder.reviewers.filter(r => r.role === 'Reviewer')
  const approveReviews = changeOrder.reviewers.filter(r => r.response === 'Approve')
  const approvesCount = reviewers.filter(r =>
    approveReviews.find(approve => r.user.id === approve.user.id)
  ).length
  const requiredReviewsApproved = approvesCount >= reviewers.length;
  const changeOrderApproved = reviewers.length > 0 && requiredReviewsApproved
  const reviewMessage = () => {
    if (reviewers.length === 0) {
      return 'There are no reviewers for this change order'
    }
    if (!requiredReviewsApproved) {
      return `${approvesCount} out of ${reviewers.length} reviewers have approved the change order`
    }
    return `All reviewers have approved the change order`
  }

  const { mergeConflicts, invalidLifeCycle } = changeOrder;
  const pluralConflicts = (mergeConflicts && mergeConflicts.length > 1) ?? false
  const pluralLifeCycleIssues = (invalidLifeCycle && invalidLifeCycle.length > 1) ?? false
  const reviewStatus = getGroupReviewStatus(workflowData)
  const groupsMissing = reviewStatus.valid ? [] : Object.values(reviewStatus.groupsReviews).filter(g => g.activeRule ? g.ruleConditionMet : true).filter(g => !g.approved)
  const actionsPending = Boolean(mergeConflicts?.length) || invalidLifeCycle?.length || !reviewStatus.valid

  const OrderStatus = ({title, body}: {title: ReactNode, body: ReactNode}) => <>
    <div className='font-medium mb-2'>
      {title}
    </div>
    <div className='text-sm mb-4'>
      {body}
    </div>
  </>

  const statusPanel = () => {
    if (changeOrder.state === 'Draft') {
      return <div className={`border-2 border-brand-500 rounded-lg p-4`}>
        <OrderStatus
          title='Change order is in draft state'
          body='Change the state to ready for review and reviewers will be notified'/>
        <Button writeOnly='left' variant='primary' disabled={loading} onClick={() => updateState('Review')}>Ready for Review</Button>
      </div>
    }
    if (changeOrder.state === 'Review') {
      const changeStateBorder = !changeOrderApproved ? 'border-red-300' : `border-brand-500`
      return <div className={`border-2 rounded-lg p-4 ${changeStateBorder}`}>
        { mergeConflicts?.length ?
            <OrderStatus
              title='Merge Conflicts'
              body={<>
                {'The change order cannot be applied due to '}
                {pluralConflicts ?
                  <>
                    merge conflicts with the parts {englishJoin(mergeConflicts.map((n) =>
                      <span className='font-semibold'>{'#' + n}</span>
                    ))}
                  </> :
                  <>a merge conflict with <span className='font-semibold'>#{mergeConflicts[0]}</span></>
                }
                . Please go to the "Changes" tab to review the conflict{pluralConflicts && 's'} and resolve {pluralConflicts ? 'them' : 'it'}.
              </>} />
          : invalidLifeCycle?.length ?
            <OrderStatus
              title='Invalid lifecycle status'
              body={<>
                {'The change order cannot be applied due to '}
                {pluralLifeCycleIssues ?
                  <>
                    invalid lifecycle states for {englishJoin(invalidLifeCycle.map(l =>
                      <span className='font-semibold'>{'#' + l}</span>
                    ))}.
                  </> :
                  <>
                    an invalid lifecycle state for <span className='font-semibold'>
                      #{invalidLifeCycle[0]}
                    </span>.
                  </>
                }
              </>} />
          : !reviewStatus.valid ?
            <OrderStatus
              title='Change order is in review'
              body={
                (groupsMissing.length === 1) ?
                  `Missing approval from reviewer group ${groupsMissing[0]!.name}.`
                  :
                  <>
                    Missing approval from reviewer groups {englishJoin(groupsMissing.map(g => g.name))}.
                  </>
              } />
          :
          <OrderStatus
            title='Change order is in review'
            body={reviewMessage()} />
        }
        <Button writeOnly='left' variant={!changeOrderApproved ? 'red' : 'primary'} onClick={() => updateState('Complete')} disabled={loading || (!hasEscalatedPrivileges && Boolean(actionsPending))}>
          {hasEscalatedPrivileges && actionsPending ?
            'Apply via Override' :
            'Apply Change Order'
          }
        </Button>
        <FormError error={stateChangeError} wrapperClassName="text-white bg-red-500 w-full my-2 p-2 rounded" />
        </div>
    }
    if (changeOrder.state === 'Cancelled') {
      return <div className={`border-2 border-gray-200 rounded-lg p-4`}>
        <OrderStatus
          title='Change order cancelled'
          body='You may restore the change order back to draft state'/>
        <Button disabled={loading} onClick={() => updateState('Draft')}>Restore Change Order</Button>
        </div>
    }

    // Complete
    return null
  }

  let log: (typeof changeOrder.log[number] | 'collapse')[] = changeOrder.log

  const [showAll, setShowAll] = useState(false)

  const startLength = 2;
  const endLength = 6;
  if (!showAll && log.length > 11) {
    log = [
      ...log.slice(0, startLength),
      'collapse',
      ...log.slice(-endLength)
    ]
  }

  return (
    <div className='flex flex-col gap-6 pl-5'>
      <div>{
        log.map((log) => {
          if (log === 'collapse') {
            return (
              <div className='flex -mr-2'>
                <button
                  onClick={() => setShowAll(true)}
                  className='flex flex-1 rounded-md justify-center my-4 bg-gray-100 p-2 text-xs hover:bg-gray-200'>
                  <div className='flex items-center gap-1'>
                    Show More Activity ({changeOrder.log.length - startLength - endLength}) <ChevronUpDownIcon className='w-4' />
                  </div>
                </button>
              </div>
            )
          }

          const logItem = () => {
            if (log.action === 'AddDelta') {
              const payload = log.payload as ChangeOrderLogPayload['AddDelta']
              if (payload.delta.type === 'Patch' || payload.delta.type === 'Push') {
                const changes = Object.keys(payload.delta.part || {}).filter(k => k !== 'updateToPublishId').map(s => humanizeString(s).toLowerCase())
                const body = payload.delta.type === 'Push' && !changes.length ? <div>
                <span className='font-medium'>{log.user.name}</span> added {highlight(`#${log.partNumber} - ${payload.partName}`)} to change order </div> : <div>
                    <span className='font-medium'>{log.user.name}</span> updated {changes.join(', ')} for part {highlight(`#${log.partNumber} - ${payload.partName}`)}</div>
                return {
                  icon: <PencilIcon className='h-4 w-4' />,
                  body
                }
              }
              if (payload.delta.type === 'Version') {
                return {
                  icon: <PlusIcon className='h-4 w-4' />,
                  body: <div>
                    <span className='font-medium'>{log.user.name}</span> changed part {highlight(`#${log.partNumber}`)} version to {highlight(payload.delta.version)} </div>
                }
              }
              if (payload.delta.type === 'Create') {
                return {
                  icon: <PlusIcon className='h-4 w-4' />,
                  body: <div>
                    <span className='font-medium'>{log.user.name}</span> created part {highlight(`#${log.partNumber}`)} - {highlight(payload.partName)}  </div>
                }
              }
            }
            if (log.action === 'RemovePart') {
              const payload = log.payload as ChangeOrderLogPayload['RemovePart']
                return {
                  icon: <TrashIcon className='h-4 w-4' />,
                  body: <div>
                    <span className='font-medium'>{log.user.name}</span> removed part {highlight(`#${log.partNumber}`)} {payload.partName && <>- {highlight(payload.partName)}</>} from the order
                  </div>
                }
            }
            if (log.action === 'RebasePart') {
              const payload = log.payload as ChangeOrderLogPayload['RebasePart']
                return {
                  icon: <ForwardIcon className='h-4 w-4'/>,
                  body: <div>
                    <span className='font-medium'>{log.user.name}</span> rebased part {highlight(`#${log.partNumber}`)} {payload.partName && <>- {highlight(payload.partName)}</>} onto the current version
                  </div>
                }
            }
            if (log.action === 'ImportAssembly') {
              const payload = log.payload as ChangeOrderLogPayload['ImportAssembly']
              const importTypes = {
                created: 'created',
                updated: 'updated'
              }
              const importType = importTypes[payload.deltaType]
              return {
                icon: <DocumentArrowUpIcon className='h-4 w-4' />,
                body: <div>{highlight(log.user.name)} {importType} assembly {highlight(`#${log.partNumber} - ${payload.rootPartName}`)} with an import of {payload.partIds.length} parts</div>
              }
            }
            if (log.action === 'Comment') {
              const payload = log.payload as ChangeOrderLogPayload['Comment']
              return {
                bubble: payload.message,
                icon: <ChatBubbleLeftIcon className='h-4 w-4' />,
                body: <div>{highlight(log.user.name)} commented</div>
              }
            }
            if (log.action === 'ApproveChangeOrder') {
              const payload = log.payload as ChangeOrderLogPayload['ApproveChangeOrder']
              return {
                bubble: payload.message,
                color: 'bg-green-200',
                icon: <CheckIcon className='h-4 w-4' />,
                body: <div>{highlight(log.user.name)} approved change order {payload.message ? `with comment` : ""}</div>
              }
            }
            if (log.action === 'RequestChanges') {
              const payload = log.payload as ChangeOrderLogPayload['RequestChanges']
              return {
                bubble: payload.message,
                color: 'bg-yellow-200',
                icon: <div className='p-4 font-bold'>!</div>,
                body: <div>{highlight(log.user.name)} requested changes {payload.message ? `with comment` : ""}</div>
              }
            }
            if (log.action === 'Creation') {
              return {
                icon: <DocumentPlusIcon className='h-4 w-4' />,
                body: <div>{highlight(log.user.name)} created draft change order</div>
              }
            }
            if (log.action === 'AddReviewer') {
              const payload = log.payload as ChangeOrderLogPayload['AddReviewer']
              return {
                icon: <UserPlusIcon className='h-4 w-4' />,
                body: <div>{highlight(log.user.name)} added {payload.role === 'Reviewer' ? 'reviewer' : 'watcher'} {highlight(payload.user.name)}</div>
              }
            }
            if (log.action === 'DeleteReviewer') {
              const payload = log.payload as ChangeOrderLogPayload['DeleteReviewer']
              return {
                icon: <UserMinusIcon className='h-4 w-4' />,
                body: <div>{highlight(log.user.name)} removed {payload.role === 'Reviewer' ? 'reviewer' : 'watcher'} {highlight(payload.user.name)}</div>
              }
            }
            if (log.action === 'UpdateChangeOrder') {
              const payload = log.payload as ChangeOrderLogPayload['UpdateChangeOrder']
              if (payload.changes.description) {
                return {
                  icon: <PencilIcon className='h-4 w-4' />,
                  body: <div>{highlight(log.user.name)} updated change order description</div>
                }
              }
              if (payload.changes.name) {
                return {
                  icon: <PencilIcon className='h-4 w-4' />,
                  body: <div>{highlight(log.user.name)} updated change order name</div>
                }
              }
            }
            if (log.action === 'ChangeState') {
              const payload = log.payload as ChangeOrderLogPayload['ChangeState']
              if (payload.state === 'Review') {
                return {
                  icon: <BookOpenIcon className='h-4 w-4' />,
                  body: <div>{highlight(log.user.name)} set change order state to review</div>
                }
              }
              if (payload.state === 'Draft') {
                return {
                  icon: <SparklesIcon className='h-4 w-4' />,
                  body: <div>{highlight(log.user.name)} set change order state to draft</div>
                }
              }
              if (payload.state === 'Complete') {
                return {
                  icon: <FlagIcon className='h-4 w-4' />,
                  body: <div>{highlight(log.user.name)} applied the change order {
                    payload.lifeCycleOverride ? 'via lifecycle validation override' :
                    (payload.override && 'via workflow override')
                  }</div>
                }
              }
              if (payload.state === 'Cancelled') {
                return {
                  color: 'bg-red-400',
                  icon: <StopIcon className='h-3 w-3' />,
                  body: <div>{highlight(log.user.name)} cancelled the change order</div>
                }
              }
            }
          }
          const { body, icon, color, bubble } = logItem() ?? {}
          return <div key={log.id} className='flex text-gray-600 gap-3'>
            {changeIcon({ icon, color })}
            <div className='mt-4 flex-1 flex flex-col'>
              <div className='flex items-center h-7'>
                <div className='flex flex-col gap-4 flex-1'>
                  {body}
                </div>
                <div className='text-xs'>{prettyDateTime(new Date(log.createdAt))}</div>
              </div>
              {bubble && <div className='border border-gray-200 rounded-lg p-4 flex-1 min-w-96 my-3 max-w-2xl whitespace-prewrap'>{bubble}</div>}
            </div>
          </div>
        })
      }</div>
      <div>
        {statusPanel()}
      </div>

      {appContext?.canEdit &&
        <Form
          className='border rounded-lg p-4'
          onSubmit={handleCommentSubmit}
          formMethods={formMethods}>
          <div className='font-medium mb-2'>
            Add a comment
          </div>
          <div>
            <TextArea name='comment' required />
          </div>
          <div className='flex mt-4'>
            <div className='flex gap-2 ml-auto'>
              {changeOrder.state !== 'Cancelled' &&
                <Button writeOnly disabled={changeOrder.state === 'Complete' || loading} onClick={() => updateState('Cancelled')}>Cancel Change Order</Button>
              }
              <Submit writeOnly variant='primary' disabled={!comment}>Submit Comment</Submit>
            </div>
          </div>
        </Form>
      }
    </div>
  )
}
