import type { ChangeOrderSelectQuery, ChangeOrderState } from 'types/graphql'
import * as ListBox from '../ListBox'
import type { CellSuccessProps, CellFailureProps } from '@redwoodjs/web'

export const QUERY = gql`
  query ChangeOrderSelectQuery {
    changeOrders {
      _id
      number
      name
      state
    }
  }
`

export type SelectedChangeOrder = {
  number: number
  name: string
  state: ChangeOrderState
}
type CellProps = {
  onSelect: (value: SelectedChangeOrder) => void
  placeholder: string
  className?: string
  omit?: number[]
  loading?: boolean
  onCreate?: () => void
  disabled?: boolean
};

export const Loading = ({ ...props }: CellProps) => {
  return <ChangeOrderSelect {...props} changeOrders={[]} loading />
}

import { FailureErrorBar } from '../Failure/Failure'
export const Failure = (props: CellProps & CellFailureProps) => {
  return <FailureErrorBar userErrorMessage='There was an error loading change orders' {...props}>
    <ChangeOrderSelect {...props} changeOrders={[]} />
  </FailureErrorBar>
}

export const Success = ({ ...props }: CellProps & CellSuccessProps<ChangeOrderSelectQuery>) => {
  return <ChangeOrderSelect {...props} />
}

export const ChangeOrderSelect = ({ changeOrders, onSelect, placeholder, className, omit, onCreate, disabled }: CellProps & CellSuccessProps<ChangeOrderSelectQuery>) => {
  const containerClass = 'relative ' + className ?? ''

  if (disabled) {
    return <ListBox.ListBox value='' disabled={true}>
      <div className={containerClass}>
        <ListBox.Button className='min-w-full' displayValue={placeholder} disabled={disabled} />
      </div>
    </ListBox.ListBox>
  }

  const values = changeOrders.filter(changeOrder => {
    if (omit && omit.includes(changeOrder.number)) return false
    if (changeOrder.state === 'Complete') return false
    if (changeOrder.state === 'Cancelled') return false
    return true
  })
  const handleSelect = (value: string) => {
    if (value === 'new') {
      onCreate!()
      return
    }
    const changeOrderNumber = Number(value)
    const changeOrder = changeOrders.find(changeOrder => changeOrder.number === changeOrderNumber)!
    onSelect({
      number: changeOrder.number,
      name: changeOrder.name,
      state: changeOrder.state,
    })
  }

  const optionClass = 'py-3 text-right'
  const dividerClass = values.length > 0 ? 'border-t border-gray-200' : ''
  const createNew = onCreate ?
    <div className={dividerClass}>
      <ListBox.Option
        key={'new'}
        className={optionClass}
        value={'new'}
        display='Create Change Order' />
    </div> : null
  return <ListBox.ListBox onChange={handleSelect} value='' disabled={!onCreate && values.length === 0}>
    {({ open, disabled }) => (
      <>
        <div className={containerClass}>
          <ListBox.Button className='min-w-full' displayValue={placeholder} disabled={disabled} />
          <ListBox.Options className='min-w-full' open={open}>
            {values.map((changeOrder) => (
              <ListBox.Option
                key={changeOrder.number}
                className={optionClass}
                value={String(changeOrder.number)}
                display={`#${changeOrder.number} ${changeOrder.name}`} />
            ))}
            {createNew}
          </ListBox.Options>
        </div>
      </>
    )}
  </ListBox.ListBox>
}
