import { reportMutationError } from 'src/lib/reportError'
import {
  EditPartMutation,
  EditPartMutationVariables,
  ChangeOrderNewEditMutation,
  ChangeOrderNewEditMutationVariables,
  ChangeOrder,
  Part,
  PartHierarchy as PartHierarchyType,
  Dependency,
  Maybe,
  PartCategory
} from 'types/graphql'

import { findAncestors } from 'api/src/lib/bom'
import { EDIT_PART_MUTATION, CHANGE_ORDER_NEW_EDIT_MUTATION, QUERY as CHANGE_ORDER_QUERY } from './ChangeOrderChangesCell'
import calculateNextVersion from 'src/lib/calculateNextVersion'
import { useMutation } from '@redwoodjs/web'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import Button from '../Button'
import PartHierarchy, { SubjectPart } from '../PartHierarchy/PartHierarchy'
import { nullControlSeq } from 'api/src/shared/nullable'

/*
Find all parts with new versions
- If their parent in the hierarchy has an old dep pointer then render "update"
  - Check their parent in the change order first, the dep might be gone
- If their parent in the hierarch has the update, render "updated to"
- Expand to show any part with an update, and their parent

For all parts with new versions
- Find their place in the hierarchy, check parent, mark as update or updated
- Find each ancestor, mark as expanded
- Filter remaining parts that are not either expanded or updated/ could update

In the part hierarchy
- Create subjectParts to accept a highlight color, a warning, a button and a handler
- When button is clicked, run a mutation and reload the query

Extra
- Check all part dependencies, highlight added parts and removed parts
- Ensure neither get an update button
- Support for no version change update
*/

type OrphanPartHierarchyDependency = Pick<Dependency, 'quantity' | 'section' | 'units' | 'referenceDesignator' | 'groupId'> & {
  to: Pick<Part, 'partNumber' | 'version'>
}
type OrphanPartHierarchyPart = Pick<Part, 'partNumber' | 'version' | 'publishId'> & {
  dependencies: OrphanPartHierarchyDependency[]
  proto: {
    category: Pick<PartCategory, 'name'>
  }
}
type OrphanPartHierarchy = Pick<PartHierarchyType, 'hierarchy'> & {
  part: OrphanPartHierarchyPart
}
type OrphanPart = Pick<Part, 'partNumber' | 'version' | 'publishId'> & {
  partNumber: string
  proto: {
    category: Pick<PartCategory, 'name'>
  }
  dependencies: OrphanPartHierarchyDependency[]
  hierarchy: OrphanPartHierarchy[]
}

type IncludedPartDependency = Pick<Dependency, 'section' | 'quantity' | 'units' | 'referenceDesignator' | 'groupId'> & {
  to: Pick<Part, 'partNumber' | 'version'>
}

type IncludedPartHead = Pick<Part, 'partNumber' | 'version'> & {
  dependencies: IncludedPartDependency[]
}
type IncludedPart = IncludedPartHead & {
  updateTo?: Maybe<IncludedPartHead>
}
type VersionChangeSummaryProps = {
  changeOrder: Pick<ChangeOrder, 'number'> & {
    baseOrphans: OrphanPart[]
    includedParts: IncludedPart[]
  }
}

const VersionChangeSummary: React.FC<VersionChangeSummaryProps> = React.memo(({ changeOrder }) => {
  const { includedParts } = changeOrder
  const partsWithNewVersions = includedParts.filter(p => {
    return p.updateTo && p.version !== p.updateTo.version
  })
  const [editPartMutation] = useMutation<EditPartMutation, EditPartMutationVariables>(EDIT_PART_MUTATION)
  const [addDeltaMutation] = useMutation<ChangeOrderNewEditMutation, ChangeOrderNewEditMutationVariables>(CHANGE_ORDER_NEW_EDIT_MUTATION)

  const relevantHierarchies = changeOrder.baseOrphans.filter(orphan => {
    return partsWithNewVersions
      .some(includedPart => orphan.partNumber === includedPart.partNumber || orphan.hierarchy.some(h => includedPart.partNumber === h.part.partNumber))
  })

  const hierarchies = relevantHierarchies.map(orphan => {
    const hierarchyWithRoot = [...orphan.hierarchy, {
      part: { ...orphan },
      hierarchy: '1',
    }]
    const updatedOrUpdateableNodes = hierarchyWithRoot
      // filter out nodes that don't have a new version in the change order
      .filter(n => partsWithNewVersions.find(p => p.partNumber === n.part.partNumber))
      .map(node => {
        const partInChangeOrder = partsWithNewVersions.find(p => p.partNumber === node.part.partNumber)!

        const parent = findParent<(typeof hierarchyWithRoot)>(hierarchyWithRoot, node.hierarchy)

        if (!parent) {
          return {
            updated: true,
            node,
            parent
          }
        }
        const parentInCo = includedParts.find(p => p.partNumber === parent?.part.partNumber)

        if (!parentInCo) return {
          updated: false,
          node,
          parent
        }

        const parentReferencesVersion = parentInCo.dependencies.find(d => d.to.partNumber === node.part.partNumber)?.to.version
        const parentReferencedVersion =
          parentInCo.updateTo?.dependencies.find(d => d.to.partNumber === node.part.partNumber)?.to.version
          ?? changeOrder.includedParts.find(p => p.partNumber === node.part.partNumber)?.updateTo?.version

        return {
          updated: parentReferencesVersion === partInChangeOrder.version,
          previousVersion: parentReferencedVersion,
          node,
          parent
        }
      }).filter(info => {
        const parentInCo = includedParts.find(p => p.partNumber === info.parent?.part.partNumber)

        // filter out if the dependency relationship has actually been removed in the change order
        if (parentInCo &&
          !parentInCo.dependencies.some(d => d.to.partNumber === info.node.part.partNumber)) {
          return false
        }
        return true
      })

    const subjectParts = updatedOrUpdateableNodes.map(nodeInfo => {
      if (!nodeInfo.parent) return {
        hierarchy: nodeInfo.node.hierarchy,
      } as SubjectPart

      const partInChangeOrder = includedParts.find(p => p.partNumber === nodeInfo.node.part.partNumber)!

      if (nodeInfo.updated) {
        // The parent should always be in the change order if node info has been updated
        const handleUndo = async () => {
          if (!nodeInfo.parent) throw new Error('Parent not found')
          const parentInChangeOrder = includedParts.find(p => p.partNumber === nodeInfo.parent!.part.partNumber)
          if (parentInChangeOrder) {
            const editVariables: EditPartMutationVariables = {
              changeOrderNumber: changeOrder.number,
              partNumber: nodeInfo.parent.part.partNumber,
              version: parentInChangeOrder.version,
              dependencies: parentInChangeOrder.dependencies.map(d => {
                return {
                  partNumber: d.to.partNumber,
                  version: partInChangeOrder.partNumber === d.to.partNumber ? nodeInfo.previousVersion! : d.to.version,
                  section: d.section,
                  quantity: d.quantity,
                  units: d.units,
                  groupId: d.groupId ?? nullControlSeq,
                  referenceDesignator: d.referenceDesignator ?? nullControlSeq
                }
              })
            }
            const { errors } = await editPartMutation({
              variables: editVariables,
              refetchQueries: [{ query: CHANGE_ORDER_QUERY, variables: { orderNumber: changeOrder.number } }],
              awaitRefetchQueries: true
            })
            if (errors) {
              reportMutationError({
                errors,
                variables: editVariables,
                message: `Error bubbling part version for part ${nodeInfo.parent.part.partNumber}`
              })
            }
          }
        }
        const message = <div
          onClick={e => e.stopPropagation()}
          className='-my-2 flex items-center justify-center gap-2 rounded-xl py-2 px-3 text-xs text-gray-800'>
          <Button size='sm' onClick={handleUndo} className='ml-1'>Use Previous Version v{nodeInfo.previousVersion!}</Button>
        </div>
        return {
          hierarchy: nodeInfo.node.hierarchy,
          className: 'bg-green-200',
          message,
        } as SubjectPart
      }

      const handleUpdate = async () => {
        if (!nodeInfo.parent) throw new Error('Parent not found')
        const parentInChangeOrder = includedParts.find(p => p.partNumber === nodeInfo.parent!.part.partNumber)!

        if (parentInChangeOrder) {
          const editVariables: EditPartMutationVariables = {
            changeOrderNumber: changeOrder.number,
            partNumber: nodeInfo.parent.part.partNumber,
            version: parentInChangeOrder.version,
            dependencies: parentInChangeOrder.dependencies.map(d => {
              return {
                partNumber: d.to.partNumber,
                version: partInChangeOrder.partNumber === d.to.partNumber ? partInChangeOrder.version : d.to.version,
                section: d.section,
                quantity: d.quantity,
                units: d.units,
                groupId: d.groupId ?? nullControlSeq,
                referenceDesignator: d.referenceDesignator ?? nullControlSeq,
              }
            })
          }
          const { errors } = await editPartMutation({
            variables: editVariables,
            refetchQueries: [{ query: CHANGE_ORDER_QUERY, variables: { orderNumber: changeOrder.number } }],
            awaitRefetchQueries: true
          })
          if (errors) {
            reportMutationError({
              errors,
              variables: editVariables,
              message: `Error bubbling part version for part ${nodeInfo.parent.part.partNumber}`
            })
          }
          return
        }
        const addDeltaVariables: ChangeOrderNewEditMutationVariables = {
          changeOrderNumber: changeOrder.number,
          partNumber: nodeInfo.parent.part.partNumber,
          version: calculateNextVersion(nodeInfo.parent.part.version),
          dependencies: nodeInfo.parent.part.dependencies.map(d => {
            return {
              partNumber: d.to.partNumber,
              version: partInChangeOrder.partNumber === d.to.partNumber ? partInChangeOrder.version : d.to.version,
              section: d.section,
              quantity: d.quantity,
              units: d.units,
              groupId: d.groupId ?? nullControlSeq,
              referenceDesignator: d.referenceDesignator ?? nullControlSeq,
            }
          })
        }
        const { errors } = await addDeltaMutation({
          variables: addDeltaVariables,
          refetchQueries: [{ query: CHANGE_ORDER_QUERY, variables: { orderNumber: changeOrder.number } }],
          awaitRefetchQueries: true
        })
        if (errors) {
          reportMutationError({
            errors,
            variables: addDeltaVariables,
            message: `Error bubbling part version for part ${nodeInfo.parent.part.partNumber}`
          })
        }
      }
      const message = <div
        onClick={e => e.stopPropagation()}
        className='-my-2 flex items-center justify-center gap-2 bg-yellow-50 rounded-xl px-2 text-xs text-gray-800 border-yellow-200 border'>
        <ExclamationTriangleIcon className='w-4 mt-0.5 text-yellow-600' />
        <div>Newer version v{partInChangeOrder.version} available</div>
        <Button writeOnly size='xs' onClick={handleUpdate} className='ml-1'>Update</Button>
      </div>
      return {
        hierarchy: nodeInfo.node.hierarchy,
        className: 'bg-gray-50',
        message,
      } as SubjectPart
    })

    const expandedNodes = updatedOrUpdateableNodes.flatMap(n => {
      const ancestors = findAncestors(hierarchyWithRoot, n.node.hierarchy)
      return ancestors.map(a => a.hierarchy)
    })

    const versionChange = (currentVersion: string, afterVersion: string | undefined) => {
      if (!afterVersion) return currentVersion
      return `${currentVersion} → ${afterVersion}`
    }
    const rootInChangeOrder = includedParts.find(p => p.partNumber === orphan.partNumber)
    const rootWithUpdates = {
      ...orphan,
      version: versionChange(rootInChangeOrder?.updateTo?.version || orphan.version, rootInChangeOrder?.version),
      hierarchy: orphan.hierarchy.filter(node => {
        return expandedNodes.some(h => h === node.hierarchy) ||
          updatedOrUpdateableNodes.some(n => n.node.hierarchy === node.hierarchy)
      }).map(node => {
        const partInChangeOrder = includedParts.find(p => p.partNumber === node.part.partNumber)!
        const nodeInfo = updatedOrUpdateableNodes.find(n => n.node.hierarchy === node.hierarchy)
        return {
          ...node,
          part: {
            ...node.part,
            version: versionChange(nodeInfo?.previousVersion || node.part.version, nodeInfo?.updated ? partInChangeOrder.version : undefined)
          }
        }
      })
    }

    const expandedWithoutRoot = expandedNodes.filter(h => h !== '1')
    return <PartHierarchy
      key={orphan.partNumber}
      rootPart={rootWithUpdates}
      subjectParts={subjectParts}
      expandedNodes={expandedWithoutRoot} />
  })
  return hierarchies.length > 0 ? <>{hierarchies}</> : <div className='text-center italic text-gray-600 bg-gray-100 p-5 rounded-md'>
    No parts in the change order are referenced by parent assemblies
  </div>
})

export default VersionChangeSummary

type FindAncestorHierarchy = {
  hierarchy: string
}

const findParent: <T extends FindAncestorHierarchy[]>(fullHierarchy: T, childLevel: string) => T[number] | undefined
  = (fullHierarchy, childLevel) => {
    const cLevel = childLevel.split('.')
    if (cLevel.length === 1) return
    const parentHierarchy = cLevel.slice(0, -1).join('.')
    return fullHierarchy.find(h => h.hierarchy === parentHierarchy)
  }
