import { MetaTags } from '@redwoodjs/web'

import ViewModeSwitch from 'src/components/ViewModeSwitch'
import PartHierarchyCell from 'src/components/PartHierarchyCell';

const PartsPage = () => {
  return (
    <>
      <MetaTags title="Parts Hierarchy" description="Parts hierarchy page" />
      <div id="partsTopRow" className='py-6 sticky top-12 bg-white/60 backdrop-blur-lg z-10'>
        <ViewModeSwitch currentMode='hierarchy'/>
      </div>
      <PartHierarchyCell/>
    </>
  )
}

export default PartsPage
