import { useEffect, useState } from 'react'
import { useMutation } from '@redwoodjs/web'
import {
  DependencyDisplayFragment,
  DependencySection,
  Part,
  EditPartMutation,
  EditPartMutationVariables,
} from 'types/graphql'

import { Form, Submit, TextField } from './Form'
import { NumberField, useForm, useWatch } from '@redwoodjs/forms'

import { reportMutationError } from 'src/lib/reportError'
import Button, {
  EditButton
} from 'src/components/Button'
import { TrashIcon, SwatchIcon, ArrowUpLeftIcon, ArrowUpRightIcon, EllipsisVerticalIcon } from '@heroicons/react/24/outline'
import PartVersionSelectCell from 'src/components/PartVersionSelectCell'
import PartSelectCell from 'src/components/PartSelectCell'
import { Link, routes, useParams } from '@redwoodjs/router'

import { registerFragment } from '@redwoodjs/web/apollo'
import { EDIT_PART_MUTATION, QUERY as CHANGE_ORDER_QUERY, ChangeOrderContextInfo } from './ChangeOrderChangesCell'
import type { PartDiffSummary } from 'src/components/ChangeOrderChangesCell/calculateAllChanges'
import * as ListBox from './ListBox'
import { StatusDot } from './LifecycleStatus/LifecycleStatus'
import { Popover } from '@headlessui/react'
import { nullControlSeq } from 'api/src/shared/nullable'
import { letF } from 'api/src/shared/functional'

const MAIN_BRANCH = -1

export const dependencyDisplayFragment = registerFragment(gql`
  fragment DependencyDisplayFragment on Dependency {
    section
    referenceDesignator
    quantity
    units
    groupId
    to {
      name
      partNumber
      version
      partNumber
      lifeCycle
      branch
    }
  }
`)

type DependencyPart = Pick<Part, 'cadRev' | 'version' | 'name' | 'partNumber' | 'lifeCycle' | 'branch'>
type DependencyInput = Pick<DependencyDisplayFragment, 'section' | 'quantity' | 'units' | 'referenceDesignator' | 'groupId'> & {
  to: DependencyPart
}

type IncludedPart = Pick<Part, 'version' | 'name' | 'partNumber' | 'isRoot'>
type PartDependencyChangesProps = {
  edit?: {
    changeOrder: {
      number: number
      includedParts: IncludedPart[]
    }
  }
  changeOrderComplete: boolean
  partDiff: PartDiffSummary
  isExpanded: boolean
  changeOrderContext?: ChangeOrderContextInfo
}

export const displayUnits = (units: string) => {
  const un = units.toLowerCase();
  return (un === 'units' || un === 'each') ? '' : units
}

export const DependencyChanges: React.FC<PartDependencyChangesProps> = ({
  edit,
  changeOrderComplete,
  partDiff,
  isExpanded,
  changeOrderContext,
}) => {

  // Should never be null if the dependencies can be edited
  const headAncestors = partDiff.headPart?.ancestors || []
  const incomingAncestors = partDiff.incomingPart.ancestors || []

  if (edit && !partDiff.incomingPart.ancestors) {
    throw new Error('Need to provide ancestors if editing dependencies')
  }

  const allAncestors = ([...incomingAncestors, ...headAncestors]).map(h => h.part)
  const [editing, setEditing] = useState(false)

  const header = (editButton?: boolean) => {
    return <div>
      <div className='flex items-center gap-2 text-sm font-semibold text-gray-900 h-8 mb-2'>
        <div>Bill of Materials</div>
        {editButton && <EditButton onClick={() => setEditing(true)} size={4} testId='dependencies' />}
      </div>
      <div className='text-sm text-gray-700 mb-4'>
        This list of parts is directly used by this assembly
      </div>
    </div>
  }

  // Part is being created
  if (!partDiff.headPart) {
    return <div>
      {header(edit && !editing && !changeOrderComplete)}
      <Dependencies
        part={partDiff.incomingPart}
        dependencies={partDiff.incomingPart.dependencies}
        edit={edit && {
          mode: 'edit',
          editing,
          setEditing,
          changeOrder: edit.changeOrder,
          ancestors: allAncestors
        }}
        links={{
          changeOrder: changeOrderContext,
        }}
      />
    </div>
  }

  const dependencyChangeInfo = partDiff.fields.dependencies.change
  const headDependencies = partDiff.headPart.dependencies
  const incomingDependencies = partDiff.incomingPart.dependencies

  const changedPartNumbers = dependencyChangeInfo.filter(change => {
    return (change.quantity || change.referenceDesignator || change.version || change.wholeDependency || change.groupChange)
  }).map(d => d.partNumber)
  const showOnly = (isExpanded || editing) ? undefined : changedPartNumbers
  if (!isExpanded && changedPartNumbers.length === 0) return null

  const changes = { headDependencies, dependencyChangeInfo }
  return <div data-testid={`all-dependencies`}>
    <div className='flex'>
      <div className='flex-1'>
        {header()}
        <Dependencies
          showOnly={showOnly}
          part={partDiff.incomingPart}
          dependencies={partDiff.headPart.dependencies}
          incomingDependencies={partDiff.incomingPart.dependencies}
          incomingEditing={editing}
          incomingChanges={changes}
          sideBySide
          links={{
            changeOrder: changeOrderContext,
          }}
        />
      </div>
      <div className='border-l border-gray-200 mx-10'></div>
      <div className='flex-1'>
        {header(edit && !editing && !changeOrderComplete)}
        <Dependencies
          showOnly={showOnly}
          part={partDiff.incomingPart}
          changes={changes}
          dependencies={incomingDependencies}
          edit={edit && !changeOrderComplete ? {
            mode: 'edit',
            editing,
            setEditing,
            changeOrder: edit.changeOrder,
            ancestors: allAncestors
          } : undefined}
          sideBySide
          links={{
            changeOrder: changeOrderContext,
          }}
        />
        </div>
    </div>
  </div>
}

type PartDependenciesProps = {
  part: {
    partNumber: string
    version: string
  }
  edit?: {
    mode: 'edit' | 'lock'
    changeOrder: {
      number: number
      includedParts: IncludedPart[]
    }
    editing: boolean
    setEditing: (editing: boolean) => void
    ancestors: Pick<Part, 'partNumber'>[]
  }
  dependencies: DependencyDisplayFragment[]
  incomingDependencies?: DependencyDisplayFragment[]
  incomingEditing?: boolean
  incomingChanges?: {
    dependencyChangeInfo: PartDiffSummary['fields']['dependencies']['change'],
    headDependencies: DependencyInput[]
  }
  changes?: {
    dependencyChangeInfo: PartDiffSummary['fields']['dependencies']['change'],
    headDependencies: DependencyInput[]
  }
  showOnly?: string[]
  sideBySide?: boolean
  links?: {
    changeOrder?: ChangeOrderContextInfo
  }
}

let CURRENT_ID = 0
const getId = () => CURRENT_ID++

type EditingPartGroup = {
  clientId: number
  name?: string
  partIds: string[]
}

export const Dependencies: React.FC<PartDependenciesProps> = ({
  edit,
  dependencies,
  incomingDependencies,
  incomingEditing,
  incomingChanges,
  changes,
  part,
  sideBySide,
  showOnly,
  links,
}) => {
  const orgId = useParams().orgId!
  const [editPartMutation] = useMutation<EditPartMutation, EditPartMutationVariables>(EDIT_PART_MUTATION)

  const [deletedGroups, setDeletedGroups] = useState<Set<number>>(new Set())

  useEffect(() => {
    if (edit?.editing) {
      setEditingParts(dependenciesToRender.filter(d =>
        !(changes?.dependencyChangeInfo.find(c => c.partNumber === d.to.partNumber)?.wholeDependency === 'removed')
      ))
      setEditingPartGroups(partGroupsToRender)
    } else {
      setEditingParts(null)
      setEditingPartGroups(null)
    }
  }, [edit?.editing])

  type FormData = {
    version: Record<string, string>
    quantity: Record<string, number>
    units: Record<string, string>
    depgroups: Record<string, string>
  }

  const formMethods = useForm<FormData>()
  const formData = useWatch(formMethods)

  const handleFormSubmit = async (formData: FormData) => {
    if (!edit) return

    const updatedDependencies = editingParts!.map((dependency) => {
      const { partNumber } = dependency.to
      const groupIdx = partGroupsToRender.find(g => g.partIds.includes(dependency.to.partNumber))?.clientId
      const groupId = (groupIdx !== undefined) && formData.depgroups?.[groupIdx]
      return {
        partNumber,
        version: formData.version[`#${btoa(partNumber)}`]!,
        section: dependency.section,
        quantity: formData.quantity[`#${btoa(partNumber)}`]!,
        units: formData.units[`#${btoa(partNumber)}`]!,
        referenceDesignator: dependency.referenceDesignator ?? nullControlSeq,
        groupId: groupId || nullControlSeq
      }
    })

    const variables: EditPartMutationVariables = {
      partNumber: part.partNumber,
      version: part.version,
      changeOrderNumber: edit.changeOrder.number,
      dependencies: updatedDependencies
    }
    const { errors } = await editPartMutation({
      variables,
      refetchQueries: [CHANGE_ORDER_QUERY],
      awaitRefetchQueries: true
    })
    if (errors) {
      reportMutationError({
        errors,
        variables,
        message: `Error creating part`
      })
    }
    edit.setEditing(false)
    setDeletedGroups(new Set())
  }

  const onCancel = () => {
    edit!.setEditing(false)
    setDeletedGroups(new Set())
  }

  const [editingParts, setEditingParts] = useState<DependencyInput[] | null>(null)
  const [editingPartGroups, setEditingPartGroups] = useState<EditingPartGroup[] | null>(null)

  const addPartToGroup = (groups: EditingPartGroup[], name: string, part: string | false) => {
    const group = groups.find(g => name === g.name)
    if (!group) {
      groups.push({
        clientId: getId(),
        name,
        partIds: part ? [part] : []
      })
    }
    else if (part) {
      group.partIds.push(part)
    }
  }
  const partGroups = (incomingDependencies ?? dependencies).reduce((groups, d) => {
    if (d.groupId) {
      addPartToGroup(groups, d.groupId, !incomingDependencies && d.to.partNumber)
    }
    return groups;
  }, [] as EditingPartGroup[])

  if (incomingDependencies) {
    dependencies.forEach(d => d.groupId && addPartToGroup(partGroups, d.groupId, d.to.partNumber))
  }

  let dependenciesToRender = dependencies
  let dependencyChanges = changes?.dependencyChangeInfo
  if (dependencyChanges && !editingParts) {
    const { headDependencies } = changes!
    dependenciesToRender = dependencyChanges.map(change => {
      if (change.wholeDependency === 'removed') {
        const deleted = headDependencies.find(head => head.to.partNumber === change.partNumber)

        if (deleted?.groupId) {
          addPartToGroup(partGroups, deleted.groupId, deleted.to.partNumber)
        }

        return deleted;
      }
      else if (change.groupChange?.from) {
        addPartToGroup(partGroups, change.groupChange.from, false)
      }
      return dependencies.find(incoming => incoming.to.partNumber === change.partNumber)
    }) as DependencyInput[]
  }

  const changedGroups = new Set();

  (changes || incomingChanges)?.dependencyChangeInfo.forEach(c => {
    if (c.wholeDependency) {
      const searchGroup = c.wholeDependency === 'added' ?
        (incomingDependencies || dependencies) :
        (changes?.headDependencies || dependencies);
      const dep = searchGroup.find(d => d.to.partNumber === c.partNumber)

      if (dep?.groupId) {
        changedGroups.add(dep.groupId)
      }
    }
  })

  let hasMovements = false;
  const movedParts = (changes || incomingChanges)?.dependencyChangeInfo
    .filter(c => c.groupChange)
    .reduce((movements, c) => {
      hasMovements = true;

      const { from = '__ungrouped__', to = '__ungrouped__' } = c.groupChange!;

      movements[from] = movements[from] || { }
      movements[to] = movements[to] || { }

      movements[from][c.partNumber] = 'leaving'
      movements[to][c.partNumber] = 'arriving'

      changedGroups.add(from)
      changedGroups.add(to)

      return movements
    }, {} as {[groupdId: string]: {
      [partNumber: string]: 'leaving' | 'arriving'
    }});

  if (editingParts) dependenciesToRender = editingParts

  const partGroupsToRender = edit ? (editingPartGroups || partGroups) : partGroups

  const additionalSpaces = incomingDependencies?.reduce((acc, d) => {
    if (incomingEditing) return acc;
    if (!dependenciesToRender.find(o => o.to.partNumber === d.to.partNumber)) {
      const key = d.groupId ?? '__ungrouped__'
      acc[key] = (acc[key] || 0) + 1
    }
    return acc;
  }, {} as {[groupId: string]: number})

  if (showOnly) {
    dependenciesToRender = dependenciesToRender.filter(d =>
      showOnly.includes(d.to.partNumber) || (d.groupId && changedGroups.has(d.groupId)))
  }

  const getSection = (p: {partNumber: string}): DependencySection => {
    const current = changes?.dependencyChangeInfo.find(c => c.partNumber === p.partNumber)
    return current?.originalSection ?? 'Manual'
  }

  const depsNotInGroups = dependenciesToRender.filter(p => {
    return !partGroupsToRender?.some(g => g.partIds.includes(p.to.partNumber))
  })

  const handleDeleteGroup = (groupClientId: number, parts: DependencyDisplayFragment[]) => {
    setEditingParts(editingParts!.filter(dep => !parts.find(p => p.to.partNumber === dep.to.partNumber)))
    deletedGroups.add(groupClientId)
    setDeletedGroups(new Set(deletedGroups))
  }

  const renderDep = (d: DependencyInput) => {
    const change = dependencyChanges?.find(change => change.partNumber === d.to.partNumber)

    const colorClasses =
      !editingParts && change?.wholeDependency === 'added' ? {
        main: 'bg-green-100 border-green-300',
        header: 'bg-green-200',
        quantity: '',
        version: '',
        refdes: '',
        hoverContrast: 'hover:bg-green-300'
      } :
      !editingParts && change?.wholeDependency === 'removed' ? {
        main: 'bg-red-100 border-red-300',
        header: 'bg-red-200',
        quantity: '',
        version: '',
        refdes: '',
        hoverContrast: 'hover:bg-red-300'
      } :
      !editingParts && change?.groupChange ? {
        main: 'bg-yellow-100 border-yellow-300',
        header: 'bg-yellow-200',
        quantity: '',
        version: '',
        refdes: '',
        hoverContrast: 'hover:bg-yellow-300'
      } :
      {
        main: 'border-gray-200',
        header: 'bg-gray-50',
        version: !editingParts && change?.version ? 'bg-yellow-100' : '',
        quantity: change?.quantity ? 'bg-yellow-100' : '',
        refdes: (editingParts || !change?.referenceDesignator) ? '' :
        d.referenceDesignator ? 'bg-yellow-100' : 'bg-red-100',
        hoverContrast: 'hover:bg-gray-200'
      };

    const units = formData.units?.[`#${btoa(d.to.partNumber)}`]
    const integerQuantity = units?.toLowerCase() === 'each' || units?.toLowerCase() === 'units'

    return (
      <div key={d.to.partNumber} className={`${colorClasses.main} grow border rounded-lg text-xs`} data-testid={`dependency-${d.to.partNumber}-${change?.wholeDependency ?? (editingParts ? 'editing' : 'standard')}`}>
        <div className={`text-gray-900 p-4 py-3 rounded-t-lg flex gap-2 font-semibold ${colorClasses.header}`}>
          <StatusDot lifeCycle={d.to.lifeCycle} size='sm' className='self-center'/>
          <div className='relative w-full'>
            <div className={`absolute w-full hover:w-fit hover:z-10 whitespace-nowrap overflow-hidden text-ellipsis hover:overflow-visible ${colorClasses.hoverContrast} hover:bg-gray-200 px-1 -ml-1 !text-xs`}>
              {d.to.name}
            </div>
          </div>
          <div className='relative ml-auto font-medium pl-8 whitespace-nowrap flex gap-2 items-center'>
            {'#' + d.to.partNumber}
            {links &&
              <Popover className="relative">
                <Popover.Button className='relative top-[4px] -my-1'>
                  <EllipsisVerticalIcon className='w-4'/>
                </Popover.Button>
                <Popover.Panel className='absolute *:py-2 *:pl-3 *:pr-3 *:cursor-pointer text-xs z-30 min-w-40 mt-1 max-h-96 rounded-md bg-white py-1
shadow-lg ring-1 ring-black ring-opacity-5
focus:outline-none
 right-0'>
                  {letF(links.changeOrder?.isPartInOrder(d.to.partNumber), partInOrder =>
                    <>
                      {partInOrder &&
                        <a href={`#part-change-${d.to.partNumber}`} className='flex gap-1 hover:bg-brand-500 hover:text-white'>View in change order</a>
                      }
                      {(d.to.branch === MAIN_BRANCH || typeof partInOrder?.proto?.currentPublishId === 'number') &&
                        <>
                          <Link to={routes.part({ orgId, partNumber: d.to.partNumber })} className='flex gap-1 hover:bg-brand-500 hover:text-white'>View part page</Link>
                        </>
                      }
                    </>
                  )}

                </Popover.Panel>
              </Popover>
            }
          </div>
        </div>
        <div className={`p-4 py-3 flex flex-col gap-4 text-xs rounded-b-lg ${''}`}>
          <div className='flex gap-1'>
            <div className='flex gap-1 min-w-28' data-testid='dep-version'>
              <div className='text-gray-900 font-semibold'>Version</div>
              <div className={`px-1 w-full ${colorClasses.version}`}>
                {edit && editingParts ?
                  <PartVersionSelectCell
                    defaultVersion={d.to.version}
                    name={`version.#${btoa(d.to.partNumber)}`}
                    partNumber={d.to.partNumber}
                    orderNumber={edit.changeOrder.number}
                    size='sm'
                    hideRing
                    className='bg-yellow-50'
                    wrapperClassName='block'
                  />
                  :
                  d.to.version
                }
              </div>
            </div>
            <div className='flex gap-1 min-w-32' data-testid='dep-quantity'>
              <div className='text-gray-900 font-semibold'>Quantity</div>
              <div className={`px-1 w-full ${colorClasses.quantity} flex`}>
                {editingParts ?
                  <>
                    <NumberField
                      required
                      defaultValue={d.quantity}
                      style={{fieldSizing: 'content'}}
                      name={`quantity.#${btoa(d.to.partNumber)}`}
                      validation={{
                        required: true,
                          min: 0
                      }}
                      step={integerQuantity ? 1 : 'any'}
                      min={integerQuantity ? 1 : 0.0001}
                      className={`${''} min-w-4 bg-yellow-50 [appearance:textfield] leading-4 [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none`} />
                    <ListBox.HookedListBox
                      required
                      defaultValue={d.units}
                      name={`units.#${btoa(d.to.partNumber)}`}>

                      <ListBox.UncontrolledButton size='sm' hideRing className='leading-4 ml-1 py-0 pl-1 pr-6 min-w-14 [&>*:nth-child(2)]:pr-0 text-xs bg-yellow-50 align-center' wrapperClassName='block' />
                      <ListBox.Options>
                        {['each', 'kg', 'g', 'mg', 'μg', 'lb', 'oz', 'm', 'cm', 'mm', 'μm', 'ft', 'in', 'barleycorn', 'l', 'ml', 'm³', 'cm³', 'in³'].map((o) => (
                          <ListBox.Option
                            key={o}
                            className='py-3 text-right'
                            value={o}
                            display={o} />
                        ))}
                      </ListBox.Options>
                    </ListBox.HookedListBox>
                  </>
                  :
                  <>
                    {d.quantity} {displayUnits(d.units)}
                  </>
                }
              </div>
            </div>
            {(d.referenceDesignator || change?.referenceDesignator) &&
            <div className='flex gap-1 grow' data-testid='dep-refdes'>
              <div className='text-gray-900 font-semibold'>Designator</div>
              <div className={`px-1 w-full ${colorClasses.refdes}`}>{d.referenceDesignator}</div>
            </div>
            }
            {editingParts &&
              <TrashIcon className='ml-auto w-4 text-gray-500 cursor-pointer' onClick={
                () => setEditingParts(editingParts!.filter(dep => dep.to.partNumber !== d.to.partNumber))
              } />
            }
          </div>
        </div>
      </div>
    )
  }

  const makeSpaces = (count: number, small: boolean = false) =>
    Array.from(' '.repeat(count)).map((_, i) => <div key={i} style={{paddingBottom: '82px'}}/>)

  const renderDeparture = (fromPartNumber: string, small: boolean = false) => {
    const toGroup = Object.entries(movedParts!).find(([_, parts]) =>
      parts[fromPartNumber] === 'arriving'
    )![0]

    const height = small ? 'h-[82px]' : 'h-[84px]'

    return <div key={fromPartNumber} className={height + ` grow border rounded-lg bg-gray-200 flex justify-center items-center text-gray-400 select-none text-sm`}>
      <div className='flex flex-row'>
        <div className='absolute'>
          <ArrowUpLeftIcon className='w-3 relative right-4 top-1'/>
        </div>
        {toGroup === '__ungrouped__' ?
          <> Removed from group </> :
          <> Moved to {toGroup} </>
        }
      </div>
    </div>
  }

  const ungroupedDeps = () => {
    if ((editingParts ? editingParts.length === 0 : dependenciesToRender.length === 0) && !hasMovements) {
      return <div className='text-center text-sm italic text-gray-600 bg-gray-100 p-5 rounded-md col-span-2'>
        There are no materials associated with this part
      </div>
    }

    const moved = movedParts?.['__ungrouped__']

    let prefixElements: JSX.Element[] = []
    let postfixElements: JSX.Element[] = []
    if (moved && !incomingEditing && !editingParts) {
      const movedEntries = Object.entries(moved)

      if (incomingChanges) {
        if (!incomingEditing) {
          prefixElements = movedEntries
            .filter(([_, direction]) => direction === 'leaving')
            .map(([partNumber]) =>
              dependencies.find((d) => d.to.partNumber === partNumber)!
            )
            .map(renderDep)
          postfixElements = makeSpaces(
            movedEntries.filter(([_, direction]) => direction === 'arriving').length
          )
        }
      }
      else {
        prefixElements = movedEntries
          .filter(([_, direction]) => direction === 'leaving')
          .map(m => renderDeparture(m[0]))

        postfixElements = movedEntries
          .filter(([_, direction]) => direction === 'arriving')
          .map(([partNumber]) =>
            dependencies.find((d) => d.to.partNumber === partNumber)!
          )
          .map(renderDep)
      }
    }

    const additionalSpace = additionalSpaces?.['__ungrouped__']

    const toRender = (editingParts) ?
      depsNotInGroups :
      depsNotInGroups.filter(d => !moved?.[d.to.partNumber])

    const tail = additionalSpace ?
      toRender.map(renderDep)
        .concat(makeSpaces(additionalSpace)) :
      toRender.map(renderDep)

    return prefixElements.concat(tail).concat(postfixElements)
  }

  const groupedDeps = () => partGroupsToRender?.map(group => {
    const parts = dependenciesToRender.filter(d => {
      return group.partIds.includes(d.to.partNumber)
    })

    const moved = movedParts?.[group.name!]

    let prefixElements: JSX.Element[] = []
    let postfixElements: JSX.Element[] = []
    if (moved && !incomingEditing && !editingParts) {
      const movedEntries = Object.entries(moved)
      if (incomingChanges) {
        if (!incomingEditing) {
          prefixElements = movedEntries
            .filter(([_, direction]) => direction === 'leaving')
            .map(([partNumber]) =>
              dependencies.find((d) => d.to.partNumber === partNumber)!
            )
            .map(renderDep)
          postfixElements = makeSpaces(
            movedEntries.filter(([_, direction]) => direction === 'arriving').length
          )
        }
      }
      else {
        prefixElements = movedEntries
          .filter(([_, direction]) => direction === 'leaving')
          .map(m => renderDeparture(m[0], true))
        postfixElements = movedEntries
          .filter(([_, direction]) => direction === 'arriving')
          .map(([partNumber]) =>
            dependencies.find((d) => d.to.partNumber === partNumber)!
          )
          .map(renderDep)
      }
    }

    const additionalSpace = additionalSpaces?.[group.name!]

    const toRender = (editingParts) ?
      parts :
      parts.filter(d => !moved?.[d.to.partNumber])

    const partEntries = prefixElements.concat(
      additionalSpace ?
        toRender.map(renderDep)
          .concat(makeSpaces(additionalSpace)) :
        toRender.map(renderDep)
    ).concat(postfixElements)

    if (parts.length === 0 && ((showOnly && !additionalSpace && !prefixElements.length && !postfixElements.length) || incomingEditing)) return;

    //this is rather transient and is merely for appearance, this could be improved
    if (edit && editingPartGroups && deletedGroups.has(group.clientId)) return;

    return <div key={group.clientId} className='rounded-md text-xs border-gray-200 border'>
      <div className='flex gap-4 bg-gray-50 p-4 py-3 rounded-t-lg items-center'>
        <SwatchIcon className='h-4 w-4' />
        {(edit && editingPartGroups) ?
          <>
            <TextField
              required
              placeholder='Swappable Part Name'
              defaultValue={group.name}
              name={`depgroups.${group.clientId}`}
              inputClassName='text-xs py-2 px-3'
              className={`w-52`} />
            <button className='ml-auto cursor-pointer' data-testid={`dependency-${part.partNumber}-delete-group-${group.name}`} onClick={
              () => handleDeleteGroup(group.clientId, parts)
            }>
              <TrashIcon className='w-4 text-gray-500' />
            </button>
          </> :
          <div>{group.name}</div>
        }
      </div>
      <div className='p-4'>
        <div className='flex gap-2 flex-col'>
          {partEntries.length > 0 ?
            partEntries :
            <div>No parts in group</div>
          }
        </div>
        {edit && editingPartGroups && <div className='w-52 mt-2 text-xs'><PartSelectCell
          onSelect={p => {
            const currentGroup = editingPartGroups?.find(g => g.partIds.includes(p.partNumber))

            setEditingPartGroups((editingPartGroups || []).map(g => {
              if (g.clientId === currentGroup?.clientId) {
                if (group.clientId === currentGroup.clientId) {
                  return g;
                }
                else {
                  return {
                    ...g,
                    partIds: g.partIds.filter(pid => pid !== p.partNumber)
                  }
                }
              }
              if (g.clientId !== group.clientId) return g
              return {
                ...g,
                partIds: [...g.partIds, p.partNumber]
              }
            }))
            if (!editingParts?.find(e => e.to.partNumber === p.partNumber)) {
              setEditingParts([...editingParts!, {
                quantity: 1,
                units: 'each',
                section: getSection(p),
                to: {
                  partNumber: p.partNumber,
                  name: p.name,
                  version: p.version
                }
              }])
            }
          }}
          className='text-xs'
          omitTopLevel
          extraParts={edit.changeOrder.includedParts}
          omit={[...toRender.map(p => p.to.partNumber), ...edit.ancestors.map(p => p.partNumber), part.partNumber]}
          placeholder='Add part to group' /></div>
        }
      </div>
    </div>
  })

  return <Form className='basis-1/2 grow-1' onSubmit={handleFormSubmit} formMethods={formMethods}>
    {
      editingParts ? <div className='flex justify-between gap-2 mt-2 mb-4'>
        {
          edit ? <div className='w-72 text-xs'>
            <PartSelectCell
              onSelect={p => {
                let baseArray: DependencyInput[];
                const currentGroup = editingPartGroups?.find(g => g.partIds.includes(p.partNumber))
                if (currentGroup) {
                  setEditingPartGroups(editingPartGroups!.map(g => {
                    if (g.clientId !== currentGroup.clientId) return g
                    return {
                      ...g,
                      partIds: g.partIds.filter(pid => pid !== p.partNumber)
                    }
                  }))
                  baseArray = editingParts.filter(ep => ep.to.partNumber !== p.partNumber)
                }
                else {
                  baseArray = editingParts
                }

                setEditingParts([...baseArray, {
                  quantity: 1,
                  units: 'each',
                  section: getSection(p),
                  to: {
                    partNumber: p.partNumber,
                    name: p.name,
                    version: p.version
                  }
                }])
              }}
              omitTopLevel
              extraParts={edit.changeOrder.includedParts}
              omit={[...depsNotInGroups.map(d => d.to.partNumber), ...edit.ancestors.map(p => p.partNumber), part.partNumber]}
              placeholder='Add part to materials' />
          </div> : null
        }
        <Button variant={'primary'} onClick={() => setEditingPartGroups([...(editingPartGroups || []), { clientId: getId(), partIds: [] }])}>Add Swappable Part Group</Button>
      </div> : null
    }
    <div className={`grid ${sideBySide ? 'grid-cols-1 gap-3 -mx-1' : 'grid-cols-2 gap-x-5 gap-y-3 -mx-1'}`}>
      {groupedDeps()}
      {ungroupedDeps()}
    </div>
    {
      editingParts ? <div className='flex justify-end gap-2 mt-4 mr-2'>
        <div className='flex-1'>
          <Button onClick={onCancel}>Cancel</Button>
        </div>
        <Submit variant='primary'>Save</Submit>
      </div> : null
    }
  </Form>
}
