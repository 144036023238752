import { useState } from 'react';
import type {
  OrgSettingsCategoriesQuery,
  Maybe,
  CreatePartNumberSchemaMutation,
  CreatePartNumberSchemaMutationVariables,
  DeletePartNumberSchemaMutation,
  DeletePartNumberSchemaMutationVariables,
  CreateCategoryMutation,
  CreateCategoryMutationVariables,
  EditCategoryMutation,
  EditCategoryMutationVariables,
  DeleteCategoryMutation,
  DeleteCategoryMutationVariables,
} from 'types/graphql'
import type { PartNumberSchema as PrismaSchema } from '@prisma/client'
import { reportMutationError } from 'src/lib/reportError'

import { CellSuccessProps, CellFailureProps, useMutation } from '@redwoodjs/web'
import * as Form from 'src/components/Form'

import { LoadingSpinnerWithDelay as LoadingSpinner } from 'src/components/Loading'
import { letF } from 'src/lib/functional'
import * as ListBox from 'src/components/ListBox';
import Button from 'src/components/Button';
import Switch from 'src/components/Switch'
import {
  XMarkIcon,
  PlusIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  PencilSquareIcon,
  TrashIcon,
} from '@heroicons/react/20/solid';
import classNames from 'classnames';
import {
  BasicBlockConfig as BlockConfig,
  BlockType,
  SequenceConfig,
  getInvalidationReason,
  getBlocks,
  validTemplate,
  BasicBlockConfig
} from 'api/src/shared/partNumbers'
import { exhaustiveCheck } from 'src/lib/util'

export const QUERY = gql`
  query OrgSettingsCategoriesQuery {
    currentOrg {
      id
      name
      me {
        role
      }
    }
    partNumberSchemas {
      key
      template
      templateConfig
      isUsed
      categories {
        id
        name
        label
        isUsed
      }
    }
  }
`

export const Loading = () => <div className='flex justify-center p-2'><LoadingSpinner /></div>

export const Empty = () => <div>Empty</div>

import GenericFailure from '../Failure/Failure'
export const Failure = GenericFailure

type SuccessProps = CellSuccessProps<OrgSettingsCategoriesQuery>

type PartNumberSchema = {
  templateConfig: {
    [key: string]: BlockConfig
  },
  categories?: UICategory[]
  isUsed?: boolean
} & Pick<PrismaSchema, 'key' | 'template'>

type UICategory = {
  name: string
  id: string
  isUsed: boolean
}

//this is quite hacky but it works ok, will have problems if multiple views need to be rendered simultaneously
let newCategoryDefaultOpen = false;

export const Success = (props: SuccessProps) => {
  const [refreshKey, setRefreshKey] = useState(0)
  if (refreshKey === 0) {
    newCategoryDefaultOpen = false;
  }
  const [currentSchemaKey, setCurrentSchemaKey] = useState<string | undefined>(undefined)
  const onRefresh = (schemaKey?: string) => {
    setRefreshKey(refreshKey + 1)
    setCurrentSchemaKey(schemaKey)
  }

  return <Main key={refreshKey.toString()} {...props} onRefresh={onRefresh} schemaKey={currentSchemaKey} />
}

const Main = ({
  currentOrg,
  partNumberSchemas,
  onRefresh: passedRefresh,
  schemaKey,
}: SuccessProps & { onRefresh: (schemaKey?: string) => void, schemaKey?: string }) => {
  const [schemas, setSchemas] = useState<PartNumberSchema[]>(
    partNumberSchemas
  )

  const [selectedSchema, setSelectedSchema] = useState<Maybe<PartNumberSchema | 'new'>>(
    schemaKey ?
      schemas.find(s => s.key === schemaKey)! :
      (schemas[0] ?? 'new')
  )

  const handleSchemaSelection = (selection: Maybe<PartNumberSchema | 'new'>) => {
    newCategoryDefaultOpen = false;
    setSelectedSchema(selection)
  }

  const onRefresh = (newKey?: string) => {
    passedRefresh(newKey ?? (selectedSchema !== 'new' ? selectedSchema?.key : undefined))
  }

  //TODO: probably onRefresh() here
  const onSave = (schema: PartNumberSchema) => {
    const existingIdx = schemas.findIndex(s => s.key === schema.key)
    const newSchemas = [...schemas];
    if (existingIdx >= 0) {
      newSchemas[existingIdx] = schema;
    }
    else {
      newSchemas.push(schema)
    }
    //TODO: networking (mutation)
    setSchemas(newSchemas)
    handleSchemaSelection(schema)
  }

  const baseTabClassName = 'py-2 px-3 cursor-pointer border-x border-t inline-block -mb-0.5'
  const activeTabClassName = classNames(
    baseTabClassName,
    'rounded-t border-gray-200 bg-white'
  )
  const tabClassName = classNames(
    baseTabClassName,
    'border-transparent'
  )

  return (
    <div className='pb-12 flex flex-col gap-5 mb-12'>
      <div className='bold text-2xl'>
        Part Categories and Schemas
      </div>
      <div className='text-sm -mt-1'>Part Number Schemas allow you to define the shape part number generators will conform to and define how part numbers indicate their categories.</div>

      <div className='flex text-sm relative border-b border-gray-200'>
        {schemas.map(s =>
          <button key={s.key} className={selectedSchema === s ? activeTabClassName : tabClassName} onClick={() => handleSchemaSelection(s)}>
            {s.key}
          </button>
        )}
        {<button className={selectedSchema === 'new' ? activeTabClassName : tabClassName} onClick={() => handleSchemaSelection('new')}>
          <PlusIcon className='w-4' />
        </button>}
      </div>

      <div className='text-sm'>
        {selectedSchema === 'new' ?
          <SchemaView key='______new_____' onRefresh={onRefresh} /> :
          (selectedSchema &&
            <SchemaView key={selectedSchema.key} schema={selectedSchema} onRefresh={onRefresh} />)}
      </div>
    </div>
  )
}

export const CREATE_PART_NUMBER_SCHEMA_MUTATION = gql`
mutation CreatePartNumberSchemaMutation ($input: PartNumberSchemaInput!) {
  createPartNumberSchema(input: $input) { key }
}
`

export const DELETE_PART_NUMBER_SCHEMA_MUTATION = gql`
mutation DeletePartNumberSchemaMutation ($input: DeletePartNumberSchemaInput!) {
  deletePartNumberSchema(input: $input)
}
`

type SchemaViewProps = {
  onRefresh: (newKey?: string) => void
  editable?: boolean,
  schema?: PartNumberSchema,
}


const SchemaView = (props: SchemaViewProps) => {
  const [createSchema, { loading: createLoading, error: createError }] = useMutation<CreatePartNumberSchemaMutation, CreatePartNumberSchemaMutationVariables>(CREATE_PART_NUMBER_SCHEMA_MUTATION)
  const [deleteSchema, { loading: deleteLoading, error: deleteError }] = useMutation<DeletePartNumberSchemaMutation, DeletePartNumberSchemaMutationVariables>(DELETE_PART_NUMBER_SCHEMA_MUTATION)

  const loading = createLoading || deleteLoading
  const error = createError || deleteError

  const { schema: passedSchema, editable, onRefresh } = props;
  const schema: PartNumberSchema = passedSchema ?? {
    key: '',
    template: '',
    templateConfig: {}
  }

  const categories = schema.categories && [...schema.categories].sort((a, b) => a.id < b.id ? -1 : 1)

  const [showValidation, setShowValidation] = useState(false)

  const [open, setOpen] = useState(!passedSchema)
  const [key, setKey] = useState<string>(schema.key)
  const [currentTemplate, setCurrentTemplate] = useState<string>(schema.template)
  const [blocks, setBlocks] = useState(getBlocks(currentTemplate))
  const [potentialBlockConfigs, setPotentialBlockConfigs] = useState<PartNumberSchema['templateConfig']>({ ...schema.templateConfig })
  const [keyBlocks, setKeyBlocks] = useState<string[]>(
    (Object.values(schema.templateConfig).findLast(cfg => cfg.type === 'sequence') as Maybe<SequenceConfig>)?.keyBlocks ?? []
  )

  const [creatingCategory, setCreatingCategory] = useState(newCategoryDefaultOpen)
  const onCategoryEditingComplete = (updated: boolean) => {
    if (updated) {
      newCategoryDefaultOpen = true;
      onRefresh()
    }
    else {
      newCategoryDefaultOpen = false;
      setCreatingCategory(false)
    }
  }

  const setTemplate = (t: string) => {
    const blocks = getBlocks(t)
    setCurrentTemplate(t);

    if (validTemplate(t, blocks)) {
      setBlocks(blocks);
      for (const b of blocks) {
        if (!potentialBlockConfigs[b]) {
          potentialBlockConfigs[b] = {
            type: 'text',
            length: 2,
          } as BasicBlockConfig
        }
      }
      setPotentialBlockConfigs(potentialBlockConfigs)
    }
  }

  const setType = (block: string, type: BlockType) => {
    if (potentialBlockConfigs[block]?.type === type) {
      return;
    }

    const baseObject: BlockConfig = type === 'sequence' ? {
      type,
      length: 4,
      keyBlocks: []
    } : (type === 'categoryNumber' || type === 'categoryId') ? {
      type,
      length: 3,
    } : type === 'text' ? {
      type,
      length: 2,
    } : type === 'any' ? {
      type,
      minLength: 4,
      maxLength: 12
    } : exhaustiveCheck(type)

    const newCfgs = {
      ...potentialBlockConfigs,
      [block]: baseObject
    }

    setPotentialBlockConfigs(newCfgs)
  }

  const updateCfgValue = (value: string | boolean, block: string, field: string, cfg: BlockConfig) => {
    const typedVal = typeof value === 'boolean' ? value : parseInt(value)
    const newCfg = {
      ...cfg,
      [field]: typedVal
    }
    setPotentialBlockConfigs({
      ...potentialBlockConfigs,
      [block]: newCfg
    })
  }

  const toggleKeyBlock = (keyBlock: string) =>
    setKeyBlocks(
      keyBlocks.includes(keyBlock) ?
        keyBlocks.filter(kb => kb !== keyBlock) :
        [keyBlock, ...keyBlocks])

  const tempDisabled = Boolean(passedSchema)

  const getKeyBlocks = (idx: number) =>
    blocks.slice(0, idx).filter(p => keyBlocks.includes(p))

  const collectedState: PartNumberSchema = {
    key,
    template: currentTemplate,
    templateConfig: blocks.reduce((config, block, idx) => {
      const blockConfig = potentialBlockConfigs[block]
      if (blockConfig) {
        const copy = { ...blockConfig }
        if (copy.type === 'sequence') {
          copy.keyBlocks = getKeyBlocks(idx)
        }
        config[block] = copy;
      }
      return config
    }, {} as PartNumberSchema['templateConfig'])
  }

  const invalidationReason = getInvalidationReason(collectedState)

  const handleSubmit = async (s: PartNumberSchema) => {
    if (invalidationReason) {
      setShowValidation(true)
    }
    else {
      const variables = {
        input: {
          key: s.key,
          template: s.template,
          templateConfig: s.templateConfig
        }
      }
      const { errors } = await createSchema({
        variables,
        awaitRefetchQueries: true,
        refetchQueries: [{ query: QUERY, variables: {} }],
      })

      if (errors) {
        reportMutationError({
          errors,
          variables,
          message: `Error creating schema`
        })
      }

      onRefresh(s.key)
    }
  }

  const handleDelete = async (s: PartNumberSchema) => {
    const variables = {
      input: { key: s.key }
    }

    const { errors } = await deleteSchema({
      variables,
      awaitRefetchQueries: true,
      refetchQueries: [{ query: QUERY, variables: {} }],
    })

    if (errors) {
      reportMutationError({
        errors,
        variables,
        message: `Error deleting schema`
      })
    }

    onRefresh()
  }

  const TypeBox = ({ block, cfg }: { block: string, cfg: BlockConfig }) => {
    const displayValue = (type: BlockType) =>
      type === 'categoryNumber' ? 'Category Number' :
        type === 'categoryId' ? 'Category ID' :
          type === 'sequence' ? 'Sequence' :
            type === 'text' ? 'Text' :
              type === 'any' ? 'Any' :
                exhaustiveCheck(type)
    return (
      <ListBox.ListBox name={`${block}.type`} onChange={e => setType(block, e as BlockType)} disabled={tempDisabled}>
        <div className="relative ml-auto">
          <ListBox.Button disabled={tempDisabled} displayValue={displayValue(cfg.type)} className='w-full' />
          <ListBox.Options>
            {(['categoryNumber', 'categoryId', 'sequence', 'text', 'any'] as const).map((p) => (
              <ListBox.Option key={p} className='py-3' value={p} display={displayValue(p)} selected={p === cfg.type} />
            ))}
          </ListBox.Options>
        </div>
      </ListBox.ListBox>
    )
  }

  return (
    <div className='flex flex-col gap-3'>
      <div className={`border rounded-md flex flex-col p-3`}>
        <div className={`flex p-2 text-lg`}>
          {passedSchema ?
            <>
              Schema Configuration
            </> :
            'Create new Schema'
          }
          {passedSchema && !passedSchema.isUsed &&
            <button onClick={() => handleDelete(passedSchema)} className='ml-auto'>
              <TrashIcon className='w-4 text-red-600' />
            </button>
          }
        </div>
        <div className='p-2'>
          <div>
            <div>
              <div className='mb-2 font-medium'>Schema Key{' '}</div>
              <Form.TextField disabled={Boolean(passedSchema)} controlled onChange={(e) => setKey(e.target.value)} placeholder='key' defaultValue={passedSchema?.key} />
            </div>
          </div>
          <div className='border-t my-6' />
          <div className='flex gap-4 my-2'>
            <div className='basis-3/4'>
              <label className='font-medium block mb-2'>Template</label>
              <Form.TextField controlled disabled={tempDisabled}
                placeholder='try "<category>-<sequence>"'
                value={currentTemplate}
                onChange={e => setTemplate(e.target.value)} />
            </div>
            <SchemaPreview template={currentTemplate} blocks={blocks} blockCfgs={potentialBlockConfigs} />
          </div>
        </div>
        {passedSchema &&
          <button onClick={() => setOpen(!open)} className='flex gap-1 items-center py-2'>
            {open ? <ChevronDownIcon className='w-4' /> : <ChevronRightIcon className='w-4' />}
            <span className='underline'>
              Blocks
            </span>
          </button>
        }
        {open &&
          <div className='flex flex-col p-2'>
            <div className='flex flex-col'>
              {blocks.map((b, blockIdx) =>
                <div key={b} className='border-t p-2 -mx-2'>
                  <div className='font-medium mt-4 mb-6'>
                    Block Configuration: <span className='font-bold'>{b}</span>
                  </div>
                  {letF(potentialBlockConfigs[b]!, (cfg) =>
                    (cfg.type === 'categoryNumber' || cfg.type === 'sequence' || cfg.type === 'categoryId') ?
                      <div key={cfg.type} className='flex flex-col gap-2'>
                        <div>
                          <div className='flex gap-1 items-center'>
                            <Form.Label name={`${b}.type`} className='min-w-48'>
                              Type
                            </Form.Label>
                            <TypeBox block={b} cfg={cfg} />
                          </div>
                          <div className='max-w-96 min-h-10 font-light leading-5 text-gray-600 text-xs'>
                            {(cfg.type === 'categoryNumber' || cfg.type === 'categoryId') ?
                              `The category number block specifies which part of the part number identifies the category ${cfg.type === 'categoryNumber' ? 'number' : 'ID'
                              }` :
                              cfg.type === 'sequence' ?
                                'The sequence block defines where and how to place the sequence when generating a part number' :
                                exhaustiveCheck(cfg)
                            }
                          </div>
                        </div>
                        <div>
                          <div className='flex gap-1 items-center'>
                            <Form.Label name={`${b}.length`} className='min-w-48'>
                              Length
                            </Form.Label>
                            <Form.NumberField controlled disabled={tempDisabled} name={`${b}.length`} value={cfg.length} min={1} max={40} className='ml-auto'
                              onChange={e => updateCfgValue(e.target.value, b, 'length', cfg)} />
                          </div>
                          <div className='max-w-96 min-h-10 font-light leading-5 text-gray-600 text-xs'>
                            {cfg.type === 'sequence' ?
                              'Specify the length of the sequence as it is generated. It will be padded with "0"s if the number is shorter than the length.' :
                              'Specify the length of the key that is used to identify what category a part belongs to.'
                            }
                          </div>
                        </div>
                        {cfg.type === 'sequence' &&
                          <div>
                            <div className='flex gap-1 items-center'>
                              <Form.Label className='min-w-48'>
                                Sequence Contributors
                              </Form.Label>
                              <div className='ml-auto'>
                                {blocks.slice(0, blockIdx).map(priorBlock =>
                                  <div key={priorBlock} className='flex flex-col gap-1 items-end'>
                                    {priorBlock}
                                    <Switch
                                      disabled={tempDisabled}
                                      accessibleDescription={`Is this a sequence contributor for the ${b} block?`}
                                      enabled={keyBlocks.includes(priorBlock)}
                                      onChange={_ => toggleKeyBlock(priorBlock)} />
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className='max-w-96 min-h-10 font-light leading-5 text-gray-600 text-xs'>
                              Specifies which blocks are used to identify the sequence that should be used to generate a part number.
                            </div>
                          </div>
                        }
                      </div>
                      : cfg.type === 'text' ?
                        <div key='text' className='flex flex-col gap-2'>
                          <div>
                            <div className='flex gap-1 items-center'>
                              <Form.Label name={`${b}.type`} className='min-w-48'>
                                Type
                              </Form.Label>
                              <TypeBox block={b} cfg={cfg} />
                            </div>
                            <div className='max-w-96 min-h-10 font-light leading-5 text-gray-600 text-xs'>
                              Text blocks allow arbitrary variations to occur within part numbers, without effecting their categorization.
                            </div>
                          </div>
                          <div>
                            <div className='flex gap-1 items-center'>
                              <Form.Label className='min-w-48'>
                                Length
                              </Form.Label>
                              <Form.NumberField controlled disabled={tempDisabled} value={cfg.length} min={0} max={40} className='ml-auto'
                                onChange={e => updateCfgValue(e.target.value, b, 'length', cfg)} />
                            </div>
                            <div className='max-w-96 min-h-10 font-light leading-5 text-gray-600 text-xs'>
                              Specifies the length of elements which will be matched to this block
                            </div>
                          </div>
                        </div>
                        : cfg.type === 'any' ?
                          <div key='text' className='flex flex-col gap-2'>
                            <div>
                              <div className='flex gap-1 items-center'>
                                <Form.Label name={`${b}.type`} className='min-w-48'>
                                  Type
                                </Form.Label>
                                <TypeBox block={b} cfg={cfg} />
                              </div>
                              <div className='max-w-96 min-h-10 font-light leading-5 text-gray-600 text-xs'>
                                Any blocks allow any text to be entered as a part number. Only advisable when Bomello is not being used for part number generation.
                              </div>
                            </div>
                            <div>
                              <div className='flex gap-1 items-center'>
                                <Form.Label className='min-w-48'>
                                  Min Length
                                </Form.Label>
                                <Form.NumberField controlled disabled={tempDisabled} value={cfg.minLength} min={1} max={100} className='ml-auto'
                                  onChange={e => updateCfgValue(e.target.value, b, 'minLength', cfg)} />
                              </div>
                              <div className='max-w-96 min-h-10 font-light leading-5 text-gray-600 text-xs'>
                                Specifies the min length of the part number.
                              </div>
                            </div>
                            <div>
                              <div className='flex gap-1 items-center'>
                                <Form.Label className='min-w-48'>
                                  Max Length
                                </Form.Label>
                                <Form.NumberField controlled disabled={tempDisabled} value={cfg.maxLength} min={1} max={100} className='ml-auto'
                                  onChange={e => updateCfgValue(e.target.value, b, 'maxLength', cfg)} />
                              </div>
                              <div className='max-w-96 min-h-10 font-light leading-5 text-gray-600 text-xs'>
                                Specifies the max length of the part number.
                              </div>
                            </div>
                          </div>
                          : exhaustiveCheck(cfg)
                  )}
                </div>
              )}
            </div>

            {showValidation && invalidationReason &&
              <div className='bg-red-200 p-2 mb-2 flex'>
                {invalidationReason}
                <button className='ml-auto' onClick={() => setShowValidation(false)}><XMarkIcon className='w-4' /></button>
              </div>
            }

            {!tempDisabled &&
              <Button type='button' variant='primary' aria-disabled={Boolean(invalidationReason)} disabled={loading} className='ml-auto' onClick={() => handleSubmit(collectedState)}>
                Save Schema
              </Button>
            }
          </div>
        }
        <Form.FormError error={error} wrapperClassName="text-white bg-red-500 w-full p-2 my-2" />
      </div>

      {passedSchema &&
        <div className='flex flex-col border p-5 rounded-md'>
          <div className='text-lg mb-4'>Schema Categories</div>
          <div className='flex mb-3 font-extra-light text-gray-500 text-xs'>
            <div className='basis-1/2'>Category ID</div>
            <div className='basis-1/2'>Category Name</div>
          </div>
          {categories?.map(c =>
            <CategoryView key={c.id} schemaKey={passedSchema.key} category={c} onDelete={onCategoryEditingComplete} />
          )}
          {creatingCategory ?
            <CategoryView schemaKey={passedSchema.key} onComplete={onCategoryEditingComplete} /> :
            <Button onClick={() => setCreatingCategory(true)} variant='primary' className='mt-1 mr-auto'>New Category</Button>
          }
        </div>
      }
    </div>
  )
}

type SchemaPreviewProps = {
  template: string
  blocks: string[]
  blockCfgs: { [key: string]: BlockConfig }
}
const SchemaPreview = ({ template, blocks, blockCfgs }: SchemaPreviewProps) => {
  const exampleStrings = ['1', '2', '3', '4', '5'].map(seq => {
    let interpolated = template;
    let firstSequence = true;
    for (const b of blocks) {
      const config = blockCfgs[b]!;
      const newVal =
        config.type === 'categoryNumber' ? '1234567890'.substring(0, config.length)
          : config.type === 'categoryId' ? 'abcdefghijklmnop'.substring(0, config.length)
            : config.type === 'sequence' ? (firstSequence ? seq : '1').padStart(config.length, '0')
              : config.type === 'text' ? 'abcdefghijklmnop'.substring(0, config.length)
              : config.type === 'any' ? 'anything-at-all'.substring(0, config.maxLength) :
                exhaustiveCheck(config)
      interpolated = interpolated.replace(`<${b}>`, newVal)

      if (config.type === 'sequence') firstSequence = false;
    }
    return interpolated
  })

  return (
    <div className='basis-1/4 text-center'>
      <div className='font-medium mb-2'>Example part numbers</div>
      <div>
        {exampleStrings.map((ex, idx) =>
          <div key={idx} className='italic font-mono mb-0.5'>
            #{ex}
          </div>
        )}
      </div>
    </div>
  )
}

export const CREATE_CATEGORY_MUTATION = gql`
mutation CreateCategoryMutation ($input: CreateCategoryInput!) {
  createCategory(input: $input) { id }
}
`

export const EDIT_CATEGORY_MUTATION = gql`
mutation EditCategoryMutation ($input: EditCategoryInput!) {
  editCategory(input: $input) {
    id
    name
  }
}
`

export const DELETE_CATEGORY_MUTATION = gql`
mutation DeleteCategoryMutation ($input: DeleteCategoryInput!) {
  deleteCategory(input: $input)
}
`

type CategoryViewProps = {
  schemaKey: string,
  category: UICategory
  onComplete?: undefined
  onDelete: (updated: boolean) => void
} | {
  schemaKey: string,
  category?: undefined
  //TODO: remove argument and do as mutation inside component, only manage parent rendering state
  onComplete: (updated: boolean) => void
  onDelete?: undefined
}
const CategoryView = ({ schemaKey, category, onComplete, onDelete }: CategoryViewProps) => {
  const [createCategory, { loading: createLoading, error: createError }] = useMutation<CreateCategoryMutation, CreateCategoryMutationVariables>(CREATE_CATEGORY_MUTATION)
  const [editCategory, { loading: editLoading, error: editError }] = useMutation<EditCategoryMutation, EditCategoryMutationVariables>(EDIT_CATEGORY_MUTATION)
  const [deleteCategory, { loading: deleteLoading, error: deleteError }] = useMutation<DeleteCategoryMutation, DeleteCategoryMutationVariables>(DELETE_CATEGORY_MUTATION)
  const loading = createLoading || editLoading || deleteLoading
  const error = createError || editError || deleteError

  const [editing, setEditing] = useState<boolean>(!category ? true : false)

  const [name, setName] = useState<string>(category?.name ?? '')
  const [categoryId, setCategoryId] = useState<string>(category?.id ?? '')

  const collectedState: UICategory = {
    name,
    id: categoryId,
  }


  const cancel = () => {
    if (onComplete) {
      onComplete(false)
    }
    else {
      setEditing(false)
      //reinitialize state, kinda bad
      setName(category.name)
      setCategoryId(category.id)
    }
  }

  const save = async () => {
    if (onComplete) {
      const variables = {
        input: {
          schemaKey,
          name: collectedState.name,
          id: collectedState.id,
        }
      }
      const { errors } = await createCategory({
        variables,
        awaitRefetchQueries: true,
        refetchQueries: [{ query: QUERY, variables: {} }],
      })

      if (errors) {
        reportMutationError({
          errors,
          variables,
          message: `Error creating category`
        })
      }

      onComplete(true)
    }
    else {
      const variables = {
        input: {
          oldId: category.id,
          newId: collectedState.id,
          name,
        }
      }
      const { data, errors } = await editCategory({
        variables
      })

      if (errors) {
        reportMutationError({
          errors,
          variables,
          message: `Error editing category`
        })
      }

      if (data?.editCategory) {
        const result = data.editCategory
        setCategoryId(result.id)
        setName(result.name)
      }

      setEditing(false)
    }
  }

  const handleDelete = async () => {
    const variables = {
      input: {
        id: collectedState.id,
      }
    }
    const { errors } = await deleteCategory({
      variables,
      awaitRefetchQueries: true,
      refetchQueries: [{ query: QUERY, variables: {} }],
    })

    if (errors) {
      reportMutationError({
        errors,
        variables,
        message: `Error deleting category`
      })
    }

    onDelete?.(true)
  }

  return (
    <div className='flex flex-col border-t rounded'>
      {editing ?
        <div className='py-3'>
          <div className='flex'>
            <div className='py-1'>
              <div className='flex gap-1 items-center'>
                <div>
                  <Form.TextField controlled
                    placeholder='Category ID'
                    autoFocus
                    value={categoryId}
                    disabled={Boolean(category)}
                    onChange={e => setCategoryId(e.target.value)} />
                </div>
              </div>
              <div className='mt-1 font-light leading-5 text-gray-600 text-xs'>
                The designation for the category that is used in part numbers
              </div>
            </div>
            <div className='ml-auto basis-1/2'>
              <div>
                <div className='flex gap-1 items-center'>
                  <Form.TextField controlled
                    className=''
                    placeholder='Category Name'
                    value={name}
                    onChange={e => setName(e.target.value)} />
                </div>
                <div className='max-w-96 mt-1 font-light leading-5 text-gray-600 text-xs'>
                  The name that appears when selecting or displaying the category for a part.
                </div>
              </div>
            </div>
          </div>
          <Form.FormError error={error} wrapperClassName="text-white bg-red-500 w-full p-2 my-2" />
          {(!category || editing) &&
            <div className='flex mt-3'>
              <Button onClick={cancel} size='sm'>Cancel</Button>
              <div className='ml-auto flex gap-2'>
                {category && !category.isUsed &&
                  <Button variant='red' size='sm' disabled={loading} onClick={handleDelete}>
                    Delete Unused Category
                  </Button>
                }
                <Button variant='primary' size='sm' disabled={loading} onClick={save}>
                  {category ? 'Edit' : 'Create'} Category
                </Button>
              </div>
            </div>
          }
        </div> :
        <>
          <div className='flex text-base py-3 group'>
            <div>
              {categoryId}             </div>
            <div className='ml-auto font-semibold basis-1/2 flex'>
              {name}
              <button className='opacity-0 group-hover:opacity-100 focus:opacity-100 ml-auto'
                onClick={() => setEditing(true)}>
                <PencilSquareIcon className='w-4 mx-1' />
              </button>
            </div>
          </div>
        </>
      }
    </div>
  )
}
