import type { QueryPartHierarchy } from './PartCell'
import { MetadataSchema, MetadataValue, resolveMetadataObj } from 'src/lib/metadata'
import type { ExchangeRates } from 'api/src/lib/exchangeRates'

import type { Source } from 'types/graphql'

type BasisType = QueryPartHierarchy[number]
type SourceBasis = Pick<BasisType['part']['sources'][number], 'price' | 'priceCurrency'>
type NodePart = Pick<BasisType['part'], 'partNumber' | 'version' | 'metadata'> & {
  sources: SourceBasis[]
}
type NodeDependency = Pick<BasisType['dependency'], 'quantity' | 'groupId' /* | 'units' */>
export interface HierarchyNodeData {
  //hierarchy: string
  part: NodePart
  dependency: NodeDependency
}

type HierarchyTreeNode = HierarchyTreeRootNode | HierarchyTreePartNode | HierarchyTreeGroupNode
type HierarchyTreeChildNode = HierarchyTreePartNode | HierarchyTreeGroupNode
type HierarchyTreeConcreteNode = HierarchyTreePartNode | HierarchyTreeRootNode
type HierarchyTreePartNode = {
  kind: 'part'
  key: string
  parentKey?: undefined
  children: HierarchyTreeChildNode[]
  //value: QueryPartHierarchy[number]
  value: HierarchyNodeData
  groupId?: undefined
}
type HierarchyTreeGroupNode = {
  kind: 'group'
  key?: undefined
  parentKey: string
  children: HierarchyTreeChildNode[]
  value?: undefined
  groupId: string
}
export type HierarchyTreeRootNode = {
  kind: 'root'
  key: '1',
  parentKey?: undefined
  children: HierarchyTreeChildNode[]
  value?: undefined
  groupId?: undefined
}

export const getInstanceQuantity = (node: HierarchyTreeNode, partNumber: string, version: string): [number, number, string | undefined] => {
  let nodeValue: [number, number]
  if (node.value && node.value.part.partNumber === partNumber && node.value.part.version === version) {
    const d = node.value.dependency;
    nodeValue = [d.groupId ? 0 : d.quantity, d.quantity]
  }
  else {
    nodeValue = [0, 0]
  }

  const childrenValues = node.children.map(child => getInstanceQuantity(child, partNumber, version))

  const { childrenMin, childrenMax } = reduceChildValues(node.kind !== 'group' ? 'sum' : 'range',
    childrenValues, ([min, max]) => ({ min, max }))

  const quantity = node.value?.dependency.quantity ?? 1;

  return [(childrenMin * quantity) + nodeValue[0], (childrenMax * quantity) + nodeValue[1], node.value?.dependency.groupId ?? undefined]
}

export const buildHierarchyTree = (inputHierarchy: QueryPartHierarchy): HierarchyTreeRootNode => {
  const groupMap: {[groupName: string]: HierarchyTreeGroupNode} = {}
  const rootNode: HierarchyTreeRootNode = {
    kind: 'root',
    key: '1',
    children: inputHierarchy.filter(h =>
      h.hierarchy.startsWith("1.") && h.hierarchy.split('.').length === 2
    )
    .reduce((acc, child) => {
      const { groupId }  = child.dependency
      const node: HierarchyTreePartNode = {
          kind: 'part',
          key: child.hierarchy,
          value: child,
          children: []
        }
      if (!groupId) {
        acc.push(node)
      }
      else {
        let groupNode = groupMap['root:' + groupId]
        if (!groupNode) {
          groupNode = groupMap['root:' + groupId] = {
            kind: 'group',
            groupId,
            parentKey: '1',
            children: [],
          }
          acc.push(groupNode)
        }

        groupNode.children.push(node)
      }
      return acc
    }, [] as HierarchyTreeChildNode[])
  }

  const nodeMap = new Map<string, HierarchyTreeConcreteNode>()
  rootNode.children.forEach(n => {
    if (n.kind === 'part') {
      nodeMap.set(n.key, n)
    }
    else {
      n.children.forEach(cn => cn.kind === 'part' && nodeMap.set(cn.key, cn))
    }
  })

  inputHierarchy.forEach(hierarchyPart => {
    const key = hierarchyPart.hierarchy
    const node = nodeMap.get(key)
    //this was in the root node initialization loop and should not skipped
    if (node) return;

    const parentNodeKey = key.substring(0, key.lastIndexOf("."))
    const parentConcreteNode = nodeMap.get(parentNodeKey)!

    const newNode: HierarchyTreePartNode = {
      kind: 'part',
      key,
      value: hierarchyPart,
      children: []
    }
    nodeMap.set(key, newNode)

    const { groupId } = hierarchyPart.dependency
    if (!groupId) {
      parentConcreteNode.children.push(newNode)
    }
    else {
      const groupKey = `part:${parentNodeKey}:${groupId}`
      let groupNode = groupMap[groupKey]
      if (!groupNode) {
        groupNode = groupMap[groupKey] = {
          kind: 'group',
          groupId,
          parentKey: parentNodeKey,
          children: [],
        }
        parentConcreteNode.children.push(groupNode)
      }

      groupNode.children.push(newNode)
    }
  })

  return rootNode
}

type SumFieldConfig<T extends string> = {
  key: T
  multiplyByQuantity?: boolean
}
type SumAggregatorProps = {
  metadataSchema: MetadataSchema
  name: string
  targetType: NumberTarget
  exchangeRates: ExchangeRates | null | undefined
  metadata: SumFieldConfig<string>[]
  sources: SumFieldConfig<keyof Source>[]
}

type ChildValues = {
  min: number,
  max: number,
}
function sumChildValues<T>(children: T[], getChildValues: (child: T) => ChildValues) {
  const [childrenMin, childrenMax] = children.map(getChildValues).reduce(([accMin, accMax], {min, max}) =>
    [accMin + min, accMax + max], [0, 0]);
  return { childrenMin, childrenMax }
}

function rangeChildValues<T>(children: T[], getChildValues: (child: T) => ChildValues) {
  if (!children.length) {
    return { childrenMin: 0, childrenMax: 0 }
  }

  const values = children.map(getChildValues)

  const { min: initialMin, max: initialMax } = values[0]!;

  const [childrenMin, childrenMax] = values.reduce(([totalMin, totalMax], {min, max}) =>
    [Math.min(totalMin, min), Math.max(totalMax, max)],
    [initialMin, initialMax]);

  return { childrenMin, childrenMax }
}

function reduceChildValues<T>(operation: 'sum' | 'range', children: T[], getChildValues: (child: T) => ChildValues) {
  return (operation === 'sum' ? sumChildValues : rangeChildValues)(children, getChildValues)
}

const invertRate = (rate: number) => 1 / rate

const getConvertedValue = (exchangeRates: ExchangeRates | null | undefined, currentValue: { unit: string, value: number }, targetType: NumberTarget) => {
  if (!exchangeRates) return undefined
  const currentUnit = currentValue.unit, targetUnit = targetType.unit;

  // NonUSD to USD
  if (targetUnit === 'USD') {
    const targetRate = exchangeRates.rates[currentValue.unit]
    if (!targetRate) return undefined
    //console.log(`${currentValue.unit} to USD `, {targetRate: invertRate(targetRate), original: targetRate})
    return currentValue.value * invertRate(targetRate)
  }
  // USD to NonUSD
  else if (currentUnit === 'USD') {
    const targetRate = exchangeRates.rates[targetUnit]
    if (!targetRate) return undefined
    //console.log(`USD to ${targetType.unit} `, {targetRate})
    return currentValue.value * targetRate
  }
  // NonUSD_A to NonUSD_B through USD
  else {
    const currentRate = exchangeRates.rates[currentUnit]
    if (!currentRate) return undefined
    const standardizationRate = invertRate(currentRate)

    const targetRate = exchangeRates.rates[targetUnit]
    if (!targetRate) return undefined

    //console.log(`${currentUnit} to USD to ${targetUnit} `, {standardizationRate, targetRate})
    return currentValue.value * standardizationRate * targetRate
  }
}

const FailNode = [0, 0, { type: 'failed' }] as ReturnType<Aggregator['getValues']>
export const SumAggregator = ({metadataSchema, name, exchangeRates, targetType, metadata: metadataCfg, sources: sourcesCfg}: SumAggregatorProps): Aggregator => ({
  name,
  targetType,
  exchangeRates,
  getValues: (node, children) => {
    if (children.find(c => c.values[name]?.[2]?.type === 'failed')) {
      return FailNode
    }

    let scalableNodeMin = 0
    let scalableNodeMax = 0
    let unscalableNodeMin = 0
    let unscalableNodeMax = 0

    let conversionFrom: Extract<ConversionNote, { type: 'success' }>['from'] = new Set()

    const quantity = node.value?.dependency.quantity ?? 1

    if (node.kind === 'part') {
      const metadata = node.value?.part.metadata
      if (metadata) {
        const metadataObj = resolveMetadataObj(metadataSchema, metadata as Record<string, MetadataValue>)
        for (const cfg of metadataCfg) {
          const metadataValue = metadataObj[cfg.key]
          if (!metadataValue) continue

          let value: number = NaN;

          if (targetType.type === 'Basic') {
            value = metadataValue.entry.value ?? metadataValue?.entry
          }
          else {
            if (metadataValue.type === 'Number') {
              value = metadataValue.entry
            }
            else {
              if (metadataValue.type === 'Price' && targetType.type === 'Price') {
                const { entry } = metadataValue

                if (entry.unit === targetType.unit) {
                  value = entry.value
                }
                else {
                  const convertedValue = getConvertedValue(exchangeRates, entry, targetType)
                  if (!convertedValue) return FailNode
                  value = convertedValue
                  conversionFrom.add(entry.unit)
                }
              } else if (metadataValue.type === 'Mass' && targetType.type === 'Mass') {
                const { unit, value } = metadataValue.entry
                return FailNode //TODO: implement mass conversion
              }
            }
          }


          if (value && isFinite(value)) {
            if (cfg.multiplyByQuantity) {
              scalableNodeMin += (quantity * value)
              scalableNodeMax += (quantity * value)
            }
            else {
              unscalableNodeMin += value
              unscalableNodeMax += value
            }
          }
        }
      }

      const sources = node.value?.part.sources
      if (sources) {
        for (const cfg of sourcesCfg) {
          let srcMin: number | undefined = undefined;
          let srcMax: number | undefined = undefined;

          for (const s of sources) {
            const rawVal = (s as any)[cfg.key]
            let sourceVal: number | undefined = typeof rawVal === 'number' ? rawVal : undefined

            if (sourceVal) {
              if (cfg.key === 'price' && s.priceCurrency && s.priceCurrency !== targetType.unit) {
                const converted = getConvertedValue(exchangeRates, {value: sourceVal, unit: s.priceCurrency}, targetType)
                if (!converted) return FailNode

                sourceVal = converted
                conversionFrom.add(s.priceCurrency)
              }

              srcMin = srcMin ? Math.min(srcMin, sourceVal) : sourceVal
              srcMax = srcMax ? Math.max(srcMax, sourceVal) : sourceVal
            }
          }

          if (cfg.multiplyByQuantity) {
            scalableNodeMin += quantity * (srcMin ?? 0)
            scalableNodeMax += quantity * (srcMax ?? 0)
          }
          else {
            unscalableNodeMin += (srcMin ?? 0)
            unscalableNodeMax += (srcMax ?? 0)
          }
        }
      }
    }

    const nodeMin = unscalableNodeMin + scalableNodeMin
    const nodeMax = unscalableNodeMax + scalableNodeMax

    const scalableChildren = reduceChildValues(node.kind !== 'group' ? 'sum' : 'range',
      children, childNode => ({
        min: childNode.values[name]![3].scalableMin,
        max: childNode.values[name]![3].scalableMax,
      }))

    const {
      childrenMin: unscalableChildrenMin,
      childrenMax: unscalableChildrenMax,
    } = reduceChildValues(node.kind !== 'group' ? 'sum' : 'range',
      children, childNode => ({
        min: childNode.values[name]![3].unscalableMin,
        max: childNode.values[name]![3].unscalableMax,
      }))

    const scalableChildrenMin = scalableChildren.childrenMin * quantity
    const scalableChildrenMax = scalableChildren.childrenMax * quantity

    const childrenMin = scalableChildrenMin + unscalableChildrenMin
    const childrenMax = scalableChildrenMax + unscalableChildrenMax

    const combinedMin = nodeMin + childrenMin
    const combinedMax = nodeMax + childrenMax

    const unscalableMin: number = unscalableChildrenMin + unscalableNodeMin
    const unscalableMax: number = unscalableChildrenMax + unscalableNodeMax
    const scalableMin: number = scalableChildrenMin + scalableNodeMin
    const scalableMax: number = scalableChildrenMax + scalableNodeMax

    const usedConverisons = children.reduce((acc, c) => {
      const [_0, _1, note] = c.values[name]!;
      if (note?.type === 'success') {
        for (const u of note.from) acc.add(u)
      }
      return acc
    }, conversionFrom)

    return [
      Math.round((combinedMin + Number.EPSILON) * 100) / 100,
      Math.round((combinedMax + Number.EPSILON) * 100) / 100,
      !usedConverisons.size ? undefined : {
        type: 'success',
        from: usedConverisons,
      },
      {
        quantity,
        nodeMin,
        nodeMax,
        childrenMin,
        childrenMax,
        combinedMin,
        combinedMax,
        unscalableMin,
        unscalableMax,
        scalableMin,
        scalableMax,
      }
    ]
  }
})

export type NumberTarget = {
  type: 'Basic'
  unit: never
} | {
  type: 'Price'
  unit: string
} | {
  type: 'Mass'
  unit: string
}

type ConversionNote = undefined | {
  type: 'failed'
} | {
  type: 'success',
  from: Set<string>
}

type CalculationDetails = {
  quantity: number
  combinedMin: number
  combinedMax: number
  nodeMin: number
  nodeMax: number
  childrenMin: number
  childrenMax: number
  scalableMin: number
  scalableMax: number
  unscalableMin: number
  unscalableMax: number
}
export type Aggregator = {
  name: string,
  targetType: NumberTarget,
  exchangeRates: ExchangeRates | null | undefined,
  getValues: (node: HierarchyTreeNode, children: AggregationNode[]) => [number, number, ConversionNote, CalculationDetails]
}
export type AggregationRootNode = {
  kind: 'root'
  children: AggregationNode[]
  values: { [aggName: string]: ReturnType<Aggregator['getValues']> }
  groupId?: undefined
  backingNode?: HierarchyTreeNode
  aggregators: Aggregator[]
  nodeMap: Map<string, AggregationNode>
  getPartNode: (hierarchy: string) => AggregationPartNode | undefined
  getGroupNode: (parentKey: string, groupId: string) => AggregationGroupNode | undefined
}
type AggregationPartNode = {
  kind: 'part'
  children: AggregationNode[]
  values: { [aggName: string]: ReturnType<Aggregator['getValues']> }
  groupId?: undefined
  backingNode?: HierarchyTreeNode
}
type AggregationGroupNode = {
  kind: 'group'
  children: AggregationNode[]
  values: { [aggName: string]: ReturnType<Aggregator['getValues']> }
  groupId: string
  backingNode?: HierarchyTreeNode
}
export type AggregationNode = AggregationRootNode | AggregationPartNode | AggregationGroupNode
type AggregationChildNode = AggregationPartNode | AggregationGroupNode

type GetAggNode<T extends HierarchyTreeNode> =
  T extends HierarchyTreeRootNode ? AggregationRootNode :
  T extends HierarchyTreePartNode ? AggregationPartNode :
  AggregationGroupNode
const isRootNode = (node: HierarchyTreeNode): node is HierarchyTreeRootNode => node.kind === 'root'
const isPartNode = (node: HierarchyTreeNode): node is HierarchyTreePartNode => node.kind === 'part'
const isGroupNode = (node: HierarchyTreeNode): node is HierarchyTreeGroupNode => node.kind === 'group'

function aggTreeWalker<T extends HierarchyTreeNode> (node: T, aggregators: Aggregator[], filterEmpty: boolean, lookupMap?: Map<string, AggregationNode>): GetAggNode<T> {
  const usedMap = lookupMap ?? new Map<string, AggregationNode>()
  const children = node.children.map(n => aggTreeWalker(n, aggregators, filterEmpty, usedMap))

  const values = aggregators.reduce((acc, aggregator) => {
    acc[aggregator.name] = aggregator.getValues(node, children)
    return acc
  }, {} as Record<string, [number, number, ConversionNote]>)

  const childrenNodes = filterEmpty ? children.filter(c =>
    Object.values(c.values).some(v => v[0] !== 0 || v[1] !== 0))
    : children

  if (isRootNode(node)) {
    const aggNode: AggregationRootNode = {
      kind: 'root',
      values,
      children: childrenNodes,
      aggregators,
      nodeMap: usedMap,
      //backingNode: node
      getPartNode: (hierarchy: string) => usedMap.get(hierarchy) as AggregationPartNode,
      getGroupNode: (parentKey: string, groupId: string) =>
        usedMap.get(`parent:${parentKey}:group:${groupId}`) as AggregationGroupNode
    }
    usedMap.set('1', aggNode)
    return aggNode as GetAggNode<T>;
  }
  else if (isPartNode(node)) {
    const aggNode: AggregationPartNode = {
      kind: 'part',
      values,
      //children,
      children: childrenNodes
      //backingNode: node
    }
    usedMap.set(node.key, aggNode)
    //usedMap.set(node.value?.hierarchy, aggNode)
    return aggNode as GetAggNode<T>;
  }
  else {
    const aggNode: AggregationGroupNode = {
      kind: 'group',
      groupId: node.groupId,
      values,
      children: childrenNodes,
      //backingNode: node
    }
    usedMap.set(`parent:${node.parentKey}:group:${node.groupId}`, aggNode)
    return aggNode as GetAggNode<T>;
  }
}

export function buildAggregationTree(node: HierarchyTreeRootNode, aggregators: Aggregator[], filterEmpty: boolean = false): AggregationRootNode {
  return aggTreeWalker(node, aggregators, filterEmpty)
}








