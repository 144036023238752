import { Fragment, useState, useEffect, HTMLAttributes } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { twMerge } from 'tailwind-merge'

type ModalProps = {
  onClose: () => void
  outerClassName?: string
  overlayClassName?: string
} & HTMLAttributes<HTMLDivElement>
//the only control for a conditional modal is whether to render it or not
//could expand this file with other control types
export const ConditionalModal = ({children, onClose, className, outerClassName, overlayClassName, ...props}: ModalProps) => {
  const [showModal, setShowModal] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      setShowModal(true)
    }, 0)
  }, [])

  const joinedClassName = twMerge(
    'p-4 w-96 mx-auto mt-40 bg-white rounded',
    className
  )

  const overlayClass = twMerge(
    'fixed inset-0 bg-black/30',
    overlayClassName
  )

  return (
    <Transition show={showModal} as={Fragment}>
      <Dialog onClose={() => onClose()} className={outerClassName} >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className={overlayClass} aria-hidden/>
        </Transition.Child>
        <Transition.Child as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Dialog.Panel className='relative !fixed inset-x-0 top-0 h-0'>
            <div className={joinedClassName} {...props}>
              {children}
            </div>
          </Dialog.Panel>
        </Transition.Child>
      </Dialog>
    </Transition>
  )
}
