import type { MapperConfig } from '../mapperConfigs'

import { CreoLogo, Logo, SolidworksLogo, AltiumLogo } from 'src/components/CadLogo'

const mapperConfig: MapperConfig = {
  name: 'Solidworks',
  orgIds: ['demo-1'],
  rootCategoryId: '499',
  rootIsTopLevel: true,
  Logo: SolidworksLogo,
  importOutputFields: {
    metadata: []
  },
  standardizeBom: {
    columns: {
      metadata: {},
      sources: [{
        distributorName: {
          type: 'simple',
          value: '${row.distributor_name}'
        },
        distributorSku: {
          type: 'simple',
          value: '${row.distributor_sku}'
        },
        url: [{
          conditions: [{
            'column': 'distributor_id',
            type: 'neq',
            value: 'McMaster-Carr'
          }, {
            'column': 'distributor_sku',
            type: 'empty'
          }],
          onMatch: {
            type: 'simple',
            value: ''
          }
        }, {
          default: true,
          onMatch: {
            type: 'simple',
            value: 'https://www.mcmaster.com/${row.distributor_sku}/'
          }
        }]
      }],
      summary: {
        type: 'ignore'
      },
      hierarchy: {
        value: '{{row.level}}',
        type: 'simple'
      },
      partNumber: {
        value: '{{row.part_number}}',
        type: 'simple'
      },
      cadRev: {
        value: '{{row.revision}}',
        type: 'simple'
      },
      categoryId: {
        type: 'split',
        input: '{{row.part_number}}',
        splitOn: '-',
        value: '{{parts.0}}',
      },
      isOffTheShelf: {
        value: '{{row.off_the_shelf}}',
        type: 'simple'
      },
      name: {
        value: '{{row.description}}',
        type: 'simple'
      },
      quantity: {
        value: '{{row.qty}}',
        type: 'simple',
      },
      units: {
        value: 'each',
        type: 'quantity'
      },
    }
  }
}

export default mapperConfig
