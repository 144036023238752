export const rwUrlEncode = (urlComponent: string) => urlComponent.replace(".", "~")
export const rwUrlDecode = (urlComponent: string) => urlComponent.replace("~", ".")

type KeysOfType<O, T> = {
  [K in keyof O]: O[K] extends T ? K : never;
}[keyof O];
type Return<T, K extends KeysOfType<T, string>> =
    { [KK in string & T[K]]?: ({ [_ in K]: KK } & T)[] }

export function groupBy<T, K extends KeysOfType<T, string>>( arr: T[], keyExtractor: (element: T) => T[K]): Return<T, K> {
  return arr.reduce((acc, item) => {
    const group = keyExtractor(item) as string & T[K];
    const groupArray: T[] = (acc[group] = acc[group] ?? [])
    groupArray.push(item)
    return acc
  }, {} as Return<T, K>)
}

//code should never reach here
export const exhaustiveCheck = (_: never): never => {throw new Error('Missing implementation for branch')}

declare const __brand: unique symbol
type Branded<B> = { [__brand]: B }
export type Brand<T, B> = T & Branded<B>
