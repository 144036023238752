import classNames from 'classnames'
import { twMerge } from 'tailwind-merge'

import { routes, useParams } from '@redwoodjs/router'

import { PlusIcon, MinusIcon, ExclamationTriangleIcon, ChevronUpDownIcon, XCircleIcon } from '@heroicons/react/20/solid'
import { groupBy } from 'src/lib/util'

import { AggregationRootNode, AggregationNode, Aggregator } from 'src/components/PartCell/aggregation';

import { StatusDot } from '../LifecycleStatus/LifecycleStatus'

import type {
  Part,
  PartHierarchy,
  Dependency,
  PartCategory
} from 'types/graphql'

import { AppLink } from 'src/lib/routing'
import { ReactNode, useState, createContext, useContext } from 'react'
import { TagIcon, MapPinIcon, SwatchIcon } from '@heroicons/react/24/outline';
import padEnd from 'lodash.padend'
import padStart from 'lodash.padstart'
import { letF } from 'api/src/shared/functional'
import * as Tooltip from "src/components/ToolTip"

export type RequiredPartHierarchy = Pick<PartHierarchy, 'hierarchy'> & {
  part: Pick<Part, 'version' | 'partNumber' | 'name' | 'lifeCycle'> & {
    proto: {
      currentVersionString?: string | undefined | null
      category: Pick<PartCategory, 'name'>
    }
  },
  dependency?: Pick<Dependency, 'quantity' | 'units' | 'referenceDesignator' | 'groupId'>
}

type RequiredPart = Pick<Part, 'version' | 'partNumber' | 'name' | 'lifeCycle'> & {
  hierarchy: RequiredPartHierarchy[]
  proto: {
    category: Pick<PartCategory, 'name'>
  }
}

export const getSelection = (hierarchy: RequiredPartHierarchy[], selectedNodes: string[]): RequiredPartHierarchy[] => {
  const filteredHierarchy: RequiredPartHierarchy[] = []

  let scanIdx = 0;
  while (scanIdx < hierarchy.length) {
    const element = hierarchy[scanIdx]!
    if (selectedNodes.includes(element.hierarchy)) {
      filteredHierarchy.push(element)
      scanIdx += 1;
    }
    else {
      const childSkipper = element.hierarchy + '.'
      const lastSkippedChild = hierarchy.findLastIndex(e => e.hierarchy.startsWith(childSkipper))
      if (lastSkippedChild === -1) {
        scanIdx += 1;
      }
      else {
        scanIdx = lastSkippedChild + 1;
      }
    }
  }
  return filteredHierarchy;
}

export type SubjectPart = {
  hierarchy: string
  className?: string
  message?: ReactNode
  hideChildren?: boolean
}

type PartHierarchyExplorerProps = {
  rootPart: RequiredPart,
  // highlightedPartNumber?: string
  subjectParts: SubjectPart[]
  expandedNodes?: string[]
  selectable?: boolean
  selectedNodes?: string[]
  setSelectedNodes?: (nodes: string[]) => void
  activeAggregators?: Aggregator[]
  aggregations?: AggregationRootNode
}

const findChildren = (fullHierarchy: RequiredPartHierarchy[], hierarchyLevel: string) => {
  const hierarchy = hierarchyLevel.split('.');
  const childFinder = new RegExp('^' + hierarchy.join('[.]') + '[.]\\d+$')

  return fullHierarchy
    .filter(p => childFinder.test(p.hierarchy))
}

type HierarchyContextType = {
  rootPart: RequiredPart
  subjectParts: SubjectPart[]
  selectable?: boolean
  selectedNodes: string[]
  setSelectedNodes: (nodes: string[]) => void
  activeAggregators?: Aggregator[]
  aggregations?: AggregationRootNode
} | null
const HierarchyContext = createContext<HierarchyContextType>(null)
const BranchDisabledContext = createContext<boolean>(false)

const PartHierarchy: React.FC<PartHierarchyExplorerProps> = ({
  rootPart,
  subjectParts,
  expandedNodes,
  selectable,
  selectedNodes,
  setSelectedNodes,
  activeAggregators,
  aggregations,
}) => {
  const node: RequiredPartHierarchy = {
    hierarchy: '1',
    part: {
      version: rootPart.version,
      partNumber: rootPart.partNumber,
      lifeCycle: rootPart.lifeCycle,
      name: rootPart.name,
      proto: {
        category: {
          name: rootPart.proto.category.name
        }
      }
    },
  }

  return <HierarchyContext.Provider value={{
    rootPart,
    subjectParts,
    selectable,
    activeAggregators,
    aggregations,
    selectedNodes: selectedNodes || [],
    setSelectedNodes: setSelectedNodes || (() => {}),
  }}>
    <PartHierarchyNode
      node={node}
      expand={expandedNodes?.includes('1')}
      expandedNodes={expandedNodes} />
  </HierarchyContext.Provider>
}

type Padding = {
  quantity: number
  version: number
  partNumber: number
}

const quantityAndUnit = (quantity: number, units: string) => {
  const unit = (['each', 'units']).includes(units) ? '' : ` ${units}`
  return `${quantity}${unit}`
}

type PartHierarchyNodeProps = {
  padding?: Padding
  expand?: boolean
  node: RequiredPartHierarchy | { groupId: string, children: RequiredPartHierarchy[], hierarchy: string }
  expandedNodes?: string[]
  parentKey?: string
}
const PartHierarchyNode: React.FC<PartHierarchyNodeProps> = ({
  node,
  padding,
  expand,
  expandedNodes,
  parentKey,
}) => {
  const context = useContext(HierarchyContext)
  if (!context) {
    throw new Error("Missing HierarchyContext")
  }
  const {
    rootPart,
    subjectParts,
    selectedNodes,
    setSelectedNodes,
    selectable,
    activeAggregators,
    aggregations,
  } = context;
  const branchDisabled = useContext(BranchDisabledContext)

  const orgId = useParams().orgId!
  const isGroup = 'groupId' in node
  const hasAggregations = (activeAggregators?.length || 0) > 0
  const [expanded, setExpanded] = useState(expand)

  const isRoot = node.hierarchy === '1'

  // the sorting is deeply inefficient, but looks nicer to have assemblies first
  const children = findChildren(rootPart.hierarchy, node.hierarchy).sort((childA, childB) => {
    const hasChildrenA = findChildren(rootPart.hierarchy, childA.hierarchy).length > 0
    const hasChildrenB = findChildren(rootPart.hierarchy, childB.hierarchy).length > 0
    if (hasChildrenA && !hasChildrenB) return -1
    if (!hasChildrenA && hasChildrenB) return 1
    return 0
  })

  const nodeProps = subjectParts?.find(p => p.hierarchy === node.hierarchy)

  const renderChildren = !nodeProps?.hideChildren
  const expandable = (isGroup || Boolean(renderChildren && children.length))
  const iconClass = 'h-5 w-5'
  const toggleClass = classNames(
    twMerge(
      'h-9 px-3 flex rounded border border-gray-200 items-center gap-3 bg-white',
      nodeProps?.className,
    ),
    {
      'cursor-pointer': expandable
    }
  )

  const toggleExpand = () => {
    if (expandable) setExpanded(!expanded)
  }

  const expandButton = () => {
    const icon = expanded ?
      <MinusIcon data-testid='collapse-hierarchy' className={iconClass} /> :
      <PlusIcon data-testid='expand-hierarchy' className={iconClass} />

    return <div className=' text-gray-500'>
      {expandable ? icon : <div className={iconClass} />}
    </div>
  }

  const checked = (isGroup && node.children.some(c => selectedNodes.includes(c.hierarchy))) || selectedNodes.includes(node.hierarchy)
  const disableChildren = isGroup ? (branchDisabled) : (branchDisabled || (!isRoot && !checked));

  const selectBox = () => {
    if (!selectable || isRoot) return null
    return <div className='flex items-center cursor-pointer'>
      <input
        onClick={e => e.stopPropagation()}
        id="hierarchy-node-toggle"
        data-testid={`hierarchy-node-checkbox-${node.part?.partNumber || node.groupId}`}
        name="hierarchy-node"
        type="checkbox"
        onChange={e => {
          let newNodes: typeof selectedNodes

          if (isGroup) {
            const children = node.children.map(c => c.hierarchy)
            const decendantFilter = children.map(c => c + '.')
            const allDecendants = rootPart.hierarchy
              .filter(h => decendantFilter.some(d => h.hierarchy.startsWith(d)))
              .map(d => d.hierarchy)

            if (e.currentTarget.checked) {
              newNodes = Array.from(new Set([...selectedNodes, ...children, ...allDecendants]))
            }
            else {
              const dSet = new Set(allDecendants)
              newNodes = selectedNodes.filter(n => !children.includes(n) && !dSet.has(n))
            }
          }
          else {
            if (e.currentTarget.checked) {
              if (!expanded) {
                //when node is collapsed, include children automatically
                const allDecendants = rootPart.hierarchy
                  .filter(h => h.hierarchy.startsWith(node.hierarchy + '.'))
                  .map(d => d.hierarchy)
                newNodes = Array.from(new Set([...selectedNodes, node.hierarchy, ...allDecendants]))
              }
              else {
                newNodes = [...selectedNodes, node.hierarchy]
              }
            }
            else {
              const dSet = new Set(rootPart.hierarchy
                .filter(h => h.hierarchy.startsWith(node.hierarchy + '.'))
                .map(d => d.hierarchy))
              newNodes = selectedNodes.filter(n => n !== node.hierarchy && !dSet.has(n))
            }
          }

          setSelectedNodes(newNodes)
        }}
        checked={checked}
        disabled={branchDisabled}
        aria-describedby="hierarchy-node-checkbox"
        className="h-4 w-4 rounded border-gray-300 text-brand-600 focus:ring-brand-600 cursor-pointer"
      />
    </div>
  }

  const ControlBox = () => {
    const branchDisabled = useContext(BranchDisabledContext)
    const allDecendants = rootPart.hierarchy
      .filter(h => h.hierarchy.startsWith(node.hierarchy + '.'))
      .map(d => d.hierarchy)
    return <div className='flex items-center cursor-pointer'>
      <input
        onClick={e => e.stopPropagation()}
        name="dependencyControl"
        type="checkbox"
        onChange={e => {
          setSelectedNodes(
            e.target.checked ?
              Array.from(new Set([...selectedNodes, ...allDecendants])) :
              selectedNodes.filter(n => !allDecendants.includes(n))
          )
        }}
        checked={allDecendants.every(d => selectedNodes.includes(d))}
        disabled={branchDisabled}
        aria-describedby="select-all-children"
        className="h-4 w-4 rounded border-gray-300 text-brand-600 focus:ring-brand-600 cursor-pointer"
      />
    </div>
  }

  const AggNode = ({agg, values}: { agg: Aggregator, values: AggregationNode['values']}) => {
    const formatter = (n: number) => agg.targetType.type === 'Price' ?
      n.toLocaleString("en", { minimumFractionDigits: 2 }) : n
    return <div key={agg.name}>
      {letF(values[agg.name]!, ([min, max, note]) =>
        <div className='min-w-36 text-right'>
          {note?.type === 'failed' ?
            <Tooltip.Container className='relative'>
              <XCircleIcon className='w-4 inline'/>
              <Tooltip.Message className="bottom-10">
                There was an error calulating this value
              </Tooltip.Message>
            </Tooltip.Container> :
           note?.type === 'success' ?
            <Tooltip.Container className='relative'>
              {min === max ?
                formatter(min) : `${formatter(min)} - ${formatter(max)}`
              }*
              <Tooltip.Message className="bottom-10">
                This value includes conversions from the following units: [{Array.from(note.from).join(", ")}]
              </Tooltip.Message>
            </Tooltip.Container> :
           min === max ?
             formatter(min) : `${formatter(min)} - ${formatter(max)}`
          }
        </div>
      )}
    </div>
  }

  if (isGroup) {
    const aggValues = aggregations?.getGroupNode(parentKey!, node.groupId)?.values
    const childPadding = node.children.reduce((padding, child) => {
      const quantityValue = child.dependency ? quantityAndUnit(child.dependency.quantity, child.dependency.units) : ''
      return {
        quantity: Math.max(quantityValue.length, padding.quantity),
        version: Math.max(child.part.version.length, padding.version),
        partNumber: Math.max(child.part.partNumber.length, padding.partNumber),
      }
    }, { quantity: 0, version: 0, partNumber: 0 } as { quantity: number, version: number, partNumber: number })
    return <div key={node.hierarchy} className='text-sm'>
      <div onClick={toggleExpand} className={toggleClass}>
        <SwatchIcon className='w-4 -mx-1' />
        {selectBox()}
        <div className='flex-1'>
          <div>{node.groupId}</div>
        </div>
        {(hasAggregations && aggValues) &&
          <div className='inline-flex flex-row justify-end gap-2'>
            {(activeAggregators!).map(a => <AggNode key={a.name} values={aggValues} agg={a} />)}
          </div>
        }
        {expandButton()}
      </div>
      {expanded && <div className='mt-4 mb-1 flex'>
        <div className='border-l border-gray-100 mx-2 mt-1 mb-4'></div>
        <div className='flex flex-col gap-2 flex-1'>
          <BranchDisabledContext.Provider value={disableChildren}>
            {node.children.map(c =>
              <PartHierarchyNode key={c.hierarchy} node={c} padding={childPadding}
                expandedNodes={expandedNodes}/>
            )}
          </BranchDisabledContext.Provider>
        </div>
      </div>
      }
    </div>
  }
  else {
    const SPACE = '\u00A0'

    const quantity = () => {
      if (!node.dependency) return null
      const value = quantityAndUnit(node.dependency.quantity, node.dependency.units)
      const quantityValue = padStart(value, padding?.quantity, SPACE)
      return <div className='text-gray-500'>{quantityValue} x </div>
    }

    const referenceDesignator = () => {
      if (!node.dependency?.referenceDesignator) return null
      return <div className='text-gray-500 flex gap-1 items-center'>
        <MapPinIcon className='h-3 w-3' />
        <div>{node.dependency.referenceDesignator}</div>
      </div>
    }

    const childPadding = children.reduce((padding, child) => {
      const quantityValue = child.dependency ? quantityAndUnit(child.dependency.quantity, child.dependency.units) : ''
      return {
        quantity: Math.max(quantityValue.length, padding.quantity),
        version: Math.max(child.part.version.length, padding.version),
        partNumber: Math.max(child.part.partNumber.length, padding.partNumber),
      }
    }, { quantity: 0, version: 0, partNumber: 0 } as { quantity: number, version: number, partNumber: number })

    const childrenWithGroups =
      [
        ...children.filter(c => !c.dependency?.groupId) as (RequiredPartHierarchy & {groupId?: undefined})[],
        ...Object.entries(
          groupBy(
            children.filter(c => c.dependency?.groupId), c => c.dependency?.groupId
          )
        ).map(([groupId, group]) => ({
          groupId,
          children: group!,
          hierarchy: ''
        })),
      ]

    const subParts = (expanded && renderChildren) ?
      <div className='mt-4 mb-1 flex'>
        <div className='border-l border-gray-100 mx-2 mt-1 mb-4'></div>
        <div className='flex flex-col gap-2 flex-1'>
          <BranchDisabledContext.Provider value={disableChildren}>
            {selectable &&
              <div className='h-6 px-3 pb-2 -mb-1 flex border-gray-200 items-center gap-3 bg-white'>
                <div className='w-4 -mx-1'/>
                <ControlBox/>
                Select All Children
              </div>
            }
            {childrenWithGroups.map(c =>
              <PartHierarchyNode key={c.groupId ?? c.hierarchy} node={c} padding={childPadding}
                expandedNodes={expandedNodes}
                parentKey={node.hierarchy}
                expand={expandedNodes?.includes(c.hierarchy)} />
            )}
          </BranchDisabledContext.Provider>
        </div>
      </div>
      : null

    const versionPadding = padding?.version ? padding.version + 1 : undefined
    const version = padEnd(`v${node.part.version}`, versionPadding, SPACE)

    const partNumberPadding = padding?.partNumber ? padding.partNumber + 1 : undefined
    const partNumber = padEnd(`#${node.part.partNumber}`, partNumberPadding, SPACE)

    const message = nodeProps?.message || null

    const aggValues = aggregations?.getPartNode(node.hierarchy)?.values

    return <div key={node.hierarchy} className='text-sm'>
      <div onClick={toggleExpand} className={toggleClass}>
        <div className='flex-1 gap-3 flex bg-inherit font-mono'>
          <div className='flex items-center'>
            <StatusDot lifeCycle={node.part.lifeCycle} size='sm' />
          </div>
          {selectBox()}
          {quantity()}
          <div className='font-medium'>{partNumber}</div>
          <div className=''>{version}</div>
          <div className='relative flex-1 bg-inherit'>
            <div className='absolute left-0 right-0 bg-inherit hover:pr-2 overflow-hidden text-ellipsis whitespace-nowrap hover:overflow-visible hover:whitespace-normal backdrop-brightness-100 hover:z-20'>
              <AppLink className='underline text-blue-700 font-sans' to={routes.part({orgId, partNumber: node.part.partNumber})}>{node.part.name}</AppLink>
            </div>
          </div>
        </div>
        {message}
        {referenceDesignator()}
        <div></div>
        {(hasAggregations && aggValues) &&
          <div className='inline-flex flex-row justify-end gap-2'>
            {(activeAggregators!).map(a => <AggNode key={a.name} values={aggValues} agg={a} />)}
          </div>
        }
        {!hasAggregations &&
          <div className='flex gap-1 text-gray-500 items-center'>
            <TagIcon className='h-3 w-3' />
            {node.part.proto.category.name}
          </div>
        }
        {expandButton()}
      </div>
      {subParts}
    </div>
  }
}

export default PartHierarchy
