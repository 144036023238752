import type { PartCompareQuery, PartCompareQueryVariables } from 'types/graphql'
import type { CellSuccessProps, CellFailureProps } from '@redwoodjs/web'

import { useParams, routes, navigate } from '@redwoodjs/router';
import calculateAllChanges from 'src/components/ChangeOrderChangesCell/calculateAllChanges'
import { PartChange, LifecycleStatus } from '../ChangeOrderChangesCell'
import { AppLink } from 'src/lib/routing'
import { ChevronLeftIcon } from '@heroicons/react/20/solid'
import { LoadingSpinnerWithDelay as LoadingSpinner } from 'src/components/Loading'
import * as ListBox from 'src/components/ListBox'

export const QUERY = gql`
  query PartCompareQuery($basePartNumber: String!, $baseVersion: String!, $incomingPartNumber: String!, $incomingVersion: String!) {
    currentOrg {
      id
      distributors {
        id
        name
      }
      metadataSchema
    }
    baseProto: partProto(partNumber: $basePartNumber) {
      partNumber
      owner {
        name
      }
      category {
        name
      }
      protoUpdatedBy {
        name
      }
      instances {
        version
        branch
        publishId
        inChangeOrder {
          number
          name
        }
      }
      selectedVersion: instance(version: $baseVersion) {
        name
        isRoot
        isOffTheShelf
        partNumber
        version
        summary
        changeMessage
        transitionPlan
        cadRev
        metadata
        lifeCycle
        artifacts {
          id
          filename
          fileId
          file {
            url
            inlineUrl
            contentType
            size
          }
        }
        sources {
          id
          url
          comment
          distributor {
            id
            name
          }
          priority
          distributorSku
          price
          priceCurrency
          leadTimeDays
          stock
        }
        dependencies {
          section
          units
          quantity
          groupId
          to {
            lifeCycle
            name
            partNumber
            version
            cadRev
            branch
          }
        }
      }
    }
    incomingProto: partProto(partNumber: $incomingPartNumber) {
      partNumber
      owner {
        name
      }
      category {
        name
      }
      protoUpdatedBy {
        name
      }
      instances {
        version
        branch
        publishId
        inChangeOrder {
          number
          name
        }
      }
      selectedVersion: instance(version: $incomingVersion) {
        name
        isRoot
        isOffTheShelf
        partNumber
        version
        summary
        changeMessage
        transitionPlan
        cadRev
        metadata
        lifeCycle
        artifacts {
          id
          filename
          fileId
          file {
            url
            inlineUrl
            contentType
            size
          }
        }
        sources {
          id
          url
          comment
          distributor {
            id
            name
          }
          priority
          distributorSku
          price
          priceCurrency
          leadTimeDays
          stock
        }
        dependencies {
          section
          units
          quantity
          groupId
          to {
            lifeCycle
            name
            partNumber
            version
            cadRev
            branch
          }
        }
      }
    }
  }
`

export const Loading = () => <LoadingSpinner className='flex p-10 items-center justify-center' />

export const Empty = () => <div>Empty</div>

import GenericFailure from '../Failure/Failure'
import PartSelectCell from '../PartSelectCell';
export const Failure = GenericFailure

export const Success = ({ currentOrg, ...props }: PartCompareQueryVariables & CellSuccessProps<PartCompareQuery, PartCompareQueryVariables>) => {
  return <PartCompare currentOrg={currentOrg} {...props} />
}

const PartCompare = ({
  baseProto,
  basePartNumber,
  baseVersion,
  incomingProto,
  incomingVersion,
  incomingPartNumber,
  currentOrg
}: PartCompareQueryVariables & CellSuccessProps<PartCompareQuery, PartCompareQueryVariables>) => {
  const orgId = useParams().orgId!
  if (!baseProto) throw new Error(`Base part not found: ${basePartNumber}`)
  if (!incomingProto) throw new Error(`Base part not found: ${incomingPartNumber}`)

  const partDiff = calculateAllChanges(currentOrg, [{
    ...incomingProto.selectedVersion,
    updateTo: baseProto.selectedVersion
  }])[0]!

  const renderSelectors = (proto: typeof baseProto, onChange: (pn: string, v: string) => void) => {
    const instances = proto.instances.filter(p => {
      // main branch
      return p.branch === -1
    })
    // sorted latest version first
    instances.sort((a, b) => {
      // those without publish ID will be filtered anyway
      return b.publishId! - a.publishId!
    })

    const versionString = proto.selectedVersion.version

    return <div className='text-xs flex gap-2'>
      <PartSelectCell
        value={proto.partNumber}
        sort={(a, b) => {
          if (a.partNumber === proto.partNumber) return -1
          if (b.partNumber === proto.partNumber) return 1
          return 0
        }}
        // omit={[proto.partNumber]}
        onSelect={p => onChange(p.partNumber, p.version)} />
      <ListBox.ListBox value={versionString} onChange={v => onChange(proto.partNumber, v)}>
        {({ open }) => (<>
          <div className="relative inline-block text-sm">
            <ListBox.Button className='' displayValue={`Version ${versionString}`} />
            <ListBox.Options open={open}>
              {instances.map(o => {
                return <ListBox.Option value={o.version} display={`Version ${o.version}`} key={o.version} />
              })}
            </ListBox.Options>
          </div>
        </>
        )}
      </ListBox.ListBox>
    </div>
  }

  return <div>
    <div className='mt-8 mb-4 ml-4'>
      <div className='text-2xl mb-1'>Compare Changes</div>
      <div className='text-sm text-gray-600'>Compare changes across parts and versions</div>
    </div>
    <div className='rounded-lg border border-gray-400'>
      <div className='px-8 pb-5 flex flex-col pt-6'>
        <div className='flex mb-6 mt-4'>
          <div className="flex-1 flex justify-between items-center">
            {/* Left version list is reversed, UI is more intuitive that way */}
            {renderSelectors(baseProto, (pn, v) => {
              navigate(routes.partCompare({
                orgId,
                basePartNumber: pn,
                baseVersion: v,
                incomingPartNumber,
                incomingVersion
              }))
            })}
            <LifecycleStatus disabled part={baseProto.selectedVersion} />
          </div>
          <div className='border-l border-gray-200 mx-10'></div>
          <div className="flex-1 flex justify-between items-center">
            {renderSelectors(incomingProto, (pn, v) => {
              navigate(routes.partCompare({
                orgId,
                basePartNumber,
                baseVersion,
                incomingPartNumber: pn,
                incomingVersion: v,
              }))
            })}
            <LifecycleStatus disabled part={incomingProto.selectedVersion} />
          </div>
        </div>
    </div>
    <PartChange
      hideVersion
      distributors={currentOrg.distributors}
      changeOrderComplete
      partDiff={partDiff}
      headPart={baseProto.selectedVersion}
      incomingPart={incomingProto.selectedVersion}
    /></div>
    </div>
}
