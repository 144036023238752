
import {
  Part,
  PartSelectQuery,
  PartCompareQueryVariables,
} from 'types/graphql'

import { useState } from 'react'
import type { CellSuccessProps, CellFailureProps } from '@redwoodjs/web'
import { inputClasses } from 'src/components/Input'
import { optionsClasses, ComboboxOption, ComboboxOptions, ComboboxButton } from 'src/components/ListBox'

import { Combobox, Transition } from '@headlessui/react'

export const QUERY = gql`
query PartSelectQuery {
  partProtos {
    partNumber
    inChangeOrders {
      number
    }
    currentVersion {
      isRoot
      name
      version
    }
  }
}
`


export type PartDetail = Pick<Part, 'partNumber' | 'version' | 'name' | 'isRoot'>

type CellProps = {
  className?: string
  disabled?: boolean
  placeholder?: string
  value?: string
  sort?: (a: PartDetail, b: PartDetail) => number
  defaultValue?: string
  omit?: string[]
  extraParts?: PartDetail[]
  omitTopLevel?: boolean
  onSelect: (part: PartDetail) => void
}

export const Loading = ({ ...props }: CellProps) => {
  return <PartSelect {...props} />
}

type SuccessProps = CellSuccessProps<PartSelectQuery, PartCompareQueryVariables> & CellProps
export const Success = ({ ...props }: SuccessProps) => {
  return <PartSelect {...props} />
}

import { FailureErrorBar } from '../Failure/Failure'
export const Failure = (props: CellProps & CellFailureProps) => {
  return <FailureErrorBar userErrorMessage='There was an error loading parts' {...props}>
    <PartSelect {...props} />
  </FailureErrorBar>
}

type PartSelectProps = CellProps & Partial<CellSuccessProps<PartSelectQuery, PartCompareQueryVariables>>

export const PartSelect = ({
  disabled,
  sort,
  className,
  omit,
  partProtos,
  placeholder,
  onSelect,
  value,
  extraParts,
  omitTopLevel,
  defaultValue }: PartSelectProps) => {
  const [query, setQuery] = useState('')
  const [opened, setOpened] = useState(false)
  extraParts = extraParts || []
  const mainBranchParts = partProtos?.filter(p => {
    if (!p.currentVersion) return false
    if (extraParts?.some(extra => extra.partNumber === p.partNumber)) return false
    return true
  }) || []
  const mainParts: PartDetail[] = mainBranchParts.map(proto => {
    return {
      partNumber: proto.partNumber,
      version: proto.currentVersion.version,
      name: proto.currentVersion.name,
      isRoot: proto.currentVersion.isRoot
    }
  })

  const allParts = [...extraParts, ...mainParts]

  const insensitiveQuery = query.toLowerCase()
  const filteredParts = allParts.filter(p => {
    if (omit && omit.includes(p.partNumber)) return false
    if (omitTopLevel && p.isRoot) return false

    return p.partNumber.toLowerCase().includes(insensitiveQuery) ||
      p.name?.toLowerCase().includes(insensitiveQuery)
  })

  if (sort) {
    filteredParts.sort(sort)
  }

  const chosenPart = value ? allParts.find(p => p.partNumber === value) : undefined
  const defaultPart = defaultValue ? allParts.find(p => p.partNumber === defaultValue) : undefined
  const handleSelect = (p: PartDetail) => {
    onSelect(p)
  }

  return (
    <div className={`relative ${className || ''}`}>
    <Combobox as='div' defaultValue={defaultPart} value={chosenPart} disabled={disabled} onChange={handleSelect}>
      <Combobox.Input
        className={inputClasses}
        onChange={(event) => setQuery(event.target.value)}
        displayValue={(part: PartDetail) => {
          return opened ? query : (chosenPart?.partNumber || '')
        }}
        placeholder={placeholder || 'Type to select part'} />
      <ComboboxButton />
      <Transition
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
        beforeEnter={() => {
          setOpened(true)
        }}
        // afterEnter={() => setQuery('b')}
        afterLeave={() => {
          setQuery('')
          setOpened(false)
        }}
      >
        <ComboboxOptions align='left' className={optionsClasses + ' left-0'}>
          {filteredParts.map((part) => (
            <ComboboxOption key={part.partNumber} value={part} className='max-w-lg'>
              <div className='flex flex-col' data-testid={`part-select-option-${part.partNumber}`}>
                <div className='font-semibold'>
                  {part.partNumber}
                </div>
                <div>
                  {part.name}
                </div>
              </div>
            </ComboboxOption>
          ))}
        </ComboboxOptions>
      </Transition>
    </Combobox>
    </div>
  )
}
