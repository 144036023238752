import {
  MetadataType,
  AddToMetadataSchemaMutation,
  AddToMetadataSchemaMutationVariables,
  EditMetadataMutation,
  EditMetadataMutationVariables,
  Part,
} from 'types/graphql'

import kebabCase from 'lodash.kebabcase'
import { QUERY } from 'src/components/ChangeOrderChangesCell'
import type { PartDiffSummary, EditMode } from './ChangeOrderChangesCell/calculateAllChanges'
import { useRef, useState, useContext } from 'react'
import { Controller, useForm } from '@redwoodjs/forms'
import { useMutation } from '@redwoodjs/web'
import { resolveMetadata, MetadataSchemaContext, MetadataSchema, metadataTypes, ResolvedMetadata } from 'src/lib/metadata'
import * as Form from 'src/components/Form'
import * as ListBox from 'src/components/ListBox'
import { ConditionalModal } from './Modal'
import { TrashIcon } from '@heroicons/react/24/outline'
import Button, { EditButton } from 'src/components/Button'
import { reportMutationError } from 'src/lib/reportError'
import { letF } from 'src/lib/functional'
import { codes as currencyCodes } from 'currency-codes'
import { masses } from 'src/lib/units'

const usedTypes = metadataTypes.filter(t => t !== 'Time');

const ADD_TO_METADATA_SCHEMA_MUTATION = gql`
mutation AddToMetadataSchemaMutation (
  $input: AddToMetadataSchemaInput!
) {
  addToMetadataSchema(input: $input) {
    id
    metadataSchema
  }
}
`
const EDIT_METADATA_MUTATION = gql`
mutation EditMetadataMutation (
  $changeOrderNumber: Int!
  $partNumber: String!
  $version: String!
  $metadata: JSON
) {
  addPartDeltas(changeOrderNumber: $changeOrderNumber, input: [{
    type: Patch
    partNumber: $partNumber
    version: $version
    part: {
      metadata: $metadata
    }
  }]) {
    partNumber
  }
}
`

type MetadataSchemaEditorWIPProps = {
  onClose: (selected?: string) => void
}
export const MetadataSchemaEditor = (props: MetadataSchemaEditorWIPProps) => {
  const { onClose } = props;
  const metadataSchema = useContext(MetadataSchemaContext)
  if (!metadataSchema) { return; }

  const formMethods = useForm<AddSchemaInput>();

  const [addToMetadataSchema, { loading: loadingSchemaUpdate, error: schemaUpdateError }] = useMutation<AddToMetadataSchemaMutation, AddToMetadataSchemaMutationVariables>(ADD_TO_METADATA_SCHEMA_MUTATION)

  const addSchemaEntry = async (data: { displayName: string, type: MetadataType }) => {
    const key = kebabCase(data.displayName);
    const variables: AddToMetadataSchemaMutationVariables = {
      input: {
        key,
        ...data
      }
    }
    const { errors } = await addToMetadataSchema({ variables })
    if (errors) {
      reportMutationError({
        errors,
        variables,
        message: `Error updating metadata schema with ${variables.input.key}`
      })
    }

    if (!errors) {
      onClose(key);
    }
  }

  type AddSchemaInput = Parameters<typeof addSchemaEntry>[0]

  const displayValue = (v: MetadataType | undefined) =>
    v ? (v === 'Price' ? 'Currency' : v)
      : 'Select Type'

  return (
    <ConditionalModal onClose={onClose} className='w-auto max-w-screen-sm'>
      <Form.Form<AddSchemaInput> onSubmit={addSchemaEntry} className='flex gap-4 p-4 flex-col' formMethods={formMethods}>
        <div>
          <div className='font-medium mb-1 text-lg'>Add a new metadata type</div>
          <div className='text-gray-400 text-sm'>
            This will be available for entry on every part. Keys cannot be removed from or edited in the schema to retain backwards compatability.
          </div>
        </div>
        <div className='flex gap-2 relative'>
          <Controller
            name='type'
            defaultValue={null}
            rules={{ required: true }}
            render={({ field: { onChange, value, ref } }) =>
              <ListBox.ListBox onChange={onChange} value={value} ref={ref}>
                {({ open }) =>
                  <div className="relative h-full">
                    <ListBox.Button className='py-2' displayValue={displayValue(value)}/>
                    <ListBox.Options open={open} align='left' className='text-sm'>
                      {usedTypes.map(t => (
                        <ListBox.Option key={t} className='py-3' value={t} display={displayValue(t)} />
                      ))}
                    </ListBox.Options>
                  </div>
                }
              </ListBox.ListBox>
            }
          />
          <Form.TextField name='displayName' placeholder='Display Name' required validation={{ minLength: 3 }} className='grow *:h-10' autoComplete='off'></Form.TextField>
        </div>
        <div className='flex gap-2'>
          <Button className='py-2 grow basis-1' onClick={() => onClose()}>
            Cancel
          </Button>
          <Button className='py-2 grow basis-1' variant='primary' type='submit' disabled={!formMethods.formState.isValid || loadingSchemaUpdate}>
            Save
          </Button>
        </div>
        <Form.FormError error={schemaUpdateError} wrapperClassName="text-white bg-red-500 w-full p-4 rounded" />
      </Form.Form>
    </ConditionalModal>
  )
}

const NEW_TYPE = 'New Type' as const;
const NEW_TYPE_VAL = '__' + NEW_TYPE + '__'
export type ChangeType = undefined | 'Add' | 'Remove' | 'Change'
type MetadataPanelProps = {
  editMode?: EditMode
  edit?: {
    partNumber: string
    changeOrderNumber: number
  }
  headMetadata?: any
  diff?: PartDiffSummary['fields']['metadata']
  part:  Pick<Part, 'version'> & { metadata: any }
}
export const MetadataPanel = ({editMode, ...props}: MetadataPanelProps) => {
  const { edit, part, headMetadata, diff } = props;
  const [editingMetadata, setEditingMetadata] = useState(false)
  const [activeEntries, setActiveEntries] = useState<ResolvedMetadata[]>([])
  const [showSchemaEditor, setShowSchemaEditor] = useState(false);

  const formMethods = useForm();

  const metadataSchema = useContext(MetadataSchemaContext)
  if (!metadataSchema) return;

  const schemaRef = useRef<MetadataSchema>(metadataSchema)
  schemaRef.current = metadataSchema;

  const resolvedHeadMetadata = headMetadata ? resolveMetadata(metadataSchema, headMetadata) : undefined
  const resolvedMetadata = resolveMetadata(metadataSchema, props.part.metadata)

  const getMetadataWithChange = () => {
    const input = diff?.change || resolvedMetadata
    return Object.entries(metadataSchema).map(([key, field]) => {
      const metadataItem = input.find(m => m.key === key)
      if (metadataItem) {
        return metadataItem
      }
      if (field.alwaysShow) {
        return {
          ...field,
          key,
          type: "Empty"
        }
      }
      return false
    }).filter(m => m) as MetadataField[]
  }
  const metadataWithChange = getMetadataWithChange()

  const [editPartMutation, { loading }] = useMutation<EditMetadataMutation, EditMetadataMutationVariables>(EDIT_METADATA_MUTATION)

  const updateMetadata = async (newMetadata: EditMetadataMutationVariables['metadata']) => {
    if (!edit) {
      throw new Error('Metadata not editable in this context')
    }
    const variables = {
      partNumber: edit.partNumber,
      changeOrderNumber: edit.changeOrderNumber,
      version: part.version,
      metadata: newMetadata
    }
    const { errors } = await editPartMutation({
      variables: variables,
      refetchQueries: [{ query: QUERY, variables: { orderNumber: edit.changeOrderNumber } }],
      awaitRefetchQueries: true
    })
    if (errors) {
      reportMutationError({
        errors,
        variables,
        message: `Error mutating part metadata`
      })
    }

    setEditingMode(false)
  }

  const setEditingMode = (editing: boolean) => {
    setEditingMetadata(editing)
    if (editing) {
      setActiveEntries(resolvedMetadata)
    }
  }

  const deleteLocalMetadata = (key: string) => {
    const newMetadata = activeEntries.filter(m => m.key !== key)
    setActiveEntries(newMetadata)
    formMethods.unregister(key)
  }

  const unusedFields = Object.entries(metadataSchema).filter(f => !activeEntries.find(m => m.displayName === f[1].displayName))

  const onNewEntrySelected = (key: string) => {
    if (key === NEW_TYPE_VAL) {
      setShowSchemaEditor(true);
    }
    else {
      const schema = schemaRef.current[key];
      //@ts-ignore
      const virtualEntry: ResolvedMetadata = {
        ...schema,
        key,
        entry: defaultMetadataValue(schema.type)
      }

      setActiveEntries([
        ...activeEntries,
        virtualEntry
      ])
    }
  }

  const onSchemaModalClosed = (newKey?: string) => {
    setShowSchemaEditor(false)
    if (newKey) onNewEntrySelected(newKey)
  }

  return (
    <div className='flex flex-col gap-0.5'>
      <div className='flex items-center gap-1 text-sm font-semibold text-gray-500'>
        <div>Metadata</div>
        {editMode && !editingMetadata &&
          <EditButton size={4} disabled={editMode === 'locked'} onClick={() => setEditingMode(true)} testId='metadata'/>
        }
      </div>
      {editingMetadata ?
        <>
          <Form.Form formMethods={formMethods} onSubmit={updateMetadata} >
            <MetadataTable>
              {activeEntries.length ?
                activeEntries.map(m =>
                <MetadataRow key={m.key} field={m} editable onDelete={(field) => deleteLocalMetadata(field.key)}/>
                ) :
                <tr>
                  <td colSpan={2} className='whitespace-nowrap px-4 py-2 text-sm text-gray-400'>
                    This part doesn't have any metadata
                  </td>
                </tr>
              }
            </MetadataTable>
            <div className='flex gap-2'>
              <ListBox.ListBox onChange={onNewEntrySelected} value={null}>
                {({ open }) =>
                  <div className=''>
                    <ListBox.Button displayValue='Add field' className='py-2 text-sm'/>
                    <ListBox.Options open={open} align='left' className='text-xs'>
                      {unusedFields.map(([key, value]) => (
                        <ListBox.Option key={key} className='py-3' value={key} display={value.displayName} />
                      ))}
                      <ListBox.Option className={unusedFields.length ? 'border-t py-3' : ''} value={NEW_TYPE_VAL} display={NEW_TYPE} />
                    </ListBox.Options>
                  </div>
                }
              </ListBox.ListBox>
              <Button type='button' className='ml-auto py-2' onClick={() => setEditingMode(false)}>
                Cancel
              </Button>
              <Button type='submit' variant='primary' className='py-2' disabled={!formMethods.formState.isValid || loading}>
                Save
              </Button>
            </div>
          </Form.Form>
          {showSchemaEditor &&
            <MetadataSchemaEditor onClose={onSchemaModalClosed}/>
          }
        </>
        :
        <MetadataTable>
          {metadataWithChange && metadataWithChange.length ?
            metadataWithChange.map(field =>
              <MetadataRow key={field.key} field={field}/>)
            :
            <tr className={changeClasses[resolvedHeadMetadata?.length ? 'Remove' : 'None']}>
              <td colSpan={2} className='whitespace-nowrap px-4 py-2 text-sm text-gray-400'>
                This part doesn't have any metadata
              </td>
            </tr>
          }
        </MetadataTable>
      }
    </div>
  )
}
type MetadataTableProps = React.PropsWithChildren<{}>
const MetadataTable = (props: MetadataTableProps) => {
  return (
    <div className='border border-gray-200 rounded-lg my-2 -mx-1 text-xs'>
      <table>
        <thead>
          <tr className='bg-gray-50'>
            <th scope="col" className={`whitespace-nowrap py-2 px-4 text-left font-semibold text-gray-900 rounded-tl-lg`}>
              Field Name
            </th>
            <th scope="col" className={`whitespace-nowrap py-2 px-4 text-left font-semibold text-gray-900 w-full rounded-tr-lg`}>
              Value
            </th>
          </tr>
        </thead>
          <tbody className="divide-y divide-gray-200">
            {props.children}
          </tbody>
      </table>
    </div>
  )
}

const changeClasses = {
  'Add': 'bg-green-200',
  'Remove': 'bg-red-200 linethrough',
  'Change': 'bg-yellow-100',
  'None': ''
}

const required = { required: true }

// Empty will never be passed if in edit mode
type MetadataField = ResolvedMetadata | {
  key: string
  type: "Empty";
  displayName: string;
  entry: never;
  alwaysShow?: boolean | undefined;
}

type MetadataRowProps = {
  field: MetadataField & { changeType?: ChangeType }
} & ({
  editable?: false
} | {
  editable: true
  onDelete: (field: ResolvedMetadata) => void
})

const MetadataRow = ({ field, editable, ...props }: MetadataRowProps) => {
  const inputId = 'metadata-input-' + field.key

  return (
    <tr className={changeClasses[field.changeType ?? 'None']}>
      <td className='whitespace-nowrap px-4 py-2 text-xs text-gray-600' data-testid={'metadata-label-' + field.key}>
        {field.displayName}
      </td>
      <td className='whitespace-nowrap px-4 py-2 text-xs text-gray-600'>
        { editable ? letF(props as Extract<MetadataRowProps, {editable: true}>, ({ onDelete }) => {
          const nonEmptyField = field as Exclude<MetadataField, { type: "Empty" }>
          const deleteButton = <button type='button' onClick={() => onDelete(nonEmptyField)} className='ml-auto pl-2'><TrashIcon className='w-4'/></button>
          return (
            field.type === 'String' ? <div className='flex gap-1'>
              <Form.TextField name={field.key} validation={required} defaultValue={field.entry} className='grow' data-testid={inputId} />
              {deleteButton}
            </div>
            : field.type === 'URL' ? <div className='flex gap-1'>
              <Form.UrlField name={field.key} validation={required} defaultValue={field.entry} className='grow'  data-testid={inputId}/>
              {deleteButton}
            </div>
            : field.type === 'Number' ? <div className='flex gap-1'>
              <Form.NumberField name={field.key} className='grow' step="any"
                validation={{ ...required, valueAsNumber: true }} defaultValue={field.entry} data-testid={inputId}/>
              {deleteButton}
            </div>
            : field.type === 'Boolean' ? <div className='flex gap-1'>
              <Form.CheckboxField name={field.key}
                validation={{ valueAsBoolean: true }} defaultChecked={field.entry} data-testid={inputId}/>
              {deleteButton}
            </div>
            : field.type === 'Time' ? <div className='flex gap-1'>
              <Form.DatetimeField name={field.key} className='grow'
                validation={{ ...required, valueAsDate: true }} data-testid={inputId}/>{/*TODO: default this better*/}
              {deleteButton}
            </div>
            : field.type === 'Mass' ?
              <div className='flex gap-1'>
                <div className='flex relative grow'>
                  <Form.NumberField name={`${field.key}.value`} placeholder='Value'  className='grow [&_input]:pr-20'
                    validation={{ ...required, valueAsNumber: true }} defaultValue={field.entry.value} step="any" data-testid={inputId}/>
                  <Controller
                    name={`${field.key}.unit`}
                    defaultValue={field.entry.unit}
                    render={({ field: { onChange, value, ref } }) =>
                      <ListBox.ListBox onChange={onChange} value={value} ref={ref}>
                        {({ open }) =>
                          <div className="absolute h-full right-0">
                            <ListBox.UncontrolledButton className='h-full !bg-transparent pr-8' hideRing/>
                            <ListBox.Options open={open}>
                              {masses.map(t => (
                                <ListBox.Option key={t} className='py-3' value={t} display={t} />
                              ))}
                            </ListBox.Options>
                          </div>
                        }
                      </ListBox.ListBox>
                    }
                  />
                </div>
                {deleteButton}
              </div>
            : field.type === 'Price' ?
              <div className='flex gap-1'>
                <div className='flex relative grow'>
                  <Form.NumberField name={`${field.key}.value`} placeholder='Value' className='grow [&_input]:pr-20'
                    validation={{ ...required, valueAsNumber: true }} defaultValue={field.entry.value} step="any" data-testid={inputId}/>
                  <Controller
                    name={`${field.key}.unit`}
                    defaultValue={field.entry.unit}
                    render={({ field: { onChange, value, ref } }) =>
                      <ListBox.ListBox onChange={onChange} value={value} ref={ref}>
                        {({ open }) =>
                          <div className="absolute h-full right-0">
                            <ListBox.UncontrolledButton className='h-full !bg-transparent pr-8' hideRing/>
                            <ListBox.Options open={open}>
                              {currencyCodes().map(t => (
                                <ListBox.Option key={t} className='py-3' value={t} display={t} />
                              ))}
                            </ListBox.Options>
                          </div>
                        }
                      </ListBox.ListBox>
                    }
                  />
                </div>
                {deleteButton}
              </div>
            : undefined
          )})
          :
          (
            field.type === 'String' ? <>{field.entry}</> :
            field.type === 'Boolean' ? <>{JSON.stringify(field.entry)}</> :
            field.type === 'Number' ? <>{field.entry}</> :
            field.type === 'URL' ? <>{field.entry}</> :
            field.type === 'Time' ? <>{field.entry}</> :
            field.type === 'Mass' ? <>{field.entry.value} {field.entry.unit}</> :
            field.type === 'Price' ? <>{field.entry.value} {field.entry.unit}</> :
            field.type === 'Empty' ? <>-</> :
            undefined
          )
        }
      </td>
    </tr>
  )
}

function defaultMetadataValue(type: MetadataType) {
  return (
    type === 'String' ? '' :
    type === 'Boolean' ? false :
    type === 'Number' ? 0 :
    type === 'URL' ? '' :
    type === 'Time' ? '' :
    type === 'Mass' ? {
      unit: 'g',
      value: 0
    } :
    type === 'Price' ? {
      unit: 'USD',
      value: 0
    } :
    ''
  )
}
