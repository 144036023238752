import { useState, Fragment, createContext, useContext } from 'react';
import { registerFragment } from '@redwoodjs/web/apollo'

import { mkConfig, generateCsv, download } from "export-to-csv";
import type {
  PartQuery,
  PartQueryVariables,
  Project,
  User,
  ChangeOrderLog,
  AddToChangeOrderMutation,
  AddToChangeOrderMutationVariables,
} from 'types/graphql'
import { DocumentIcon, TagIcon } from '@heroicons/react/24/outline'
import type { ChangeOrderLogPayload } from '$api/src/services/changeOrders/changeOrders'
import { CellSuccessProps, CellFailureProps, useMutation } from '@redwoodjs/web'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import type { ExchangeRates } from 'api/src/lib/exchangeRates';

import { Sources } from '../Sources';
import { reportMutationError } from 'src/lib/reportError'
import calculateNextVersion from 'src/lib/calculateNextVersion'
import ChangeOrderSelectCell, { SelectedChangeOrder, ChangeOrderSelect } from 'src/components/ChangeOrderSelectCell'
import { Dialog, Transition } from '@headlessui/react'
import { routes, navigate, useParams, Link } from '@redwoodjs/router';
import { prettyDate } from 'src/lib/formatters'
import * as ListBox from '../ListBox';
import Button from 'src/components/Button'
import { ZipIcon } from '../Icons';
import { DocumentTextIcon, ArrowDownTrayIcon, ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid'
import { Overview } from '../PartOverview/PartOverview';
import { Artifacts } from '../Artifacts/Artifacts'
import PartHierarchy, { getSelection as getHierarchySelection, RequiredPartHierarchy } from '../PartHierarchy/PartHierarchy';
import PartHierarchyCell from '../PartHierarchyCell'
import { getMapper } from 'src/lib/mapping'
import { LoadingSpinnerWithDelay as LoadingSpinner } from 'src/components/Loading'
import PartSettingsDialog from './PartSettings'
import { useMetadataSchema, useMetadataTabularizer } from 'src/lib/metadata'
import { displayUnits } from 'src/components/Dependencies'
import { ConditionalModal } from '../Modal';
import CreatePartCell from '../CreatePartCell';
import { getInstanceQuantity, buildHierarchyTree, buildAggregationTree, SumAggregator, Aggregator, HierarchyTreeRootNode } from './aggregation';

import { StatusDot } from '../LifecycleStatus/LifecycleStatus';
import { useLifeCycleStages, resolveLifeCycle, ResolvedLifeCycleStage } from "src/lib/lifecycle"
import * as Tooltip from "src/components/ToolTip"

type ExportMapper = PartQuery['currentOrg']['manualExportMapper']
export type QueryPartHierarchy = NonNullable<PartQuery['partProto']>['instance']['hierarchy']
type QueryInstance = NonNullable<PartQuery['partProto']>['instance']

export const aggregationConfigFragment = registerFragment(gql`
  fragment AggregationConfigsFragment on Organization {
    aggregationConfigs {
      name
      targetType {
        type
        unit
      }
      metadata {
        key
        multiplyByQuantity
      }
      sources {
        key
        multiplyByQuantity
      }
    }
  }
`)

export const QUERY = gql`
  query PartQuery($partNumber: String!, $version: String) {
    currentOrg {
      id
      distributors {
        name
      }
      metadataSchema
      manualExportMapper
      exchangeRates
      ...AggregationConfigsFragment
    }
    partProto(partNumber: $partNumber) {
      project {
        id
        name
        link
        defaultMapper
      }
      category {
        id
        name
        label
      }
      inChangeOrders {
        number
        name
        state
        log {
          user {
            name
          }
          id
          action
          createdAt
          payload
        }
      }
      partNumber
      currentVersion {
        version
        publishId
        lifeCycle
      }
      owner {
        id
        name
      }
      categoryId
      protoUpdatedBy {
        name
      }
      instance(version: $version) {
        name
        cadRev
        sources {
          id
          url
          comment
          distributorId
          distributor {
            id
            name
          }
          priority
          distributorSku
          price
          priceCurrency
          leadTimeDays
          stock
        }
        summary
        version
        lifeCycle
        isRoot
        isOffTheShelf
        partNumber
        hierarchy {
          hierarchy
          part {
            name
            cadRev
            version
            lifeCycle
            summary
            partNumber
            metadata
            sources {
              id
              url
              comment
              distributorId
              distributor {
                id
                name
              }
              priority
              distributorSku
              price
              priceCurrency
              leadTimeDays
              stock
            }
            artifacts {
              filename
              file {
                url
                inlineUrl
                contentType
                size
              }
            }
            proto {
              currentVersionString
              category {
                id
                name
              }
            }
          }
          dependency {
            quantity
            units
            referenceDesignator
            groupId
          }
        }
        metadata
        integrationType
        documentType
        externalDocRef
        artifacts {
          id
          filename
          fileId
          file {
            confirmed
            path
            url
            inlineUrl
            contentType
            size
          }
        }
        dependencies {
          section
          to {
            proto {
              partNumber
              currentVersionString
            }
            branch
            partNumber
            name
            version
          }
          quantity
          units
          referenceDesignator
        }
      }
      forkFrom {
        partNumber
        version
      }
      instances {
        publishId
        version
        branch
        inChangeOrder {
          name
        }
      }
    }
  }
`

export const ADD_TO_CHANGE_ORDER_MUTATION = gql`
mutation AddToChangeOrderMutation ($changeOrderNumber: Int!, $input: [PartDeltaInput!]!) {
  addPartDeltas(changeOrderNumber: $changeOrderNumber, allowDuplicates: true, input: $input) {
    partNumber
  }
}
`

const ExportMapperContext = createContext<ExportMapper>(null)

export const Loading = () => <LoadingSpinner className='flex p-10 items-center justify-center'/>

export const Empty = () => <div>Empty</div>

import GenericFailure from '../Failure/Failure'

export const Failure = GenericFailure

export const Success = ({currentOrg, ...props}: CellSuccessProps<PartQuery, PartQueryVariables>) => {
  return <ExportMapperContext.Provider value={currentOrg.manualExportMapper}>
      <Part {...props} aggregationConfigs={currentOrg.aggregationConfigs} exchangeRates={currentOrg.exchangeRates}/>
    </ExportMapperContext.Provider>
}

const Part = ({
  partProto,
  aggregationConfigs,
  exchangeRates,
}: Omit<CellSuccessProps<PartQuery, PartQueryVariables>, 'currentOrg'> &
    Pick<CellSuccessProps<PartQuery, PartQueryVariables>['currentOrg'], 'aggregationConfigs' | 'exchangeRates'>
  ) => {
  const metadataSchema = useMetadataSchema()!

  const orgId = useParams().orgId!
  if (!partProto) throw new Error('No part found')
  const { partNumber, instances, instance, inChangeOrders, owner, currentVersion } = partProto;

  // for part hierarchy input
  const instanceWithProto = { ...instance, proto: partProto }

  const [exportDialogOpen, setExportDialogOpen] = useState(false)
  const [partSettingsOpen, setPartSettingsOpen] = useState(false)
  const [forkPartOpen, setForkPartOpen] = useState(false)
  const handleMoreSelect = (option: 'PartSettings' | 'PartCompare' | 'Fork') => {
    if (option === 'PartSettings') {
      setPartSettingsOpen(true)
      setForkPartOpen(false)
    }
    else if (option === 'PartCompare') {
      navigate(
        routes.partCompare({
          basePartNumber: partNumber,
          incomingPartNumber: partNumber,
          baseVersion: instance.version,
          orgId,
          incomingVersion: currentVersion!.version!
        })
      )
    }
    else if (option === 'Fork') {
      setPartSettingsOpen(false)
      setForkPartOpen(true)
    }
  }

  const forkPrefix = '__fork__' as const;
  const forkSeparator = '~~~' as const;
  const handleVersionSelection = (newVersion: string) => {
    if (newVersion === '__full__') {
      navigate(routes.partHistory({ partNumber, orgId }))
    }
    else if (newVersion.startsWith(forkPrefix)) {
      const [partNumber, version] = newVersion.substring(forkPrefix.length).split(forkSeparator) as [string, string]
      navigate(routes.partWithVersion({ partNumber, version, orgId }))
    }
    else if (newVersion === instance.version) {
      navigate(routes.part({ partNumber, orgId }))
    }
    else {
      navigate(routes.partWithVersion({ partNumber, version: newVersion, orgId }))
    }
  }

  const liveInstances = instances.filter(p => {
    // latest version, not sure what happens when added to a change order? maybe new version
    // if (!p.inChangeOrder) return true

    // main branch
    return p.branch === -1
  })
  liveInstances.sort((a, b) => {
    // those without publish ID will be filtered anyway
    return b.publishId! - a.publishId!
  })

  const parentDownloads = instance.artifacts.map(a => {
    return {
      source: a.file.url,
      destination: `${partNumber}v${instance.version} - ${instance.name}/${a.filename}`
    }
  })
  const descendentDownloads = instance.hierarchy
    .reduce((deduped, h, i) => {
      const exists = deduped.some(e => {
        return h.part.partNumber === e.part.partNumber &&
          h.part.version === e.part.version
      })
      if (!exists) {
        return [...deduped, h]
      }
      return deduped
    }, [] as typeof instance.hierarchy)
    .flatMap(h => {
      return h.part.artifacts.map(a => {
        return {
          source: a.file.url,
          destination: `${h.part.partNumber}v${h.part.version} - ${h.part.name}/${a.filename}`
        }
      })
    })

  const appliedChangeOrders = inChangeOrders.filter(c => c.state === 'Complete')
  type LastPublishLog = Pick<ChangeOrderLog, 'createdAt'> & {
    user: Pick<User, 'name'>
  }
  type LastPublishInfo = {
    log?: LastPublishLog
    orderNumber: number
    orderName: string
  }
  const lastPublish = appliedChangeOrders.reduce((latestApply, c) => {
    return c.log.reduce((latestApply, l) => {
      if (l.action !== 'ChangeState') return latestApply
      const payload = l.payload as ChangeOrderLogPayload['ChangeState']
      if (payload.state !== 'Complete') return latestApply
      if (!latestApply.log || l.createdAt > latestApply.log.createdAt) {
        return {
          log: l,
          orderNumber: c.number,
          orderName: c.name
        }
      }
      return latestApply
    }, latestApply)
  }, {} as LastPublishInfo)

  const lastPublishMessage = () => {
    if (!lastPublish.log?.createdAt) {
      return <>Part not published since initial import</>
    }

    const date = prettyDate(new Date(lastPublish.log?.createdAt))
    return <>{date} in <a className='text-sm text-blue-600 underline' href={routes.changeOrderTab({ orgId, orderNumber: lastPublish.orderNumber, tab: 'changes' })}>
        #{lastPublish.orderNumber} {lastPublish.orderName}
      </a></>
  }

  const openChangeOrders = inChangeOrders.filter(c => c.state !== 'Complete' && c.state !== 'Cancelled')
  const changeOrdersList = openChangeOrders.length === 0 ?
    <div className='text-sm text-gray-800'>This part is not in any change orders</div> :
    <>{openChangeOrders.map(c => {
      return <a key={c.number} className='text-sm text-blue-600 underline' href={routes.changeOrderTab({ orgId, orderNumber: c.number, tab: 'changes' })}>
        #{c.number} {c.name}
      </a>
    })}</>

  const [addToChangeOrderMutation] = useMutation<AddToChangeOrderMutation, AddToChangeOrderMutationVariables>(ADD_TO_CHANGE_ORDER_MUTATION)

  const addToChangeOrder = async (c: SelectedChangeOrder, input: AddToChangeOrderMutationVariables['input']) => {
    const variables: AddToChangeOrderMutationVariables = {
      changeOrderNumber: c.number,
      input
    }
    const { errors } = await addToChangeOrderMutation({
      variables: variables,
    })
    if (errors) {
      reportMutationError({
        errors,
        variables,
        message: `Error adding part to change order`
      })
      return
    }
  }
  const handleAddToChangeOrder = async (c: SelectedChangeOrder) => {
    await addToChangeOrder(c, [{
      type: 'Push',
      partNumber,
      version: calculateNextVersion(currentVersion!.version!),
    }])

    navigate(`${routes.changeOrderTab({ orgId, orderNumber: c.number, tab: 'changes' })}#part-change-${partNumber}`)
  }

  const handleAddToChangeOrderWithSelection = async(c: SelectedChangeOrder) => {
    //TODO: maybe add modal here to confrim, or else it is a bit of a hot wire

    const filtedHierarchy = getHierarchySelection(instanceWithProto.hierarchy, selectedNodes)

    const input = [{
      type: 'Push' as const,
      partNumber,
      version: calculateNextVersion(currentVersion!.version!),
    }]

    input.push(...filtedHierarchy.map(n => ({
      type: 'Push' as const,
      partNumber: n.part.partNumber,
      version: calculateNextVersion(n.part.proto.currentVersionString!),
    }))
    )

    await addToChangeOrder(c, input)

    navigate(`${routes.changeOrderTab({ orgId, orderNumber: c.number, tab: 'changes' })}#part-change-${partNumber}`)
  }

  const handleCreateChangeOrder = () => {
    navigate(routes.changeOrderCreate({ orgId, withPartNumber: partNumber }))
  }

  const handleCreateChangeOrderWithSelection = () => {
    const filtedHierarchy = getHierarchySelection(instanceWithProto.hierarchy, selectedNodes)

    const opaquePartNumbers = btoa(partNumber) + ',' + filtedHierarchy.map(n => btoa(n.part.partNumber)).join(',')

    navigate(routes.changeOrderCreate({ orgId, withPartNumbers: opaquePartNumbers }))
  }

  const [exportMode, setExportMode] = useState(false)
  const [selectedNodes, setSelectedNodes] = useState<string[]>([])

  const selectedHierarchy = getHierarchySelection(instance.hierarchy, exportMode ?
      selectedNodes : instanceWithProto.hierarchy.map(h => h.hierarchy))

  const hierarchyTree = buildHierarchyTree(selectedHierarchy)

  const potentialAggregators = aggregationConfigs?.map(c => SumAggregator({
    metadataSchema,
    name: c.name,
    exchangeRates: exchangeRates as ExchangeRates,
    targetType: {
      type: c.targetType.type,
      unit: c.targetType.unit
    },
    metadata: c.metadata,
    sources: c.sources
  })) ?? []


  const [activeAggregators, setActiveAggregators] = useState<Aggregator[]>([])

  const aggregationTree = activeAggregators.length ? buildAggregationTree(hierarchyTree, activeAggregators) : undefined

  const aggregationSelect = (
    <ListBox.ListBox multiple value={activeAggregators.map(a => a.name)} disabled={potentialAggregators.length === 0}
      onChange={names => setActiveAggregators(potentialAggregators.filter(a => names.includes(a.name)))}>
      {({ open }) => (<>
        <div className="relative">
          <ListBox.Button className='h-full' displayValue={'View Aggregation'} disabled={potentialAggregators.length === 0}/>
          <ListBox.Options open={open} className='z-50'>
            {
              potentialAggregators.map(a =>
                <ListBox.Option key={a.name} value={a.name} display={a.name}/>
              )
            }
          </ListBox.Options>
        </div>
      </>
      )}
    </ListBox.ListBox>
  )

  const wrappedAggSelect = potentialAggregators.length ? aggregationSelect :
    <Tooltip.Container>
      <div className='group h-full' tabIndex={0}>
        {aggregationSelect}
        <Tooltip.Message className="group-focus:visible group-focus:z-50 bottom-11">
          No aggregations configured. Please contact Bomello to enable this feature.
        </Tooltip.Message>
      </div>
    </Tooltip.Container>

  const exportButtons = () => !exportMode ?
    <div className='flex gap-2 items-stretch'>
      <Button onClick={() => {
        setSelectedNodes(instanceWithProto.hierarchy.map(h => h.hierarchy))
        setExportMode(true)
      }} className='min-w-20'>
        Select
      </Button>
      {wrappedAggSelect}
      <ChangeOrderSelect changeOrders={[]} onSelect={() => {}} placeholder='Add Selection to Change Order' disabled={true} />
      <Tooltip.Container>
        <div className='group h-full' tabIndex={0}>
          <Button variant='primary' className='h-full' disabled>Export Selected Parts</Button>
          <Tooltip.Message className="group-focus:visible group-focus:z-50 bottom-10">
            Select which parts to export
          </Tooltip.Message>
        </div>
      </Tooltip.Container>
    </div> :
    <div className='flex gap-2'>
      <Button onClick={() => {
        setSelectedNodes([])
        setExportMode(false)
      }} className='min-w-20'>
        Cancel
      </Button>
      {wrappedAggSelect}
      <ChangeOrderSelectCell
        className=''
        placeholder='Add Selection to Change Order'
        onCreate={handleCreateChangeOrderWithSelection}
        onSelect={handleAddToChangeOrderWithSelection} />
      <Button variant='primary' onClick={() => setExportDialogOpen(true)}>Export Selected Parts</Button>
    </div>

  return (<>
    <ExportDialog open={exportDialogOpen} setOpen={setExportDialogOpen} hierarchy={selectedHierarchy} tree={hierarchyTree} part={instance} downloads={[...parentDownloads, ...descendentDownloads]} />
    {forkPartOpen &&
      <ConditionalModal className='w-fit mt-10' onClose={() => setForkPartOpen(false)}>
        <CreatePartCell variant='fork' forkPart={partProto} onComplete={() => setForkPartOpen(false)}/>
      </ConditionalModal>
    }
    {partSettingsOpen &&
      <PartSettingsDialog open={partSettingsOpen} onClose={() => setPartSettingsOpen(false)} partProto={partProto} refetchQueries={[QUERY]} />
    }
    <div className='flex flex-col gap-2 pt-16' data-testid='part'>
      <div className='rounded-lg border border-gray-300 flex flex-col'>
        {/* Header */}
        <div className={`bg-brandBlue-400 pl-10 py-6 pr-6 rounded-t-lg flex flex-col gap-6 text-sm`}>
          <div className='flex items-center mb-4 gap-3'>
            <div className='grow'>
              <div className='flex flex-row gap-2 items-center'>
                <TagIcon className='h-3 w-3' /><div>{partProto.category.name}</div>
              </div>
              <div className='flex flex-row items-center gap-2'>
                <div>
                  <StatusDot lifeCycle={instance.lifeCycle} />
                </div>
                <div className='text-xl flex-1 text-gray-900'><span className='font-semibold'>#{partNumber}</span> {instance.name}</div>
              </div>
            </div>
            <ListBox.ListBox value={instance.version} onChange={handleVersionSelection}>
              {({ open }) => (<>
                <div className="relative">
                  <ListBox.Button className='' displayValue={`Version ${instance.version}`} />
                  <ListBox.Options open={open}>
                    <div className='border-b border-gray-200'>
                      {liveInstances.map(i =>
                        <ListBox.Option value={i.version} display={`Version ${i.version}`} key={i.version} />
                      )}
                    </div>
                    <ListBox.Option value={'__full__'} display={`View Full History`} key={'__full__'} />

                  </ListBox.Options>
                </div>
              </>
              )}
            </ListBox.ListBox>
            <ChangeOrderSelectCell
              omit={openChangeOrders.map(o => o.number)}
              className=''
              placeholder='Add to Change Order'
              onCreate={handleCreateChangeOrder}
              onSelect={handleAddToChangeOrder} />
            <ListBox.ListBox value='' onChange={handleMoreSelect}>
              {({ open }) => (<>
                <div className="relative">
                  <ListBox.Button displayValue={`More`} />
                  <ListBox.Options open={open}>
                    <ListBox.Option value='PartSettings' display={`Part Settings`} key={'PartSettings'} />
                    <ListBox.Option value='PartCompare' display={`Compare`} key={'PartCompare'} />
                    <ListBox.Option value='Fork' display={`Fork`} key={'Fork'} />
                  </ListBox.Options>
                </div>
              </>
              )}
            </ListBox.ListBox>
          </div>
          <div className='flex gap-6'>
            <div className='flex flex-col gap-0.5 pr-5 max-w-sm'>
              <div className='flex items-center gap-1 text-sm font-semibold text-gray-700'>
                Owner
              </div>
              <div className='text-sm text-gray-800'>
                {owner.name}
              </div>
            </div>
            <div className='flex flex-col gap-0.5 pr-5 max-w-sm'>
              <div className='flex items-center gap-1 text-sm font-semibold text-gray-700'>
                Last Published
              </div>
              <div className='text-sm text-gray-800'>
                {lastPublishMessage()}
              </div>
            </div>
            <div className='flex flex-col gap-0.5 pr-5 max-w-sm'>
              <div className='flex items-center gap-1 text-sm font-semibold text-gray-700'>
                Change Orders
              </div>
              {changeOrdersList}
            </div>
            {partProto.forkFrom &&
              <div className='flex flex-col gap-0.5 pr-5 max-w-sm'>
                <div className='flex items-center gap-1 text-sm font-semibold text-gray-700'>
                  Forked From
                </div>
                <div className='text-sm text-gray-800'>
                  <Link to={routes.partWithVersion({partNumber: partProto.forkFrom.partNumber, version: partProto.forkFrom.version, orgId})} className='underline text-blue-700 font-sans'>
                    #{partProto.forkFrom.partNumber} v{partProto.forkFrom.version}
                  </Link>
                </div>
              </div>
            }
          </div>
        </div>
        {/* Body */}
        <div className='flex flex-col gap-10 px-10 pt-6 pb-10'>
          <div>
            <div
              className='text-md text-gray-900 font-medium h-10'>Overview</div>
            <Overview part={instance} />
          </div>
          {partProto.project ? <ProjectSection orgId={orgId} project={partProto.project} /> : null}
          <div>
            <div className='text-md text-gray-900 font-medium mb-4'>Artifacts</div>
            <Artifacts partNumber={partNumber} part={instance} />
          </div>
          {
            instance.dependencies.length ?
              <div className='flex flex-col gap-4 -mx-1'>
                <div className='flex items-center gap-2 text-md text-gray-900 font-medium justify-between'>
                  <div>Materials</div>
                  {exportButtons()}
                </div>
                {activeAggregators.length > 0 &&
                  <div className='flex justify-end gap-2 mr-11 text-xs'>
                    {activeAggregators.map(a =>
                    <div key={a.name} className='group w-36 text-right whitespace-nowrap overflow-hidden text-ellipsis hover:overflow-visible hover:z-10'>
                      <span className='group-hover:bg-gray-300 p-2 -m-2'>
                        {a.name} {a.targetType.type !== "Basic" && `(${a.targetType.unit})`}
                      </span>
                      </div>
                    )}
                  </div>
                }
                <PartHierarchy
                  selectable={exportMode}
                  selectedNodes={selectedNodes}
                  setSelectedNodes={setSelectedNodes}
                  rootPart={instanceWithProto}
                  activeAggregators={activeAggregators}
                  aggregations={aggregationTree}
                  subjectParts={[]}
                  expandedNodes={['1']} />
              </div>
           : null}
          {instance.sources.length ? <Sources sources={instance.sources}/> : null}
          <div>
            <div className='text-md text-gray-900 font-medium mb-4'>
              Where Used
            </div>
            <div className='mb-4 -mx-1'>
              <PartHierarchyCell highlightedPartNumber={instance.partNumber} />
            </div>
          </div>

        </div>
      </div>
    </div>
    </>
  )
}

type ExportDialogProps = {
  open: boolean
  setOpen: (open: boolean) => void
} & ExportBodyProps

type ExportBodyProps = {
  hierarchy: QueryPartHierarchy
  tree: HierarchyTreeRootNode,
  part: QueryInstance
  downloads: {
    source: string
    destination: string
  }[]
}

const ExportDialog: React.FC<ExportDialogProps> = ({ open, setOpen, hierarchy, tree, part, downloads }) =>
  <Transition.Root show={open} as={Fragment}>
  <Dialog as="div" className="relative z-10" onClose={setOpen}>
    <Transition.Child
      as={Fragment}
      enter="ease-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
    </Transition.Child>

    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
      <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-8 py-6 text-left shadow-xl transition-all min-w-[28rem]">
            <div>
              <div className="">
                <Dialog.Title className="font-medium mb-5 text-lg text-gray-900">
                  Export
                </Dialog.Title>
                <ExportBody hierarchy={hierarchy} tree={tree} part={part} downloads={downloads} />
              </div>
            </div>
            <div className="mt-5 flex flex-col items-end -mr-1">
              <Button
                onClick={() => setOpen(false)}
              >
                Done
              </Button>
            </div>
          </Dialog.Panel>
        </Transition.Child>
      </div>
    </div>
  </Dialog>
</Transition.Root>

const ExportBody = ({ hierarchy, tree, part, downloads }: ExportBodyProps) => {
  const tabularizeMetadata = useMetadataTabularizer()
  const exportMapper = useContext(ExportMapperContext)
  const stages = useLifeCycleStages()

  const sourcesHeaderPrefixes =
    Array(hierarchy.reduce((count, p) => Math.max(count, p.part.sources.length), 0)).fill(null)
    .map((_, i) => `Source-${i + 1}-`)

  type Sources = (typeof hierarchy)[number]['part']['sources']
  const tabularizeSources = (sources: Sources) =>
    sourcesHeaderPrefixes.reduce((acc, prefix, idx) => {
      const source = sources[idx];
      return {
        ...acc,
        [prefix + 'Distributor']: source?.distributor?.name ?? '',
        [prefix + 'SKU']: source?.distributorSku ?? '',
        [prefix + 'Link']: source?.url ?? '',
        [prefix + 'Comment']: source?.comment ?? '',
        [prefix + 'Price']: source?.price ?? '',
        [prefix + 'Currency']: source?.priceCurrency ?? '',
        [prefix + 'Lead_Time_Days']: source?.leadTimeDays ?? '',
        [prefix + 'Stock']: source?.stock ?? '',
      }
    }, {} as Record<string, any>)

  const hierarchyCsvBody = hierarchy.map(h => {
    return {
      Hierarchy: h.hierarchy,
      'Part Number': h.part.partNumber,
      Version: h.part.version,
      'CAD Revision': h.part.cadRev,
      Category: h.part.proto.category.name,
      Name: h.part.name,
      ['Option-Name']: h.dependency.groupId ?? '',
      Lifecycle: stages?.length ? resolveLifeCycle(stages, h.part.lifeCycle).name : undefined,
      Summary: h.part.summary,
      Quantity: h.dependency.quantity,
      ['Quantity-Units']: displayUnits(h.dependency.units),
      ['Reference-Designator']: h.dependency.referenceDesignator ?? '',
      ...tabularizeMetadata(h.part.metadata),
      ...tabularizeSources(h.part.sources)
    }
  })

  const flatCsvBody = hierarchy.reduce((rolledUp, h) => {
    const matchingIndex = rolledUp.findIndex(e => {
      return h.part.partNumber === e.part.partNumber &&
        h.part.version === e.part.version
    })
    if (matchingIndex === -1) {
      return [...rolledUp, {
        ...h,
      }]
    }
    return rolledUp.map((e, i) => {
      if (matchingIndex !== i) {
        return e
      }

      return {
        ...e,
        dependency: {
          ...e.dependency,
          quantity: e.dependency.quantity + h.dependency.quantity,
          units: displayUnits(h.dependency.units) || displayUnits(e.dependency.units),
          designator: e.dependency.referenceDesignator
        },
      }
    })
  }, [] as ((typeof hierarchy)[number] & {quantityRange?: [number, number]})[]).map(h => {
    const q = getInstanceQuantity(tree, h.part.partNumber, h.part.version)
    return {
      'Part Number': h.part.partNumber,
      Version: h.part.version,
      Category: h.part.proto.category.name,
      Name: h.part.name,
      Lifecycle: stages?.length ? resolveLifeCycle(stages, h.part.lifeCycle).name : undefined,
      Summary: h.part.summary,
      Quantity: (q && q[1]) ?
        (q[0] === q[1] ? q[0] : `${q[0]}-${q[1]}`)
        : '',
      ['Quantity-Units']: displayUnits(h.dependency.units),
      ['Reference-Designator']: h.dependency.referenceDesignator ?? '',
      ...tabularizeMetadata(h.part.metadata),
      ...tabularizeSources(h.part.sources)
    }
  })

  type R = Record<string, any>
  const withMappedKeys = (objs: Object[]) => exportMapper ?
    objs.map(obj =>
      Object.entries(obj).reduce((mapped, [k, v]) => ({
        ...mapped,
        [(exportMapper as R)[k] ?? k]: v
      }), {} as R))
    :
    objs as R[];

  const mappedFlat = withMappedKeys(flatCsvBody);
  const mappedHierarchy = withMappedKeys(hierarchyCsvBody);

  const files = () => {
    if (flatCsvBody.length === 0) return downloads
    const csvConfig = mkConfig({ useKeysAsHeaders: true })
    const flatCsvContents = String(generateCsv(csvConfig)(mappedFlat))
    const hierarchyCsvContents = String(generateCsv(csvConfig)(mappedHierarchy))
    const filesWithCsvs = [...downloads, {
      sourceContent: flatCsvContents,
      destination: `${part.partNumber}-v${part.version} - Flat Bill of Materials.csv`
    }, {
      sourceContent: hierarchyCsvContents,
      destination: `${part.partNumber}-v${part.version} - Hierarchical Bill of Materials.csv`
    }]
    return filesWithCsvs
  }

  return (
    <div className='flex flex-col gap-3 my-68'>
      <div className='flex flex-col gap-1'>
        <div className="text-sm text-gray-500">
          Hierarchical bill of materials
        </div>
        <CsvLink body={mappedHierarchy} fileName={`${part.partNumber}-v${part.version}-hierarchical.csv`} />
      </div>
      <div className='flex flex-col gap-1'>
        <div className="text-sm text-gray-500">
          Flat bill of materials
        </div>
        <CsvLink body={mappedFlat} fileName={`${part.partNumber}-v${part.version}-flat.csv`} />
      </div>
      <div className='flex flex-col gap-1'>
        <div className="text-sm text-gray-500">
          Artifacts
        </div>
        <ArtifactsBundle files={files()} name={`${part.partNumber}-v${part.version} - ${part.name}.zip`} />
      </div>
    </div>
  )
}

type CsvLinkProps = {
  fileName: string
  body: Record<string, unknown>[]
}
export const csvConfig = mkConfig({ useKeysAsHeaders: true })
export const CsvLink: React.FC<CsvLinkProps> = ({ body, fileName }) => {
  const csv = generateCsv(csvConfig)(body)
  const csvContent = `data:text/csv;charset=utf-8,${csv}`;

  const encodedUri = encodeURI(csvContent).replaceAll('#', '%23');
  return <a
    className='flex items-center gap-4 my-2 border border-gray-200 rounded-lg px-5 py-5 hover:bg-gray-50 -mx-1'
    href={encodedUri} download={fileName}>
      <DocumentTextIcon className='h-10 w-10 text-green-500'/>
      <div className='flex-1'>{fileName}</div>
      <ArrowDownTrayIcon className='h-5 w-5 text-gray-500' />
  </a>
}

type ProjectProps = {
  project: Pick<Project, 'id' | 'name' | 'link' | 'defaultMapper'>
  orgId: string
}
export const ProjectSection: React.FC<ProjectProps> = ({
  project,
  orgId
}) => {
  const mapper = getMapper(orgId, project.defaultMapper)
  const Logo = mapper?.Logo || DocumentIcon
  const fileBox = <div className='-ml-1 relative border border-gray-300 rounded-xl items-center px-8 py-6 inline-flex gap-4 flex-col'>
    <div className='w-28 h-12 flex items-center justify-center'>
      <Logo className='max-h-12 max-w-28'/>
    </div>
    <div className='text-gray-700 flex gap-3 items-center'>
      {project.name}
    </div>
    {project.link ?
      <ArrowTopRightOnSquareIcon className='absolute right-2 top-2 h-5 w-5'/> : null
    }
  </div>
  const fileButton = project.link ?
    <a target='_blank' href={project.link}>{fileBox}</a> :
    <div>{fileBox}</div>
  return <div className='flex-1'>
    <div className='text-md text-gray-900 font-medium mb-4'>Project</div>
    <div className='text-sm text-gray-700 mb-4'>
      This part is a project root assembly
    </div>
    {fileButton}
  </div>
}

type ArtifactDownload = {
  source?: string
  sourceContent?: string
  destination: string
}

type ArtifactsBundleProps = {
  name: string
  files: ArtifactDownload[]
  align?: 'left' | 'center'
}

export const ArtifactsBundle: React.FC<ArtifactsBundleProps> = ({ name, files, align }) => {
  const jszip = new JSZip()
  const generateDownload = async () => {
    const filesWithContent = await Promise.all(files.map(async file => {
      if (file.sourceContent) return file
      const response = await fetch(file.source!)
      return {
        destination: file.destination,
        sourceContent: await response.blob()
      }
    }))
    for (let file of filesWithContent) {
      jszip.file(file.destination, file.sourceContent!);
    }
    jszip.generateAsync({type: "blob" }).then(function(content) {
      saveAs(content, name);
    });
  }

  return <button
    className='flex items-center gap-4 my-2 border border-gray-200 rounded-lg px-5 py-5 hover:bg-gray-50 -mx-1'
    onClick={generateDownload}>
      <ZipIcon className='h-10 w-10 text-gray-500'/>
      <div className={`flex-1 ${align === 'left' ? 'text-left' : ''}`}>{name}</div>
      <ArrowDownTrayIcon className='h-5 w-5 text-gray-500' />
  </button>
}

